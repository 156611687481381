<template>
    <span :class="blockClass">
        <img v-if="image !='null'" :src="image" :class="elementClass('img')" alt="User Avatar" />
        <img v-else src="@/assets/images/avatar-base.svg" :class="elementClass('img')" alt="User Avatar" />
    </span>
</template>
<script>
  import BEM from '@helpers/bem';

  export default {
    blockName: 'c-avatar',
    mixins: [BEM],
    props: {
      image: {
        type: String,
        required: true
      }
    },
  }
</script>
