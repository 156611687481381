<template>
    <transition name="flash-wrapper" duration="300">
        <div :class="blockClass" v-if="ui.flashMessage">
            <div :class="[elementClass('dialog'), 'is-success']" v-if="ui.flashMessages.success">
                <span :class="elementClass('message')">
                    {{ ui.flashMessages.success }}
                </span>
                <button type="button" class="c-btn c-btn--default c-btn--sm"  @click="close()">
                    {{ $t('Ok') }}
                </button>
            </div>
            <div :class="[elementClass('dialog'), 'is-error']" v-if="(ui.flashMessages.error || Object.keys(ui.flashMessages.errors).length > 0)">
                <span v-if="ui.flashMessages.error" :class="elementClass('message')">{{ ui.flashMessages.error }}</span>
                <span v-else :class="elementClass('message')">
                    <span v-if="Object.keys(ui.flashMessages.errors).length === 1">{{ $t('There is one form error.') }}</span>
                    <span v-else>{{ $t('There are form errors.',{ n: Object.keys(ui.flashMessages.errors).length }) }}</span>
                </span>
                <button type="button" class="c-btn c-btn--primary c-btn--sm"  @click="close()">
                    <span>{{ $t('Ok') }}</span>
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
  import {mapState} from 'vuex';
  import BEM from '@helpers/bem';

  export default {
    blockName: 'c-flash-message',
    mixins: [BEM],
    computed: {
      ...mapState(['ui'])
    },
    watch: {
      'ui.flashMessages': {
        handler() {
          this.showFlash();
        },
        deep: true,
      },
      $route (){
          this.$store.commit('ui/resetFlashErrorMessage', [])
      }
    },
    mounted() {
      this.showFlash();
    },
    methods: {
      showFlash(){
        if(Object.keys(this.ui.flashMessages).length > 0) {
            this.$store.commit('ui/setFlashMessageState', true);

            if (this.ui.flashMessages.success) {
              setTimeout(() => {
                  this.$store.commit('ui/setFlashMessageState', false)
                this.ui.flashMessages.success = null
              }, 2000);
            }
          }
      },
      close(){
        this.$store.commit('ui/setFlashMessageState', false)
      }
    }
  }
</script>
