import { CUSTOMERS_RESET_CUSTOMERS, CUSTOMERS_SET_LOADING, CUSTOMERS_SET_CUSTOMERS } from "./types/mutation-types";

export default {
    [CUSTOMERS_RESET_CUSTOMERS]: (state) => {
        state.items = [],
        state.page = 1,
        state.size = 9,
        state.pages = 1,
        state.search = '',
        state.loading = false
    },
    [CUSTOMERS_SET_CUSTOMERS]: (state, projects) => {
        state.items = projects.items
        state.pages = projects.pages
        state.page = parseInt(projects.current_page),
        state.total = projects.items_total
    },
    [CUSTOMERS_SET_LOADING]: (state, loading) => {
        state.loading = loading
    }
}