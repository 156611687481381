<template>
    <div class="u-container u-max-w-tablet-small u-mt-16 u-mb-16">
        <div class="u-text-center">
            <picture class="u-inline-block u-w-56">
                <source srcset="@/assets/images/logo-printcil-diapos.svg" media="(prefers-color-scheme: dark)">
                <img src="@/assets/images/logo-printcil.svg" class="u-w-full" alt="Logo Printcil">
            </picture>
        </div>

        <Card v-if="!appHasRegistered && !webHasRegistered" class="u-mt-8">
            <strong class="u-text-primary">{{ $t('auth.register.sub-title') }}</strong>
            <h1 class="u-mt-0">
                {{ $t('auth.register.title') }}
            </h1>

            <form @submit.prevent="register" class="u-mt-4">
                <div class="c-alert c-alert--danger"  v-if="auth.message">
                    {{ auth.message }}
                </div>

                <InputBase 
                    type="text" 
                    v-model="name" 
                    :placeholder="$t('auth.register.full-name')"
                    autocomplete="name" 
                    :validations="[
                        {
                            condition: !!auth.errors.name,
                            text: auth.errors.name ? auth.errors.name[0] : null
                        }
                    ]"/>

                <InputBase 
                    type="email" 
                    v-model="email" 
                    :placeholder="$t('auth.register.email')"
                    class="u-mt-4"
                    autocomplete="email" 
                    :validations="[
                        {
                            condition: !!auth.errors.email,
                            text: auth.errors.email ? auth.errors.email[0] : null
                        }
                    ]"/>

                <div class="u-mt-4">
                    <InputBase 
                        type="password" 
                        v-model="password" 
                        :placeholder="$t('auth.register.password')"
                        autocomplete="password" 
                        :validations="[
                            {
                                condition: !!auth.errors.password,
                                text: auth.errors.password ? auth.errors.password[0] : null
                            }
                        ]"/>
                </div>
                <div class="u-mt-4">
                    <InputBase 
                        type="password" 
                        v-model="password_confirmation" 
                        :placeholder="$t('auth.register.password-confirmation')"
                        autocomplete="password_confirmation" 
                        :validations="[
                            {
                                condition: !!auth.errors.password_confirmation,
                                text: auth.errors.password_confirmation ? auth.errors.password_confirmation[0] : null
                            }
                        ]"/>
                </div>

                <div class="u-mt-6">
                    <InputCheckbox v-model:checked="agree" :validations="[
                            {
                                condition: !!auth.errors.agree,
                                text: auth.errors.agree ? auth.errors.agree[0] : null
                            }
                    ]"><span v-html="$t('auth.register.terms')"></span></InputCheckbox>

                </div>

                <div class="u-flex u-justify-between u-mt-4">
                    <router-link :to="{name: 'login'}" class="c-btn c-btn--text">{{ $t('auth.register.or-login') }}</router-link>
                    <button type="submit" class="c-btn c-btn--primary">
                        <span><i class="fal fa-arrow-right"></i> {{ $t('auth.register.register') }}</span>
                    </button>
                </div>
            </form>

        </Card>

        <Card v-if="appHasRegistered" class="u-mt-8">
            <strong class="u-text-primary">{{ $t('auth.register.isApp.sub-title') }}</strong>
            <h1 class="u-mt-0">
                {{ $t('auth.register.isApp.title') }}
            </h1>
 
        </Card>

        <Card v-else-if="webHasRegistered" class="u-mt-8">
            <strong class="u-text-primary">{{ $t('auth.register.isWeb.sub-title') }}</strong>
            <h1 class="u-mt-0">
                {{ $t('auth.register.isWeb.title') }}
            </h1>
            <div class="u-flex u-justify-between u-mt-4">
                <button @click="$router.push({name: 'dashboard'})" class="c-btn c-btn--primary">
                    <span><i class="fal fa-arrow-right"></i> {{ $t('auth.register.isWeb.back-to-login') }}</span>
                </button>
            </div>
        </Card>
    </div>
</template>
<script>
import { mapState } from 'vuex' 
import { AUTH_REGISTER } from '@store/stores/auth/types/action-types';

import Card from '@components/card'
import InputBase from '@components/input-base'
import InputCheckbox from '@components/input-checkbox'
import { AUTH_RESET_MESSAGE } from '../../store/stores/auth/types/mutation-types';

export default {
    components: {
        Card,
        InputBase,
        InputCheckbox
    },
    data() {
        return {
            agree: null,
            name: null,
            email: null,
            password: null,
            password_confirmation: null,
            appHasRegistered: false,
            webHasRegistered: false
        }
    },
    mounted(){
        this.$store.commit(AUTH_RESET_MESSAGE);
    },
    computed: {
        ...mapState(['auth']),
        isApp(){
            return this.$route.query.isApp;
        },
    },
    methods: {
        register(){
            this.$store.dispatch(AUTH_REGISTER, { 
                agree: this.agree, 
                name: this.name, 
                email: this.email, 
                password: this.password,
                password_confirmation: this.password_confirmation 
            }).then(() => {
                if(this.isApp) this.appHasRegistered = true
                else {
                    // this.$router.push({name: 'dashboard'})
                    this.webHasRegistered = true
                }
            }).catch(err => console.log(err))
        },
        backToApp () {
            if(this.isApp)
                if(typeof window.ReactNativeWebView !== 'undefined')
                    window.ReactNativeWebView.postMessage('login:' + this.email);

            if(this.isApp)
                if (typeof window.webkit.messageHandlers !== 'undefined') 
                    window.webkit.messageHandlers.handler.postMessage('login:' + this.email);
        }
    }
}
</script>