<template>
    <div class="c-designer is-light-mode u-bg-neutral">
        <div class="c-designer__topbar">
            <router-link v-if="!isApp" class="c-designer__back" :to="{ name: 'dashboard' }"
                :class="{ 'hidden': isApp === 'true' }">
                <i class="fal fa-angle-left"></i>
            </router-link>
            <div v-else class="c-designer__back" @click="appBack">
                <i class="fal fa-angle-left"></i>
            </div>
            <div class="c-designer__meta">
                <div class="u-flex u-gap-2 u-items-baseline">
                    <input v-if="editableTitle" type="text" v-model="project.project.title" class="c-designer__title"
                        ref="title" @blur="saveTitle">
                    <h1 v-else class="c-designer__title">{{ project.project.title }}</h1>
                    <a v-if="editableTitle" @click="saveTitle" class="u-cursor-pointer"><i class="fal u-text-success"
                            :class="{ 'fa-spinner-third fa-spin': loadingTitle, 'fa-save': !loadingTitle }"></i></a>
                    <a v-else @click="editTitle" class="u-cursor-pointer"><i class="fal fa-edit u-text-faded"></i></a>
                    <i class="fal fa-spin"></i>
                </div>
                <div class="u-flex u-mt-2 u-items-center">

                    <a @click="addCustomer" class="c-btn c-btn--default c-btn--sm" v-if="project.project.customers.length">
                        {{ listCustomerNamesSentence }}
                        <i class="fal fa-edit u-ml-3"></i>
                    </a>
                    <a @click="addCustomer" v-else class="c-btn c-btn--default c-btn--sm u-mr-2">
                        <i class="fal fa-plus"></i> {{ $t('designer.add-customer.add-customer') }}
                    </a>

                    <button @click="saveStencil" v-if="!project.ui.saved" :disabled="project.loading"
                        class="c-btn c-btn--default c-btn--sm u-mr-2">
                        <i class="fal fa-save"></i> {{ $t('designer.save') }}
                    </button>
                    <a @click="cropSelection" v-if="project.ui.mode == 'crop'"
                        class="c-btn c-btn--default c-btn--sm u-mr-2">
                        <i class="fal fa-crop"></i> {{ $t('designer.tools.crop.crop') }}
                    </a>

                    <button @click="reset" class="c-btn c-btn--icon c-btn--default u-mr-2">
                        <i class="fal fa-trash"></i></button>

                    <button @click="undo" class="c-btn c-btn--icon c-btn--default"
                        :disabled="project.loading || !project.history[$store.getters.getLatestActiveHistoryPoint] || !project.history[$store.getters.getLatestActiveHistoryPoint].canUndo"><i
                            class="fal fa-backward-step"></i></button>
                    <!-- <button @click="redo" class="c-btn c-btn--icon c-btn--default" :disabled="project.loading || $store.getters.getLatestActiveHistoryPoint === (project.history.length - 1)"><i class="fal fa-forward-step"></i></button> -->

                    <div v-if="project.loading">
                        <i class="fal fa-spinner-third fa-spin u-text-primary"></i>
                    </div>
                    <!-- <span v-if="!project.ui.saved" class="c-pill c-pill--default c-pill--sm">Unsaved</span> -->
                </div>
            </div>
        </div>
        <div class="c-designer__canvas-wrapper" :class="{
            'is-draggable': project.ui.state == 'drag',
            'is-dragging': project.ui.dragging,
            'is-drawing': project.ui.state == 'draw',
            'is-erasing': project.ui.state == 'erase',
            'is-lasso': project.ui.state == 'freeselect',
            'is-loading-overlayed': project.loading,
        }">
            <img v-if="loadingCanvas" src="@/assets/images/loading.svg" alt="loading" class="u-max-w-mobile u-mx-auto">
            <canvas v-show="!loadingCanvas" ref="canvas" class="c-designer__canvas"></canvas>
            <div class="c-designer__cursor" :style="{ width: drawThickness + 'px', height: drawThickness + 'px' }"
                ref="cursor"></div>
            <ToolSelectors class="u-absolute u-right-2 u-top-10" v-if="ui.isMobile" />
        </div>
        <div class="c-designer__canvas-spinner-wrapper" v-if="project.loading">
            <i class="fal fa-spinner-third fa-spin u-text-primary u-ml-2"></i>
        </div>
        <div class="c-designer__toolbar-wrapper"
            :class="{ 'is-active': !project.ui.toolPanelStates.collapsed, 'is-loading': project.loading }">
            <div class="c-designer__tools-wrapper">
                <div class="u-grid u-gap-2">
                    <a @click="project.ui.toolPanelStates.collapsed = !project.ui.toolPanelStates.collapsed"
                        class="c-btn c-btn--icon c-btn--primary desktop:u-hidden">
                        <i class="fal"
                            :class="{ 'fa-tools': project.ui.toolPanelStates.collapsed, 'fa-angle-right': !project.ui.toolPanelStates.collapsed && !ui.isMobile, 'fa-angle-down': !project.ui.toolPanelStates.collapsed && ui.isMobile }"></i>
                    </a>
                    <ToolSelectors v-if="!ui.isMobile" />
                </div>
            </div>
            <div class="c-designer__toolbar">
                <div class="c-designer__toolbar-container">
                    <div class="c-designer__toolbar-top">
                        <a @click="printPanel = true" class="c-btn c-btn--sidebar">
                            <i class="fal fa-print"></i> {{ $t('designer.print') }}
                        </a>
                        <a @click="exportStencil" class="c-btn c-btn--sidebar">
                            <i class="fal fa-download" :class="{ 'fa-spinner fa-spin': loadingExport }"></i> {{
                                $t('designer.export') }}
                        </a>
                        <a @click="saveStencil" class="c-btn c-btn--sidebar">
                            <i class="fal fa-save"></i> {{ $t('designer.save') }}
                        </a>
                    </div>

                    <ul v-if="project.ui.toolPanelStates.photocopy || project.ui.toolPanelStates.stencil"
                        class="c-designer-tool__tabs c-select-tool">
                        <li class="c-designer-tool__tab-title">
                            {{ $t('designer.tools.stencil.selection') }}
                        </li>
                        <li class="c-designer-tool__tab-item" :class="{ 'is-active': project.ui.mode == 'default' }">
                            <a @click="unsetSelection" class="c-designer-tool__tab">
                                <i class="fal fa-vector-square"></i>
                            </a>
                        </li>
                        <li class="c-designer-tool__tab-item" :class="{ 'is-active': project.ui.mode == 'select' }">
                            <a @click="setSelection" class="c-designer-tool__tab">
                                <i class="fal fa-object-group"></i>
                            </a>
                        </li>
                        <li class="c-designer-tool__tab-item"
                            :class="{ 'is-active': (project.ui.mode == 'freeselect' || project.ui.states.freeselect.coords.length > 10) }">
                            <a @click="setFreeSelection" class="c-designer-tool__tab">
                                <i class="fal fa-lasso"></i>
                            </a>
                        </li>
                    </ul>

                    <ToolCrop v-if="project.ui.mode == 'crop'" :active="project.ui.toolPanelStates.crop"
                        @toggle="project.ui.toolPanelStates.crop = !project.ui.toolPanelStates.crop" />
                    <ToolDrawing v-if="project.ui.state == 'draw'" :active="project.ui.toolPanelStates.drawing"
                        @toggle="project.ui.toolPanelStates.drawing = !project.ui.toolPanelStates.drawing" />
                    <ToolErase v-if="project.ui.state == 'erase'" :active="project.ui.toolPanelStates.erase"
                        @toggle="project.ui.toolPanelStates.erase = !project.ui.toolPanelStates.erase" />
                    <ToolRefineStencil v-if="project.preprocessOptions.posterize"
                        :active="project.ui.toolPanelStates.stencil" @toggle="toogleMainTool('refine')" />
                    <ToolPhotocopy v-if="project.preprocessOptions.posterize" :active="project.ui.toolPanelStates.photocopy"
                        @toggle="toogleMainTool('photocopy')" />
                </div>
                <div class="u-mt-auto u-pb-5 c-designer__toolbar-bottom">
                    <ToolSnapshots :active="project.ui.toolPanelStates.snapshots"
                        @toggle="project.ui.toolPanelStates.snapshots = !project.ui.toolPanelStates.snapshots" />
                    <ToolNavigator :active="project.ui.toolPanelStates.navigator"
                        @toggle="project.ui.toolPanelStates.navigator = !project.ui.toolPanelStates.navigator" />
                </div>
            </div>
        </div>
    </div>
    <teleport to="body">
        <component :is="wrapper" v-model="printPanel">
            <PanelPrint />
        </component>
        <Overlay v-model="printPanel" @close="printPanel = false" />

        <component :is="wrapper" v-model="userPanel">
            <PanelUserAdd @attach="attachCustomer" @detach="attachCustomer" :activeCustomers="project.project.customer_ids"
                v-if="userPanel" />
        </component>
        <Overlay v-model="userPanel" @close="userPanel = false" />
    </teleport>
</template>
<script>
import { Store as store } from '@store/index';
import { mapState, mapGetters } from 'vuex'

import {
    PROJECT_GET_DATA,
    // PROJECT_PREPROCESS,
    PROJECT_DRAW_CANVAS,
    PROJECT_PREPARE_CANVAS,
    PROJECT_GET_PROCESSED_IMAGE,
    // PROJECT_CLEAR_CANVAS
} from '@store/stores/project/types/action-types'

import Modal from '@components/modal'
import Overlay from '@components/overlay';
import BarBottom from '@components/bar-bottom';
import ToolCrop from '@components/designer/tool-crop'
import ToolDrawing from '@components/designer/tool-drawing'
import ToolErase from '@components/designer/tool-erase'
import ToolRefineStencil from '@components/designer/refine-stencil'
import ToolPhotocopy from '@components/designer/photocopy'
import ToolNavigator from '@components/designer/tool-navigator'
import ToolSelectors from '@components/designer/tool-selectors'
import ToolSnapshots from '@components/designer/tool-snapshots'

import PanelPrint from '@parts/panels/print'
import PanelUserAdd from '@parts/panels/user-add'

import { PROJECT_EXPORT_WITH_BACKGROUND, PROJECT_RESET_STATES, PROJECT_SAVE, PROJECT_SAVE_TITLE } from '@store/stores/project/types/action-types';
import { PROJECT_CROP_IMAGE, PROJECT_HISTORY_UNDO, PROJECT_HISTORY_REDO, PROJECT_RESET } from '@store/stores/project/types/action-types';
import { PROJECT_ADD_CUSTOMER, PROJECT_RESET_CANVAS_SIZES, PROJECT_SET_SHORTCUTS } from '../store/stores/project/types/action-types';

import { PROJECT_SET_STENCIL_SELECTION } from '@store/stores/project/types/mutation-types'
import { PROJECT_RESET_SELECTION_LINE, PROJECT_SET_STENCIL_FREE_SELECTION, PROJECT_UNSET_STENCIL_SELECTION, PROJECT_SET_HISTORY_POINT } from '@store/stores/project/types/mutation-types'

export default {
    setup() {
        store.watch((state) => state.project.ui.zoom, () => {
            store.dispatch(PROJECT_DRAW_CANVAS);
        }, { deep: true })
    },
    data() {
        return {
            loadingCanvas: false,
            loadingTitle: false,
            loadingExport: false,
            editableTitle: false,
            panel: false,
            printPanel: false,
            userPanel: false,
        }
    },
    components: {
        Modal,
        Overlay,
        ToolErase,
        BarBottom,
        PanelPrint,
        PanelUserAdd,
        ToolCrop,
        ToolDrawing,
        ToolSelectors,
        ToolNavigator,
        ToolSnapshots,
        ToolRefineStencil,
        ToolPhotocopy,
    },
    props: ['project_id'],
    mounted() {
        this.loadingCanvas = true
        this.$store.dispatch(PROJECT_RESET_STATES).then(() => {
            this.$store.dispatch(PROJECT_GET_DATA, this.project_id).then(() => {

                if (this.project.preprocessOptions.was_edge_detected) {
                    this.$store.dispatch(PROJECT_PREPARE_CANVAS, this.$refs.canvas)
                    this.loadingCanvas = false
                    this.$store.dispatch(PROJECT_GET_PROCESSED_IMAGE);
                } else {
                    this.$store.dispatch(PROJECT_PREPARE_CANVAS, this.$refs.canvas);
                    // this.$store.dispatch(PROJECT_PREPROCESS).then(() => {
                    //     this.loadingCanvas = false
                    // })
                    this.$store.dispatch(PROJECT_GET_PROCESSED_IMAGE).then(() => {
                        this.loadingCanvas = false
                    });
                }

                this.$store.commit(PROJECT_SET_HISTORY_POINT, 'Open')
            })
        });

        window.addEventListener("keydown", this.setShortKeys);
        window.addEventListener('resize', this.resetCanvas);

        this.$refs.canvas.addEventListener("mousemove", (e) => {
            this.$refs.cursor.style.left = e.clientX + 'px'
            this.$refs.cursor.style.top = e.clientY + 'px'
        });

        this.project.ui.toolPanelStates.photocopy = false;
        this.project.ui.toolPanelStates.stencil = true;

        // store.watch(
        //     (state) => state.project.ui.state,
        //     (newValue, oldValue) => {
        //         if (oldValue == 'draw' || oldValue == 'erase') {
        //             this.$store.dispatch(PROJECT_SAVE).then(() => {
        //                 this.$store.dispatch(PROJECT_CLEAR_CANVAS, this.$refs.canvas)
        //             })
        //             // this.saveStencil()
        //             // this.$store.dispatch(PROJECT_SAVE).then(() => {
        //             // this.$store.dispatch(PROJECT_RESET_STATES).then(() => {
        //             //     this.$store.dispatch(PROJECT_GET_DATA, this.project_id).then(() => {

        //             //         if (this.project.preprocessOptions.was_edge_detected) {
        //             //             this.$store.dispatch(PROJECT_PREPARE_CANVAS, this.$refs.canvas)
        //             //             this.loadingCanvas = false
        //             //             // this.$store.dispatch(PROJECT_GET_PROCESSED_IMAGE);
        //             //         } else {
        //             //             this.$store.dispatch(PROJECT_PREPARE_CANVAS, this.$refs.canvas);
        //             //             // this.$store.dispatch(PROJECT_PREPROCESS).then(() => {
        //             //             //     this.loadingCanvas = false
        //             //             // })
        //             //             // this.$store.dispatch(PROJECT_GET_PROCESSED_IMAGE).then(() => {
        //             //             //     this.loadingCanvas = false
        //             //             // });
        //             //         }

        //             // //         this.$store.commit(PROJECT_SET_HISTORY_POINT, 'Open')
        //             //     })
        //             // });
        //             // })
        //         }
        //     }
        // )
    },
    unmounted() {
        window.removeEventListener("keydown", this.setShortKeys);
        window.removeEventListener("resize", this.resetCanvas);
    },
    computed: {
        ...mapState(['project', 'ui', 'user']),
        ...mapGetters({ drawThickness: 'drawThickness' }),
        isApp() {
            return this.$route.query.isApp;
        },
        wrapper() {
            return this.ui.isMobile ? BarBottom : Modal
        },
        selectionStyle() {
            return {
                'width': this.project.preprocessOptions.clip.position.pos.swidth + 'px',
                'height': this.project.preprocessOptions.clip.position.pos.sheight + 'px',
                'top': this.project.preprocessOptions.clip.position.pos.sy + 'px',
                'left': this.project.preprocessOptions.clip.position.pos.sx + 'px',
            }
        },
        listCustomerNamesSentence() {
            let user = ''

            if (this.project.project.customers.length) {

                this.project.project.customers.forEach((val, key, arr) => {
                    let comma = key === 0 ? '' : ','
                    let and = key === 0 ? '' : ' ' + this.$t('main.and-divider') + ' '
                    user += Object.is(arr.length - 1, key) ? and + ' ' + val.name : comma + ' ' + val.name;
                });
            }

            return user
        }
    },
    methods: {
        unsetSelection() {
            this.$store.commit(PROJECT_UNSET_STENCIL_SELECTION)
            this.$store.commit(PROJECT_RESET_SELECTION_LINE)
            this.$store.dispatch(PROJECT_DRAW_CANVAS);
        },
        setSelection() {
            this.$store.commit(PROJECT_SET_STENCIL_SELECTION)
            this.$store.dispatch(PROJECT_DRAW_CANVAS);
        },
        setFreeSelection() {
            this.$store.commit(PROJECT_SET_STENCIL_FREE_SELECTION)
            this.$store.dispatch(PROJECT_DRAW_CANVAS);
        },
        toogleMainTool(tool) {
            this.project.ui.toolPanelStates.photocopy = false;
            this.project.ui.toolPanelStates.stencil = false;

            if (tool == 'photocopy') this.project.ui.toolPanelStates.photocopy = !this.project.ui.toolPanelStates.photocopy
            if (tool == 'refine') this.project.ui.toolPanelStates.stencil = !this.project.ui.toolPanelStates.stencil
        },
        exportStencil() {

            this.loadingExport = true;
            this.$store.dispatch(PROJECT_SAVE).then(() => {

                if (this.isApp) {
                    this.loadingExport = false;
                    if (typeof window.ReactNativeWebView !== 'undefined')
                        window.ReactNativeWebView.postMessage('export');

                    if (typeof window.webkit.messageHandlers !== 'undefined')
                        window.webkit.messageHandlers.handler.postMessage('export');

                    return;
                }

                this.$store.dispatch(PROJECT_EXPORT_WITH_BACKGROUND).then(() => {
                    this.loadingExport = false;
                });
            })
        },
        saveStencil() {
            this.$store.dispatch(PROJECT_SAVE);
        },
        editTitle() {
            this.editableTitle = true;
            this.$nextTick(() => {

                this.$refs.title.focus()
                this.$refs.title.select()
            })
        },
        saveTitle() {
            this.loadingTitle = true;

            this.$store.dispatch(PROJECT_SAVE_TITLE).then(() => {
                this.editableTitle = false;
                this.loadingTitle = false

                if (this.isApp)
                    if (typeof window.ReactNativeWebView !== 'undefined')
                        window.ReactNativeWebView.postMessage('titleSaved');

                if(this.isApp)
                    if (typeof window.webkit.messageHandlers !== 'undefined')
                        window.webkit.messageHandlers.handler.postMessage('titleSaved');
            })
        },
        cropSelection() {
            this.$store.dispatch(PROJECT_CROP_IMAGE)
        },
        addCustomer() {
            this.userPanel = true
        },
        setShortKeys(e) {
            this.$store.dispatch(PROJECT_SET_SHORTCUTS, e)
        },
        reset() {
            if (this.user.subscription_data.limits.can_create_projects && this.user.subscription_data.limits.can_delete_projects) {
                this.$swal({
                    title: this.$t('project.reset.confirm-title'),
                    text: this.$t('project.reset.confirm-text'),
                    icon: 'error',
                    confirmButtonText: this.$t('project.reset.confirm-btn'),
                    cancelButtonText: this.$t('project.reset.cancel-btn')
                }).then(result => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(PROJECT_RESET)
                    }
                });
            } else {
                if (!this.user.subscription_data.limits.can_delete_projects)
                    this.$swal({
                        title: this.$t('project.reset.ability-not-available.confirm-title'),
                        text: this.$t('project.reset.ability-not-available.confirm-text'),
                        icon: 'error',
                        cancelButtonText: this.$t('project.reset.ability-not-available.cancel-btn')
                    });
                else
                    this.$swal({
                        title: this.$t('project.reset.limit-reached.confirm-title'),
                        text: this.$t('project.reset.limit-reached.confirm-text'),
                        icon: 'error',
                        cancelButtonText: this.$t('project.reset.limit-reached.cancel-btn')
                    });
            }
        },
        undo() {
            this.$store.dispatch(PROJECT_HISTORY_UNDO);
        },
        redo() {
            this.$store.dispatch(PROJECT_HISTORY_REDO);
        },
        attachCustomer(customer) {
            this.$store.dispatch(PROJECT_ADD_CUSTOMER, customer).then(() => {
                this.userPanel = false

                if (this.isApp)
                    if (typeof window.ReactNativeWebView !== 'undefined')
                        window.ReactNativeWebView.postMessage('customerSaved');

                if(this.isApp)
                    if (typeof window.webkit.messageHandlers !== 'undefined')
                        window.webkit.messageHandlers.handler.postMessage('customerSaved');
            })
        },
        resetCanvas() {
            this.$store.dispatch(PROJECT_RESET_CANVAS_SIZES)
        },
        appBack() {
            if (typeof window.ReactNativeWebView !== 'undefined')
                window.ReactNativeWebView.postMessage('back');

            if (typeof window.webkit.messageHandlers !== 'undefined')
                window.webkit.messageHandlers.handler.postMessage('back');
        },
    }
}
</script>