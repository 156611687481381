<template>
    <LayoutBase>
        <div v-if="customer" class="u-container u-max-w-tablet-large u-mt-4 tablet:u-mt-16">
           <form @submit.prevent="saveCustomer" class="u-grid u-grid-cols-3 u-gap-16">
               <div>
                   <h1>{{ customer.name }}</h1>

                    <div class="u-mt-4">
                        <button class="c-btn c-btn--primary u-mr-4">
                            <span><i class="fal fa-check"></i> {{ $t('customers.form.save') }}</span>
                        </button>

                        <router-link class="c-btn c-btn--text u-mt-2" :to="{ name: 'customers.show', params: { customer_id: customer._id }}">
                            {{ $t('customers.form.cancel') }}
                        </router-link>
                    </div>
               </div>
               <div class="u-col-span-2 u-pb-16">
                    <InputGroup :label="$t('customers.form.full-name')">
                        <InputBase 
                            type="text" 
                            v-model="form.name" 
                            :placeholder="$t('customers.form.full-name')"
                            autocomplete="name" 
                            :validations="[
                                {
                                    condition: errors && !!errors.name,
                                    text: errors && errors.name ? errors.name[0] : null
                                }
                            ]"/>
                    </InputGroup>

                    <InputGroup :label="$t('customers.form.email')">
                        <InputBase 
                            type="email" 
                            v-model="form.email" 
                            :placeholder="$t('customers.form.email')"
                            autocomplete="email" 
                            :validations="[
                                {
                                    condition: errors && !!errors.email,
                                    text: errors && errors.email ? errors.email[0] : null
                                }
                            ]"/>
                    </InputGroup>
                    <InputGroup :label="$t('customers.form.phone')">
                        <InputBase 
                            type="tel" 
                            v-model="form.phone" 
                            :placeholder="$t('customers.form.phone')"
                            autocomplete="phone" 
                            :validations="[
                                {
                                    condition: errors && !!errors.phone,
                                    text: errors && errors.phone ? errors.phone[0] : null
                                }
                            ]"/>
                    </InputGroup>

                    <h3 class="u-mt-16">{{ $t('customers.form.address') }}</h3>

                    <InputGroup :label="$t('customers.form.street')">
                        <InputBase 
                            type="text" 
                            v-model="form.street" 
                            :placeholder="$t('customers.form.street')"
                            autocomplete="street" 
                            :validations="[
                                {
                                    condition: errors && !!errors.street,
                                    text: errors && errors.street ? errors.street[0] : null
                                }
                            ]"/>
                    </InputGroup>

                    <InputGroup :label="$t('customers.form.postcode')">
                        <InputBase 
                            type="text" 
                            v-model="form.postcode" 
                            :placeholder="$t('customers.form.postcode')"
                            autocomplete="postcode" 
                            :validations="[
                                {
                                    condition: errors && !!errors.postcode,
                                    text: errors && errors.postcode ? errors.postcode[0] : null
                                }
                            ]"/>
                    </InputGroup>

                    <InputGroup :label="$t('customers.form.city')">
                        <InputBase 
                            type="text" 
                            v-model="form.city" 
                            :placeholder="$t('customers.form.city')"
                            autocomplete="city" 
                            :validations="[
                                {
                                    condition: errors && !!errors.city,
                                    text: errors && errors.city ? errors.city[0] : null
                                }
                            ]"/>
                    </InputGroup>

                    <InputGroup :label="$t('customers.form.country')">
                        <InputBase 
                            type="text" 
                            v-model="form.country" 
                            :placeholder="$t('customers.form.country')"
                            autocomplete="country" 
                            :validations="[
                                {
                                    condition: errors && !!errors.country,
                                    text: errors && errors.country ? errors.country[0] : null
                                }
                            ]"/>
                    </InputGroup>

               </div>
           </form>
        </div>
    </LayoutBase>
</template>
<script>
import LayoutBase from '@layouts/base'
import InputBase from '@components/input-base'
import InputGroup from '@components/input-group'

import { CUSTOMERS_GET_CUSTOMER } from '@store/stores/customers/types/action-types'
import { CUSTOMERS_SAVE_CUSTOMER } from '@store/stores/customers/types/action-types'

export default {
    components: {
        InputBase,
        LayoutBase,
        InputGroup
    },
    props: ['customer_id'],
    data(){
        return {
            customer: null,
            errors: null,
            form: {
                _id: null,
                name: null,
                email: null,
                phone: null,
                street: null,
                postcode: null,
                city: null,
                country: null,
            }
        }
    },
    mounted(){
        this.$store.dispatch(CUSTOMERS_GET_CUSTOMER, this.customer_id).then((customer) => {
            this.customer = customer

            this.form._id = customer._id,
            this.form.name = customer.name
            this.form.email = customer.email
            this.form.phone = customer.phone
            this.form.street = customer.street
            this.form.postcode = customer.postcode
            this.form.city = customer.city
            this.form.country = customer.country
        });
    },
    methods: {
        saveCustomer(){
            this.$store.dispatch(CUSTOMERS_SAVE_CUSTOMER, this.form).then(() => {

                this.$router.push({ name: 'customers.show', params: { customer_id: this.customer._id }})
                this.$store.commit('ui/setFlashSuccessMessage', this.$t('customers.form.success'))
            }).catch((resp) => {
                this.errors = resp.response.data.errors
                this.$store.commit('ui/setFlashErrorMessage', this.errors)
            });
        }
    }
}
</script>