<template>
    <LayoutBase>
        <!-- <div class="u-container u-max-w-tablet-large">
            <ul class="c-tabs u-mt-4 tablet:u-mt-16 u-border-b u-border-neutral-20 dark:u-border-neutral-20">
                <li class="c-tabs__tab is-active">
                    <router-link to="#" class="c-tabs__link">{{ $t('dashboard.personal') }}</router-link>
                </li>
                <li class="c-tabs__tab">
                    <router-link to="#" class="c-tabs__link">{{ $t('dashboard.team') }}</router-link>
                </li>
                
            </ul>
        </div> -->
       
        <div class="u-container u-max-w-tablet-large u-mt-8 tablet:u-mt-16">

            <Card>
                <div class="u-grid tablet:u-grid-cols-2 u-gap-4">
                    <div>
                        <strong class="u-text-primary">{{ $t('cta.stencil.leading') }}</strong>
                        <h1 class="u-mt-0">
                            {{ $t('cta.stencil.title') }}
                        </h1>

                        <p>{{ $t('cta.stencil.text') }}</p>
                    </div>
                    <div class="u-flex">
                        <InputDropUpload v-if="user.subscription_data.limits.can_create_projects" @select="handleNewProject" class="u-w-full">
                            <h5 class="u-w-1/2 u-mx-auto dark:u-text-neutral">{{ $t('cta.stencil.upload-text') }}</h5>
                            <span class="c-btn c-btn--primary u-mt-4">
                                <span class="dark:u-bg-neutral dark:u-text-neutral-50">
                                    <i class="fal fa-upload"></i> {{ $t('cta.stencil.upload-btn') }}
                                </span>
                            </span>
                        </InputDropUpload>
                        <div class="u-flex" v-else>
                            <div class="u-w-full bg:primary-50">
                                <h5 class="u-w-1/2 u-mx-auto u-text-primary">{{ $t('cta.stencil.upload-limits-exceeded') }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>

            <div v-if="user.subscription_data.plan_code == 'free' || !user.subscription_data.plan_code" class="u-py-4">
                <Card >
                    <template #header>
                        <h4>{{ $t('subscription.you-are-on-plan', {plan: $t('subscription.plans.' + user.subscription_data.plan_code)}) }}</h4>
                        <p v-if="user.subscription_data.plan_code == 'free' || !user.subscription_data.plan_code">{{ $t('subscription.praise-upgrade') }}</p>
                    </template>
                
                    <div class="u-mt-0">
                        <router-link :to="{ name: 'subscription' }" class="c-btn c-btn--primary"> 
                            <span>{{ $t('subscription.go-to-subscription') }}</span>
                        </router-link> 
                    </div>
                </Card>
            </div>

            <section class="o-section" ref="overview">

                <div class="u-flex u-justify-between u-items-center">
                    <h5>{{ $t('dashboard.latest-projects') }}</h5>
                    <form class="u-flex" @submit.prevent="search">
                        <button type="submit" class="c-btn c-btn--text">
                            <i class="fal fa-search"></i>
                        </button>
                        <input type="search" @blur="search" :placeholder="$t('dashboard.search')" class="c-input-blank" v-model="searchString">
                    </form>
                </div>
                <div class="u-grid u-gap-2 u-grid-cols-3 u-gap-y-4 u-mt-4">
                    <Project :user="user" :project="project" v-for="project in projects.items" :key="project._id" />
                    
                    <template v-if="projects.loading">
                        <ProjectLoading v-for="i in Array(9)" :key="i"  />
                    </template>
                </div>

                <div class="u-py-4">
                    <Pagination 
                        :currentPage="projects.page"
                        :max="(projects.total/projects.size).toFixed(0)"
                        @setPage="setPage" />
                </div>
            </section>
        </div>
    </LayoutBase>

    <teleport to="body">
        <Modal v-model="cropImage" class="u-p-4">
            <CropImage @process="process" :image="cropImageFile" />
        </Modal>
        <Loading v-if="loading" />
    </teleport>
</template>
<script>
import { mapState } from "vuex";
import { watch, ref } from "vue";
import { useRoute } from "vue-router";
import { Store } from "@store/index";

import { PROJECT_UPLOAD_AND_CREATE } from "@store/stores/project/types/action-types";
import { PROJECTS_GET_PROJECTS } from "@store/stores/projects/types/action-types";

import Card from "@components/card";
import Modal from '@components/modal'
import CropImage from '@components/crop-image'
import LayoutBase from "@layouts/base";
import Loading from "@components/loading";
import Project from "@components/project";
import Pagination from "@components/pagination";
import ProjectLoading from "@components/project-loading";
import InputDropUpload from "@components/input-drop-upload";


export default {
  setup() {
    const route = useRoute();
    const overview = ref(null);

    Store.dispatch(PROJECTS_GET_PROJECTS, {
      page: "p" in route.query ? route.query.p : null,
      size: "size" in route.query ? route.query.size : null,
      search: "search" in route.query ? route.query.size : null,
    });

    watch(
      () => route.query.p,
      async () => {
        Store.dispatch(PROJECTS_GET_PROJECTS, {
          page: "p" in route.query ? route.query.p : null,
          size: "size" in route.query ? route.query.size : null,
          search: "search" in route.query ? route.query.size : null,
        });

        overview.value.scrollIntoView({
          behavior: "smooth",
        });
      }
    );

    return {
      overview,
    };
  },
  components: {
    Card,
    Modal,
    Loading,
    Project,
    CropImage,
    LayoutBase,
    Pagination,
    ProjectLoading,
    InputDropUpload
  },
  data() {
    return {
      loading: false,
      cropImage: false,
      cropImageFile: null,
      searchString: ''
    };
  },
  computed: {
    ...mapState(["user", "projects"]),
  },
  methods: {
    process(file){
      this.cropImage = false;
      this.loading = true
      this.$store.dispatch(PROJECT_UPLOAD_AND_CREATE, file).then(async (resp) => {
        await this.$router.push({name: 'designer', params: {project_id: resp._id}})
        this.loading = false
      })
    },
    handleNewProject(file) {
        this.cropImageFile = file;
        this.cropImage = true
    },
    search() {
      this.$store.dispatch(PROJECTS_GET_PROJECTS, {
        page: 1,
        size: 9,
        search: this.searchString,
      });
    },
    setPage(p) {
      this.$router.push({
        name: "dashboard",
        query: {
          p: p,
          search: this.searchString,
        },
      });
    },
  },
};
</script>