<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  mounted(){
      this.$store.dispatch('ui/detectMobile');
      this.$store.dispatch('ui/detectIos');
      this.$store.dispatch('ui/setServiceWorker');
  }
}
</script>
