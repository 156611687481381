<template>
    <LayoutBase>
        <div class="u-container u-max-w-tablet-large u-mt-8 tablet:u-mt-16">
            
            <h2>{{ $t('account.account') }}</h2>

             <div class="u-mt-6">
                 <AvatarUpload
                    @fileSelected="uploadAvatar"
                    :loading="avatarLoading"
                    :validations="[
                        {
                            condition: !!errors?.avatar,
                            text: errors?.avatar ? errors.avatar : null
                        }
                    ]"
                    :init-avatar="user.profile.avatar_url"/>
             </div>


            <Card class="u-mt-8">
                <form @submit.prevent="updateAccount">
                    <InputGroup :label="$t('account.name')">
                        <InputBase 
                            type="text" 
                            v-model="form.name" 
                            :placeholder="$t('account.name')"
                            autocomplete="name" 
                            :validations="[
                                {
                                    condition: errors && !!errors.name,
                                    text: errors && errors.name ? errors.name[0] : null
                                }
                            ]"/>
                    </InputGroup>
                    
                    <InputGroup :label="$t('account.email')">
                        <InputBase 
                            type="email" 
                            v-model="form.email" 
                            :placeholder="$t('account.email')"
                            autocomplete="email" 
                            :validations="[
                                {
                                    condition: errors && !!errors.email,
                                    text: errors && errors.email ? errors.email[0] : null
                                }
                            ]"/>
                    </InputGroup>

                    <div class="u-mt-8">
                        <h4>{{ $t('account.password') }}</h4>

                        <InputGroup :label="$t('account.password')">
                            <InputBase 
                                type="password" 
                                v-model="form.password" 
                                :placeholder="$t('account.password')"
                                autocomplete="password" 
                                :validations="[
                                    {
                                        condition: errors && !!errors.password,
                                        text: errors && errors.password ? errors.password[0] : null
                                    }
                                ]"/>
                        </InputGroup>
                        
                        <InputGroup :label="$t('account.password_confirmation')">
                            <InputBase 
                                type="password" 
                                v-model="form.password_confirm" 
                                :placeholder="$t('account.password_confirmation')"
                                autocomplete="password_confirmation" 
                                :validations="[
                                    {
                                        condition: errors && !!errors.password_confirm,
                                        text: errors && errors.password_confirm ? errors.password_confirm[0] : null
                                    }
                                ]"/>
                        </InputGroup>
                    </div>

                    <div class="u-mt-2">
                        <button class="c-btn c-btn--primary u-mt-4">
                            <span>
                                <i class="fal fa-check"></i> {{ $t('account.update.update') }}
                            </span>
                        </button>

                        <button @click.prevent="removeAccount" class="c-btn c-btn--primary u-mt-4 u-ml-2">
                            <span>
                                <i class="fal fa-trash"></i> {{ $t('account.delete.delete') }}
                            </span>
                        </button>
                    </div>
                </form>
            </Card>
        </div>
    </LayoutBase>
</template>
<script>
import { mapState } from 'vuex'

import Card from '@components/card'
import InputGroup from '@components/input-group'
import InputBase from '@components/input-base'
import AvatarUpload from '@components/avatar-upload'

import LayoutBase from '@layouts/base'
import { USER_STORE_USER, USER_UPLOAD_AVATAR } from '../store/stores/user/types/action-types'
import { ACCOUNT_REMOVE } from '../store/stores/user/types/action-types';

export default {
    components: {
        Card,
        InputBase,
        InputGroup,
        LayoutBase,
        AvatarUpload
    },
    data(){
        return {
            errors: [],
            avatarLoading: false,
            form: {
                _id: null,
                name: null,
                email: null,
                password: null,
                password_confirm: null
            }
        }
    },
    mounted(){
        this.form._id = this.user.profile._id
        this.form.name = this.user.profile.name
        this.form.email = this.user.profile.email
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        removeAccount(){
            this.$swal({
                title: this.$t('account.delete.confirm-title'),
                text: this.$t('account.delete.confirm-text'),
                icon: 'error',
                confirmButtonText: this.$t('account.delete.confirm-btn'),
                cancelButtonText: this.$t('account.delete.cancel-btn')
            }).then(result => {
                if(result.isConfirmed){
                    // this.deleting = true;
                    this.$store.dispatch(ACCOUNT_REMOVE).then(() => {
                        this.$store.commit('ui/setFlashSuccessMessage', this.$t('account.delete.success'))
                        setTimeout(() => {
                            window.location.reload()
                        }, 2500)
                    })
                }
            });
        },
        updateAccount(){
            let data = {
                ...this.form,
                change_password: !!this.form.password
            }

            this.errors = []
            this.$store.commit('ui/resetFlashErrorMessage')

            this.$store.dispatch(USER_STORE_USER, data).then(() => {
                this.$emit('close')

                this.$store.commit('ui/setFlashSuccessMessage', this.$t('account.update.success'))
            }).catch((resp) => {
                this.errors = resp.response.data.errors
                this.$store.commit('ui/setFlashErrorMessage', this.errors)
            })
        },
        uploadAvatar(data){
            this.avatarLoading = true;

            var avatarData = new FormData();
            avatarData.append("_id", this.user._id);
            avatarData.append("avatar", data[0]);

            this.$store.dispatch(USER_UPLOAD_AVATAR, avatarData).then(() => {
                this.avatarLoading = false
            }).catch((resp) => {
                this.errors.avatar = resp.data.message
                this.avatarLoading = false
            })
        }
    }
   
}
</script>