import { PROJECT_CHANGE_PRINT_PAGE, PROJECT_DRAG_PRINT_SELECTION, PROJECT_DRAW_PRINTABLE, PROJECT_DRAW_PRINT_SELECTION_BOX, PROJECT_PREPARE_PRINTABLE, PROJECT_PREPARE_PRINT_CANVAS, PROJECT_PREPARE_PRINT_CANVAS_ITEMS, PROJECT_PRINT_MOUSE_EFFECTS } from "../types/action-types"
import { PROJECT_MOVE_PRINT_SELECTION, PROJECT_RESIZE_PRINT_SELECTION_BL, PROJECT_RESIZE_PRINT_SELECTION_BR, PROJECT_RESIZE_PRINT_SELECTION_TL, PROJECT_RESIZE_PRINT_SELECTION_TR, PROJECT_SET_PAGE_SIZE, PROJECT_SET_PRINT_ANCHOR, PROJECT_SET_PRINT_CANVAS } from "../types/mutation-types"

import { cursorPosition } from "@helpers/cursor"

export default {
    [PROJECT_PREPARE_PRINT_CANVAS]: ({ commit, dispatch }, canvas) => {
        
        commit(PROJECT_SET_PRINT_CANVAS, canvas)
        commit(PROJECT_SET_PAGE_SIZE)
        dispatch(PROJECT_PREPARE_PRINT_CANVAS_ITEMS)
    },
    [PROJECT_PREPARE_PRINT_CANVAS_ITEMS]: ({ state, dispatch, getters }) => {

        let imgRatioLandscape = state.canvas.img.width / state.canvas.img.height
        let imgRatioPortrait = state.canvas.img.height / state.canvas.img.width  

        let imgRelativeWidth = state.print.size.w * state.print.canvas.width;
        let imgRelativeHeight = state.print.size.h * state.print.canvas.height;

        let newWidth = (imgRatioLandscape * imgRelativeHeight) / state.print.canvas.width
        let newHeight = (imgRatioPortrait * imgRelativeWidth) / state.print.canvas.height

        if(newHeight > 1){
            state.print.size.w = newWidth
        } else {
            state.print.size.h = newHeight
        }
        
        state.print.position.x = (1 - state.print.size.w) / 2
        state.print.position.y = (1 - state.print.size.h) / 2

        const dragEvent = (e) => {
            let cursor = cursorPosition(e)
            dispatch(PROJECT_DRAG_PRINT_SELECTION, cursor)
        }

        const dragStart = (e) => {

            let cursor = cursorPosition(e)

            state.print.dragging = true
            
            state.print.startDragPos = { ...getters.printCorners }
            state.print.anchorFix = e.type == 'mousedown' ? true : false;

            state.print.dragStartPosition.x = cursor.clientX 
            state.print.dragStartPosition.y = cursor.clientY

            state.print.canvas.addEventListener("mousemove", dragEvent)
            state.print.canvas.addEventListener("touchmove", dragEvent, { passive: true})
        }

        const dragStop = () => {
            state.print.dragging = false,

            state.print.canvas.removeEventListener("mousemove", dragEvent)
            state.print.canvas.removeEventListener("touchmove", dragEvent)

            state.print.anchorFix = false;
            state.print.lockCanvasDrag = false
        }

        state.print.canvas.addEventListener("mousedown", dragStart);
        state.print.canvas.addEventListener("touchstart", dragStart,{ passive: true});
        state.print.canvas.addEventListener("mouseup", dragStop);
        state.print.canvas.addEventListener("mouseout", dragStop);
        state.print.canvas.addEventListener("mouseleave", dragStop);
        state.print.canvas.addEventListener("touchend", dragStop, { passive: true});

        let mouseEvent = (e) => dispatch(PROJECT_PRINT_MOUSE_EFFECTS, e)
        state.print.canvas.addEventListener("mousemove", mouseEvent)
        state.print.canvas.addEventListener("touchmove", mouseEvent)

        dispatch(PROJECT_DRAW_PRINTABLE)
    },
    [PROJECT_PRINT_MOUSE_EFFECTS]: ({state, getters, commit}, e) => {

        let cursor = cursorPosition(e)

        if (state.print.lockCanvasDrag) {
            return false;
        }

        if(!state.print.anchorFix){
            let accuracy = 20
            
            const isBetween = (num1,num2,value) => value >= num1 && value <= num2 
            
            if(isBetween(getters.printCorners.x - accuracy, getters.printCorners.x + accuracy, cursor.clientX) && isBetween( getters.printCorners.y - accuracy, getters.printCorners.y + accuracy, cursor.clientY)) {
                commit(PROJECT_SET_PRINT_ANCHOR, 'tl')
            } else if(isBetween(getters.printCorners.x2 - accuracy, getters.printCorners.x2 + accuracy, cursor.clientX) && isBetween( getters.printCorners.y - accuracy, getters.printCorners.y + accuracy, cursor.clientY)) {
                commit(PROJECT_SET_PRINT_ANCHOR, 'tr')
            } else if(isBetween(getters.printCorners.x - accuracy, getters.printCorners.x + accuracy, cursor.clientX) && isBetween( getters.printCorners.y2 - accuracy, getters.printCorners.y2 + accuracy, cursor.clientY)) {
                commit(PROJECT_SET_PRINT_ANCHOR, 'bl')
            } else if(isBetween(getters.printCorners.x2 - accuracy, getters.printCorners.x2 + accuracy, cursor.clientX) && isBetween( getters.printCorners.y2 - accuracy, getters.printCorners.y2 + accuracy, cursor.clientY)) {
                commit(PROJECT_SET_PRINT_ANCHOR, 'br')
            } else if(isBetween(getters.printCorners.x + 20, getters.printCorners.x2 - 20, cursor.clientX) && isBetween(getters.printCorners.y + 20, getters.printCorners.y2 - 20, cursor.clientY)){
                commit(PROJECT_SET_PRINT_ANCHOR, 'drag')
            } else {
                commit(PROJECT_SET_PRINT_ANCHOR, null)
            }
        }
    },
    [PROJECT_DRAG_PRINT_SELECTION]: ({ state, dispatch, commit }, e) => {

        if(state.print.anchor == 'tl') {

            commit(PROJECT_RESIZE_PRINT_SELECTION_TL, e)
        } else if(state.print.anchor == 'tr') {
            commit(PROJECT_RESIZE_PRINT_SELECTION_TR, e)
        } else if(state.print.anchor == 'bl') {
            commit(PROJECT_RESIZE_PRINT_SELECTION_BL, e)
        } else if(state.print.anchor == 'br') {
            commit(PROJECT_RESIZE_PRINT_SELECTION_BR, e)
        } else if(state.print.anchor == 'drag') {
            commit(PROJECT_MOVE_PRINT_SELECTION, e)
        } else {
            return false
        }
    
        dispatch(PROJECT_DRAW_PRINTABLE)
    },
    [PROJECT_CHANGE_PRINT_PAGE]: ({ commit, dispatch } ) => {
        commit(PROJECT_SET_PAGE_SIZE)
        dispatch(PROJECT_PREPARE_PRINT_CANVAS_ITEMS)
    },
    [PROJECT_DRAW_PRINTABLE]: ({ state, dispatch, getters }) => {
        if(!state.print.ctx){
            return false
        }

        state.print.ctx.clearRect(0, 0, state.print.canvas.width, state.print.canvas.height);        
        
        state.canvas.ctx.lineWidth = 1;
        state.print.ctx.strokeStyle = '#ffaa2a';
        
        if(state.print.mirrored === 'mirrored'){
            state.print.ctx.save();
            state.print.ctx.scale(-1,1)

            state.print.ctx.drawImage(
                state.canvas.background,
                (state.print.position.x * state.print.canvas.width) * - 1,
                state.print.position.y * state.print.canvas.height,
                state.print.size.w * state.print.canvas.width * -1,
                state.print.size.h * state.print.canvas.height,
            )

            state.print.ctx.drawImage(
                state.canvas.canvasImg,
                (state.print.position.x * state.print.canvas.width) * - 1,
                state.print.position.y * state.print.canvas.height,
                state.print.size.w * state.print.canvas.width * -1,
                state.print.size.h * state.print.canvas.height,
            )
            
            
            state.print.ctx.setTransform(1, 0, 0, 1, 0, 0);
            state.print.ctx.restore();
        } else {
            state.print.ctx.drawImage(
                state.canvas.background,
                (state.print.position.x * state.print.canvas.width),
                state.print.position.y * state.print.canvas.height,
                state.print.size.w * state.print.canvas.width,
                state.print.size.h * state.print.canvas.height,
            )
            
            state.print.ctx.drawImage(
                state.canvas.canvasImg,
                (state.print.position.x * state.print.canvas.width),
                state.print.position.y * state.print.canvas.height,
                state.print.size.w * state.print.canvas.width,
                state.print.size.h * state.print.canvas.height,
            )
        }

        state.print.ctx.rect(
            state.print.position.x * state.print.canvas.width,
            state.print.position.y * state.print.canvas.height,
            state.print.size.w * state.print.canvas.width,
            state.print.size.h * state.print.canvas.height,
        );

        state.print.ctx.font = "14px Rubik, Sans Serif";
        state.print.ctx.fillStyle = '#9A9A9A';
        state.print.ctx.textAlign = "right";
        state.print.ctx.textBaseline = "hanging";
        state.print.ctx.fillText(
            getters.printCorners.mmw.toFixed(0) + "mm x " + getters.printCorners.mmh.toFixed(0) + 'mm, ' + getters.printCorners.dpi.toFixed(0) + 'dpi', 
            getters.printCorners.x2,
            getters.printCorners.y2 + 12
        );        

        state.print.ctx.stroke()

        dispatch(PROJECT_DRAW_PRINT_SELECTION_BOX)
    },
    [PROJECT_DRAW_PRINT_SELECTION_BOX]: ({ state, getters }) => {
        
        // set resizers
        state.print.ctx.globalCompositeOperation="source-over";
        state.print.ctx.fillStyle = '#ffaa2a';
        
        state.print.ctx.beginPath();
        state.print.ctx.arc(
            getters.printCorners.x,
            getters.printCorners.y,
            5,0,2 * Math.PI
            );
        state.print.ctx.fill()
            
        state.print.ctx.beginPath();
        state.print.ctx.arc(
            getters.printCorners.x2,
            getters.printCorners.y,
            5,0,2 * Math.PI
            );
        state.print.ctx.fill()
            
        state.print.ctx.beginPath();
        state.print.ctx.arc(
            getters.printCorners.x2,
            getters.printCorners.y2,
            5,0,2 * Math.PI
            );
        state.print.ctx.fill()
            
        state.print.ctx.beginPath();
        state.print.ctx.arc(
            getters.printCorners.x,
            getters.printCorners.y2,
            5,0,2 * Math.PI
        );

        state.print.ctx.fill()
    },
    [PROJECT_PREPARE_PRINTABLE]: ({state}) => {

        var canvas = document.createElement('canvas');
        let ctx  = canvas.getContext('2d');

        let width = (state.print.page.w / 25.4) * state.print.resolution
        let height = (state.print.page.h / 25.4) * state.print.resolution

        if(state.print.orientation == 'landscape'){
            width = state.print.page.hpx
            height = state.print.page.wpx
        }

        canvas.width = width
        canvas.height = height

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        if(state.print.mirrored === 'mirrored'){
            ctx.save();
            ctx.scale(-1,1)

            ctx.drawImage(
                state.canvas.background,
                state.print.position.x * canvas.width * -1,
                state.print.position.y * canvas.height,
                state.print.size.w * canvas.width * -1,
                state.print.size.h * canvas.height,
            )
            
            ctx.drawImage(
                state.canvas.canvasImg,
                state.print.position.x * canvas.width * -1,
                state.print.position.y * canvas.height,
                state.print.size.w * canvas.width * -1,
                state.print.size.h * canvas.height,
            )

            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.restore();
        } else {
            ctx.drawImage(
                state.canvas.background,
                state.print.position.x * canvas.width,
                state.print.position.y * canvas.height,
                state.print.size.w * canvas.width,
                state.print.size.h * canvas.height,
            )
            
            ctx.drawImage(
                state.canvas.canvasImg,
                state.print.position.x * canvas.width,
                state.print.position.y * canvas.height,
                state.print.size.w * canvas.width,
                state.print.size.h * canvas.height,
            )
        }


        return canvas
    }
}