<template>
    <transition name="overlay-toggle">
        <div class="c-overlay" @click="$emit('close');" v-if="active"></div>
    </transition>
</template>
<script>
import { mapState } from 'vuex';

  export default {
    name: 'Overlay',
    emits: ['close'],
    props: {
        modelValue: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            active: this.modelValue
        }
    },
    watch: {
        modelValue(value) {
            this.active = value;
        },
    },
    computed: {
        ...mapState(['ui'])
    }
  }
</script>
