<template>
    <label class="c-input-drop-upload" :class="[{'is-active': dragging}]" v-if="!file">
        <div class="c-input-drop-upload__dropzone"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
        >

            <div v-if="loading">
                <i class="fal fa-spinner fa-spin c-input-drop-upload__icon"></i>
                <small>{{ $t('main.loading') }}</small>
            </div>
            <div v-else-if="$slots.default">
                <slot />
            </div>

        </div>
        <input type="file" ref="file" :accept="accept"
         @change="onChange" class="c-input-drop-upload__input">
    </label>
</template>
<script>

export default {
    props: {
      modelValue: {
        type: String,
        required: true
      },
      accept: {
          type: String,
          default: "image/jpeg,image/jpg,image/png"
      }
    },
    data(){
        return {
            loading: false
        }
    },
    emits: ['select'],
    methods: {
        onChange() {
            this.$emit('select',this.$refs.file.files[0])
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('is-active')) {
                event.currentTarget.classList.remove('is-active');
                event.currentTarget.classList.add('is-active');
            }
            },
        dragleave(event) {
            event.currentTarget.classList.remove('is-active');
        },
        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange();
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        }
    }
}
</script>
