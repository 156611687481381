export const PROJECT_SET_SHORTCUTS = 'PROJECT_SET_SHORTCUTS'
export const PROJECT_UPLOAD_AND_CREATE = 'PROJECT_UPLOAD_AND_CREATE'
export const PROJECT_RESET_STATES = 'PROJECT_RESET_STATES'
export const PROJECT_CREATE = 'PROJECT_CREATE'
export const PROJECT_ADD_SNAPSHOT = 'PROJECT_ADD_SNAPSHOT'
export const PROJECT_DOWNLOAD_SNAPSHOT = 'PROJECT_DOWNLOAD_SNAPSHOT'
export const PROJECT_REMOVE_SNAPSHOT = 'PROJECT_REMOVE_SNAPSHOT'
export const PROJECT_ADD_IMAGE = 'PROJECT_ADD_IMAGE'
export const PROJECT_PREPROCESS = 'PROJECT_PREPROCESS'
export const PROJECT_GET_DATA = 'PROJECT_GET_DATA'
export const PROJECT_GET_PROCESSED_IMAGE = 'PROJECT_GET_PROCESSED_IMAGE'
export const PROJECT_INTERACT_MOUSE_START = 'PROJECT_INTERACT_MOUSE_START'
export const PROJECT_DRAW_CANVAS = 'PROJECT_DRAW_CANVAS'
export const PROJECT_SET_IMAGE = 'PROJECT_SET_IMAGE'
export const PROJECT_DRAW_LINES = 'PROJECT_DRAW_LINES'
export const PROJECT_DRAW_SELECTION_BOX = 'PROJECT_DRAW_SELECTION_BOX'
export const PROJECT_DRAW_FREE_SELECTION_BOX = 'PROJECT_DRAW_FREE_SELECTION_BOX'
export const PROJECT_PREPARE_CANVAS = 'PROJECT_PREPARE_CANVAS'
export const PROJECT_CLEAR_CANVAS = 'PROJECT_CLEAR_CANVAS'
export const PROJECT_PREPARE_PRINT_CANVAS = 'PROJECT_PREPARE_PRINT_CANVAS'
export const PROJECT_PREPARE_PRINT_CANVAS_ITEMS = 'PROJECT_PREPARE_PRINT_CANVAS_ITEMS'
export const PROJECT_SET_DRAG = 'PROJECT_SET_DRAG'
export const PROJECT_DRAG_CANVAS = 'PROJECT_DRAG_CANVAS'
export const PROJECT_DRAW_LINE = 'PROJECT_DRAW_LINE'
export const PROJECT_ERASE_LINE = 'PROJECT_ERASE_LINE'
export const PROJECT_DRAG_SELECTION = 'PROJECT_DRAG_SELECTION'
export const PROJECT_DRAW_SELECTION = 'PROJECT_DRAW_SELECTION'
export const PROJECT_MOUSE_EFFECTS = 'PROJECT_MOUSE_EFFECTS'
export const PROJECT_PREPARE_PREVIEW = 'PROJECT_PREPARE_PREVIEW'
export const PROJECT_DRAW_PREVIEW = 'PROJECT_DRAW_PREVIEW'
export const PROJECT_DRAW_PRINTABLE = 'PROJECT_DRAW_PRINTABLE'
export const PROJECT_EXPORT_WITH_BACKGROUND = 'PROJECT_EXPORT_WITH_BACKGROUND'
export const PROJECT_SAVE = 'PROJECT_SAVE'
export const PROJECT_SAVE_TITLE = 'PROJECT_SAVE_TITLE'
export const PROJECT_ADD_CUSTOMER = 'PROJECT_ADD_CUSTOMER'
export const PROJECT_DRAW_PRINT_SELECTION_BOX = 'PROJECT_DRAW_PRINT_SELECTION_BOX'
export const PROJECT_DRAG_PRINT_SELECTION = 'PROJECT_DRAG_PRINT_SELECTION'
export const PROJECT_PRINT_MOUSE_EFFECTS = 'PROJECT_PRINT_MOUSE_EFFECTS'
export const PROJECT_CHANGE_PRINT_PAGE = 'PROJECT_CHANGE_PRINT_PAGE'
export const PROJECT_PREPARE_PRINTABLE = 'PROJECT_PREPARE_PRINTABLE'
export const PROJECT_CROP_IMAGE = 'PROJECT_CROP_IMAGE'
export const PROJECT_SET_BACKGROUND = 'PROJECT_SET_BACKGROUND'
export const PROJECT_GET_BACKGROUND = 'PROJECT_GET_BACKGROUND'
export const PROJECT_HISTORY_UNDO = 'PROJECT_HISTORY_UNDO'
export const PROJECT_HISTORY_REDO = 'PROJECT_HISTORY_REDO'
export const PROJECT_HISTORY_SET = 'PROJECT_HISTORY_SET'
export const PROJECT_RESET_CANVAS_SIZES = 'PROJECT_RESET_CANVAS_SIZES'
export const PROJECT_RESIZE_IMAGE = 'PROJECT_RESIZE_IMAGE'
export const PROJECT_REMOVE = 'PROJECT_REMOVE'
export const PROJECT_SET_ABORT_CONTROLLER = 'PROJECT_SET_ABORT_CONTROLLER'
export const PROJECT_UNDO = 'PROJECT_UNDO'
export const PROJECT_REDO = 'PROJECT_REDO'
export const PROJECT_RESET = 'PROJECT_RESET'
export const PROJECT_ROTATE = 'PROJECT_ROTATE'
