import { PROJECT_MOVE_PRINT_SELECTION, PROJECT_RESIZE_PRINT_SELECTION_BL, PROJECT_RESIZE_PRINT_SELECTION_BR, PROJECT_RESIZE_PRINT_SELECTION_TL, PROJECT_RESIZE_PRINT_SELECTION_TR, PROJECT_SET_PAGE_SIZE, PROJECT_SET_PRINT_ANCHOR, PROJECT_SET_PRINT_CANVAS  } from "../types/mutation-types";

export default {
    [PROJECT_SET_PRINT_CANVAS]: (state, canvas) => {
        
        state.print.canvas = canvas;
        state.print.ctx = canvas.getContext('2d');
        state.print.page = state.print.sizes[0]
        
        canvas.width = canvas.parentElement.offsetWidth;
    },
    [PROJECT_SET_PAGE_SIZE]: (state) => {
        if(state.print.orientation == 'portrait'){
            let ratio = state.print.page.h / state.print.page.w;
            state.print.canvas.height = state.print.canvas.width * ratio
        } else if( state.print.orientation == 'landscape' ){
            let ratio = state.print.page.w / state.print.page.h;
            state.print.canvas.height = state.print.canvas.width * ratio
        }
    },
    [PROJECT_RESIZE_PRINT_SELECTION_TL]: (state, e) => {

        let movedX = state.print.startDragPos.x - e.clientX
        let sizedWidthPercentage = (state.print.startDragPos.w + movedX) / state.print.startDragPos.w
        let newWidth = (state.print.startDragPos.w + movedX) / state.print.canvas.width
        let newHeight = sizedWidthPercentage * (state.print.startDragPos.h / state.print.canvas.height)
        let sizedHeight = state.print.startDragPos.h - (newHeight * state.print.canvas.height)
        
        if(newWidth < 0 || newHeight < 0){
            return; // prevent flipping image
        }

        state.print.lockCanvasDrag = true
        state.print.size.w = newWidth
        state.print.size.h = newHeight
        state.print.position.x = e.clientX / state.print.canvas.width
        state.print.position.y = (state.print.startDragPos.y - ( state.print.startDragPos.py - sizedHeight)) / state.print.canvas.height   
    },
    [PROJECT_RESIZE_PRINT_SELECTION_TR]: (state, e) => {
        
        let movedX = state.print.startDragPos.x2 - e.clientX
        let sizedWidthPercentage = (state.print.startDragPos.w - movedX) / state.print.startDragPos.w
        let newWidth = (state.print.startDragPos.w - movedX) / state.print.canvas.width
        let newHeight = sizedWidthPercentage * (state.print.startDragPos.h / state.print.canvas.height)
        let sizedHeight = state.print.startDragPos.h - (newHeight * state.print.canvas.height)
        
        if(newWidth < 0 || newHeight < 0){
            return; // prevent flipping image
        }

        state.print.lockCanvasDrag = true
        state.print.size.w = newWidth
        state.print.size.h = newHeight
        state.print.position.y = (state.print.startDragPos.y - ( state.print.startDragPos.py - sizedHeight)) / state.print.canvas.height
    },
    [PROJECT_RESIZE_PRINT_SELECTION_BL]: (state, e) => {
        
        let movedX = state.print.startDragPos.x - e.clientX
        let sizedWidthPercentage = (state.print.startDragPos.w + movedX) / state.print.startDragPos.w
        let newWidth = (state.print.startDragPos.w + movedX) / state.print.canvas.width
        let newHeight = sizedWidthPercentage * (state.print.startDragPos.h / state.print.canvas.height)
        
        if(newWidth < 0 || newHeight < 0){
            return; // prevent flipping image
        }

        state.print.lockCanvasDrag = true
        state.print.size.w = newWidth
        state.print.size.h = newHeight
        state.print.position.x = e.clientX / state.print.canvas.width
    },
    [PROJECT_RESIZE_PRINT_SELECTION_BR]: (state, e) => {
        
        let movedX = state.print.startDragPos.x2 - e.clientX
        let sizedWidthPercentage = (state.print.startDragPos.w - movedX) / state.print.startDragPos.w
        let newWidth = (state.print.startDragPos.w - movedX) / state.print.canvas.width
        let newHeight = sizedWidthPercentage * (state.print.startDragPos.h / state.print.canvas.height)
        
        if(newWidth < 0 || newHeight < 0){
            return; // prevent flipping image
        }
        
        state.print.lockCanvasDrag = true
        state.print.size.w = newWidth
        state.print.size.h = newHeight
    },
    [PROJECT_MOVE_PRINT_SELECTION]: (state, e) => {
        
        let movedX = state.print.dragStartPosition.x - e.clientX
        let movedY = state.print.dragStartPosition.y - e.clientY
        
        state.print.position.x = (state.print.startDragPos.x - movedX) / state.print.canvas.width
        state.print.position.y = (state.print.startDragPos.y - movedY) / state.print.canvas.height
    },
    [PROJECT_SET_PRINT_ANCHOR]: (state, anchor) => {
        let cursors = {
            'tl' : 'nwse-resize',
            'tr' : 'nesw-resize',
            'bl' : 'nesw-resize',
            'br' : 'nwse-resize',
            'drag': 'move'
        }

        state.print.canvas.style.cursor = cursors[anchor] ?? 'inherit'
        state.print.anchor = anchor
    }
}