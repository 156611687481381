<template>
    <div v-if="validations">
        <div
            v-for="(validation, index) in getErrors"
            :key="index"
            class="c-input__help is-error"
            :class="validation.classes"
        >
          {{ $t(validation.text) }}
        </div>
    </div>
</template>
<script>
    export default {
      props: {
        validations: {
          type: Array,
          default: () => []
        }
      },
      computed: {
          getErrors(){
              return this.validations.filter((item)=> {
                  return item.condition
              })
          }
      }
    }
</script>
