<template>
    <ul class="c-nav-main">
        <li class="c-nav-main__item">
            <router-link :to="{ name: 'dashboard' }" class="c-nav-main__link">{{ $t('nav.stencils') }}</router-link>
        </li>
        <li class="c-nav-main__item">
            <router-link :to="{ name: 'customers.index' }" class="c-nav-main__link">{{ $t('nav.customers') }}</router-link>
        </li>
        <!-- <li class="c-nav-main__item">
            <a href="#" class="c-nav-main__link">{{ $t('nav.team') }}</a>
        </li> -->
    </ul>
</template>