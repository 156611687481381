<template>
    <div class="c-print">
        <div class="c-print__preview-wrapper">
            <div>
                <canvas class="c-print__preview" ref="preview"/>
            </div>
        </div>
        <div class="c-print__settings">
            
            <h4>{{ $t('designer.printing.print-stencil') }}</h4>
            
            <div class="u-mt-6">
                <InputGroup :label="$t('designer.printing.page-size')">
                    <InputSelect>
                        <select v-model="project.print.page" name="" id="">
                            <option v-for="page in project.print.sizes" :key="page" :value="page">{{ page.name }}</option>
                        </select>
                    </InputSelect>
                </InputGroup>

                <InputGroup :label="$t('designer.printing.page-orientation')">
                    <InputSelect>
                        <select v-model="project.print.orientation" name="" id="">
                            <option value="portrait">Portrait</option>
                            <option value="landscape">Landscape</option>
                        </select>
                    </InputSelect>
                </InputGroup>

                <InputGroup :label="$t('designer.printing.mirror')">
                    <InputSelect>
                        <select v-model="project.print.mirrored" name="" id="">
                            <option value="normal">Normal</option>
                            <option value="mirrored">Mirrored</option>
                        </select>
                    </InputSelect>
                </InputGroup>
            </div>

            <div class="u-mt-4">
                <a @click="print" href="#" class="c-btn c-btn--primary">
                    <span><i class="fal fa-print"></i> Print</span>
                </a>
            </div>

        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import { PROJECT_PREPARE_PRINT_CANVAS } from '@store/stores/project/types/action-types'

import InputGroup from '@components/input-group'
import InputSelect from '@components/input-select'

import { PROJECT_CHANGE_PRINT_PAGE, PROJECT_PREPARE_PRINTABLE } from '../../store/stores/project/types/action-types'

// import printJS from 'print-js'
import { jsPDF } from "jspdf";

export default {
    components: {
        InputGroup,
        InputSelect
    },
    computed: {
        ...mapState(['project']),
        isApp(){
            return this.$route.query.isApp;
        }
    },
    watch:{
        'project.print.page'(){
            this.$store.dispatch(PROJECT_CHANGE_PRINT_PAGE)
        },
        'project.print.orientation'(){
            this.$store.dispatch(PROJECT_CHANGE_PRINT_PAGE)
        },
        'project.print.mirrored'(){
            this.$store.dispatch(PROJECT_CHANGE_PRINT_PAGE)
        }
    },
    mounted(){
        this.page = this.project.print.page
        this.$store.dispatch(PROJECT_PREPARE_PRINT_CANVAS, this.$refs.preview)
    },
    methods:{
        print(){

            this.$store.dispatch(PROJECT_PREPARE_PRINTABLE).then(result => {

                var pdf = new jsPDF({
                    format: [this.project.print.page.w, this.project.print.page.h],
                    orientation: this.project.print.orientation
                });

                pdf.addImage(result.toDataURL("image/jpeg"),"jpeg",0,0,pdf.getPageWidth(),pdf.getPageHeight())
                pdf.autoPrint();

                if(this.isApp){
                    if(typeof window.ReactNativeWebView !== 'undefined')
                        window.ReactNativeWebView.postMessage('print:'+pdf.output('datauristring'));

                    if (typeof window.webkit.messageHandlers !== 'undefined') 
                        window.webkit.messageHandlers.handler.postMessage('print:'+pdf.output('datauristring'));

                } else window.open(pdf.output('bloburl'), '_blank');

            })
        }
    }
}
</script>