export const cursorPosition = (e) => {
    let cursorEvent = {};

    if(['mousedown','mousemove'].includes(e.type)){
        cursorEvent.clientX = e.offsetX
        cursorEvent.clientY = e.offsetY
    } else if(['touchstart','touchmove'].includes(e.type)){
        let bcr = e.target.getBoundingClientRect();
        let stylusTouch = Object.values(e.touches).find(touchItem => touchItem.touchType === 'stylus')
        let touch = stylusTouch ? stylusTouch : e.touches[0]

        cursorEvent.clientX = touch.clientX - bcr.x
        cursorEvent.clientY = touch.clientY - bcr.y
    }

    return cursorEvent
}