<template>
    <LayoutBase>
        <div v-if="customer" class="u-container u-max-w-tablet-large u-mt-4 tablet:u-mt-16">
           <div class="u-flex u-justify-between">
               <h1>{{ customer.name }}</h1>
           </div>
        </div>
       
        <div v-if="customer" class="u-container u-max-w-tablet-large u-mt-8 tablet:u-mt-16">
            <div class="u-grid u-grid-cols-2 u-gap-4">
                <div class="u-bg-neutral-20 u-p-4">
                    <div class="u-flex u-justify-between u-items-center">
                        <h6>{{ $t('customers.contact-information') }}</h6>
                        <small><router-link :to="{name: 'customers.edit', params: {customer_id: customer._id}}"><i class="fal fa-edit"></i> {{ $t('customers.edit') }}</router-link></small>
                    </div>
                    <address class="u-text-2xl u-mt-2">
                        <strong class="u-font-medium">
                            {{ customer.name}} <br>
                            <a :href="'tel:' + customer.phone" v-if="customer.phone">{{ customer.phone }} <br></a>
                            <a :href="'mailto:' + customer.email" v-if="customer.email">{{ customer.email }}</a>
                        </strong>
                    </address>
                </div>
                <div class="u-bg-neutral-20 u-p-4">
                    <div class="u-flex u-justify-between u-items-center">
                        <h6>{{ $t('customers.contact-address') }}</h6>
                        <small><router-link :to="{name: 'customers.edit', params: {customer_id: customer._id}}"><i class="fal fa-edit"></i> {{ $t('customers.edit') }}</router-link></small>
                    </div>
                    <address class="u-text-2xl u-mt-2">
                        <strong class="u-font-medium">
                            {{ customer.street}} <br>
                            {{ customer.postcode}} <br>
                            {{ customer.city}} <br>
                            {{ customer.country}}
                         </strong>
                    </address>
                </div>
            </div>

            <section class="u-mt-6">
                <Card>
                    <div class="u-grid tablet:u-grid-cols-2 u-gap-4">
                        <div>
                            <strong class="u-text-primary">{{ $t('cta.stencil.leading') }}</strong>
                            <h1 class="u-mt-0">
                                {{ $t('customers.cta.start',{name: customer.name}) }}
                            </h1>
                        </div>
                        <div class="u-flex">
                            <InputDropUpload @select="handleNewProject" class="u-w-full">
                                <h5 class="u-w-1/2 u-mx-auto dark:u-text-neutral">{{ $t('cta.stencil.upload-text') }}</h5>
                                <span class="c-btn c-btn--primary u-mt-4">
                                    <span class="dark:u-bg-neutral dark:u-text-neutral-50">
                                        <i class="fal fa-upload"></i> {{ $t('cta.stencil.upload-btn') }}
                                    </span>
                                </span>
                            </InputDropUpload>
                        </div>
                    </div>
                </Card>
            </section>

            <section class="o-section" ref="overview">

                <div class="u-flex u-justify-between u-items-center">
                    <h5>{{ $t('dashboard.latest-projects') }}</h5>
                    <form class="u-flex" @submit.prevent="search">
                        <button type="submit" class="c-btn c-btn--text">
                            <i class="fal fa-search"></i>
                        </button>
                        <input type="search" @blur="search" :placeholder="$t('dashboard.search')" class="c-input-blank" v-model="searchString">
                    </form>
                </div>
                <div class="u-grid u-gap-2 u-grid-cols-3 u-gap-y-4 u-mt-4">
                    <Project :user="user" :project="project" v-for="project in projects.items" :key="project._id" />
                    
                    <template v-if="projects.loading">
                        <ProjectLoading v-for="i in Array(9)" :key="i"  />
                    </template>
                </div>

                <div class="u-py-4">
                    <Pagination 
                        :currentPage="projects.page"
                        :max="(projects.total/projects.size).toFixed(0)"
                        @setPage="setPage" />
                </div>
            </section>
        </div>
    </LayoutBase>
    <teleport to="body">
        <Loading v-if="loading" />
    </teleport>
</template>
<script>
import { mapState } from 'vuex'
import { watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Store } from '@store/index';

import LayoutBase from '@layouts/base'

import Card from '@components/card'
import Loading from '@components/loading'
import Project from '@components/project'
import Pagination from '@components/pagination'
import InputDropUpload from '@components/input-drop-upload'

import { CUSTOMERS_GET_CUSTOMER } from '@store/stores/customers/types/action-types'
import { PROJECT_UPLOAD_AND_CREATE } from '@store/stores/project/types/action-types'
import { PROJECTS_GET_CUSTOMER_PROJECTS } from '../../store/stores/projects/types/action-types'

export default {
    setup(props) {
        const route = useRoute()
        const overview = ref(null)

        Store.dispatch(PROJECTS_GET_CUSTOMER_PROJECTS, {
            page: 'p' in route.query ? route.query.p : null,
            size: 'size' in route.query  ? route.query.size : null,
            search: 'search' in route.query  ? route.query.search : null,
            user_id: props.customer_id
        })

        watch(
            () => route.query.p,
            async () => {
                Store.dispatch(PROJECTS_GET_CUSTOMER_PROJECTS, {
                    page: 'p' in route.query ? route.query.p : null,
                    size: 'size' in route.query  ? route.query.size : null,
                    search: 'search' in route.query  ? route.query.search : null,
                    user_id: props.customer_id
                })

                overview.value.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        )

        return {
            overview
        }
    },
    components: {
        Card,
        Project,
        Loading,
        LayoutBase,
        Pagination,
        InputDropUpload
    },
    props: ['customer_id'],
    data(){
        return {
            customer: null,
            loading: false
        }
    },
    mounted(){
        this.$store.dispatch(CUSTOMERS_GET_CUSTOMER, this.customer_id).then((customer) => {
            this.customer = customer
        });
    },
    computed: {
        ...mapState(['projects'])
    },
    methods: {
        handleNewProject(file){
            this.loading =  true
            this.$store.dispatch(PROJECT_UPLOAD_AND_CREATE, file).then((resp) => {
                this.$router.push({name: 'designer', params: {project_id: resp._id}})
                this.loading =  false
            })
        },
        search(){
            this.$store.dispatch(PROJECTS_GET_CUSTOMER_PROJECTS, {
                page: 1,
                size: 9,
                search: this.searchString,
                user_id: this.customer_id
            })
        },
        setPage(p){

            this.$router.push({
                name: 'customers.show',
                params: { customer_id: this.customer_id },
                query: {
                    p: p,
                    search: this.searchString
                }
            })
        }
    }
}
</script>