import { CUSTOMERS_DELETE_CUSTOMER, CUSTOMERS_GET_CUSTOMER, CUSTOMERS_GET_CUSTOMERS, CUSTOMERS_SAVE_CUSTOMER } from "./types/action-types"
import { CUSTOMERS_RESET_CUSTOMERS, CUSTOMERS_SET_LOADING, CUSTOMERS_SET_CUSTOMERS } from "./types/mutation-types"

export default {
    [CUSTOMERS_GET_CUSTOMERS]: ({ commit, state }, params) => {
        let page = params.page ? params.page : state.page
        let size = params.size ? params.size : state.size
        let search = params.search ? params.search : state.search

        commit(CUSTOMERS_SET_LOADING, true)
        commit(CUSTOMERS_RESET_CUSTOMERS)

        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/customer/get?p=' + page + '&size=' + size + '&search=' + search, method: 'GET' })
                .then(resp => {     
                    commit(CUSTOMERS_SET_CUSTOMERS, resp.data.data)
                    commit(CUSTOMERS_SET_LOADING, false)

                    resolve(resp)
                })
                .catch(err => {

                    reject(err)
                })
        }) 
    },
    [CUSTOMERS_GET_CUSTOMER]: ({ state }, id) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/customer/' + id, method: 'GET' })
                .then(resp => {     
                    console.log(state)
                    resolve(resp.data.data)
                })
                .catch(err => {

                    reject(err)
                })
        }) 
    },
    [CUSTOMERS_SAVE_CUSTOMER]: ({ state }, items) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/customer/store', data: items, method: 'POST' })
                .then(resp => {    
                    console.log(state)
                    resolve(resp.data.data)
                })
                .catch(err => {

                    reject(err)
                })
        }) 
    },
    [CUSTOMERS_DELETE_CUSTOMER]: ({ state }, id) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/customer/delete', data: {_id: id}, method: 'POST' })
                .then(resp => {     
                    
                    state.items = state.items.filter(item => item._id != id)

                    resolve(resp.data.data)
                })
                .catch(err => {

                    reject(err)
                })
        }) 
    },
}