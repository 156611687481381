<template>
    <div :class="blockClass">
        <i :class="elementClass('select-pointer') + ' fal fa-angle-down'"></i>
        <div :class="elementClass('input')">
            <slot></slot>
        </div>
    </div>
</template>
<script>
  import BEM from '@helpers/bem';

  export default {
    blockName: 'c-input-select',
    mixins: [BEM],
  }
</script>
