import { USER_GET_USER } from "@store/stores/user/types/action-types"

export default function auth({to, next, store, router }) {

    const token = localStorage.getItem('user-token')

    if (token) {
        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    }

    store.dispatch(USER_GET_USER).then(() => {

        if (!store.getters.isAuthenticated) {

            if(typeof to.query.token !== 'undefined') {
                window.ReactNativeWebView.postMessage('loggedout');
                if (typeof window.webkit.messageHandlers !== 'undefined') 
                    window.webkit.messageHandlers.handler.postMessage('loggedout');
                return
            } 

            return router.push({
                name: 'login'
            })
        } else return next()
    }).catch(() => {

        if(typeof to.query.token !== 'undefined') {
            window.ReactNativeWebView.postMessage('loggedout');
            if (typeof window.webkit.messageHandlers !== 'undefined') 
                window.webkit.messageHandlers.handler.postMessage('loggedout');
            return
        } 

        return router.push({
            name: 'login'
        })
    })
}
