export const statePrint = {
    canvas: null,
    ctx: null,
    startDragPos: null,
    position: {
        x: .3,
        y: .4
    },
    size: {
        w: .8,
        h: .8
    },
    orientation: 'portrait',
    mirrored: 'normal',
    dragging: false,
    anchorFix: false,
    anchor: null,
    lockCanvasDrag: false,
    dragStartPosition: {
        x: 0,
        y: 0
    },
    page: null,
    resolution: 300,
    sizes: [
        {
            name: 'A4',
            w: 210,
            h: 297,
        },
        {
            name: 'A3',
            w: 297,
            h: 420,
        },
        {
            name: 'Letter',
            w: 215.9,
            h: 279.4
        }
    ]
}