

export default function appAuth({to, next}) {

    if(typeof to.query.token !== 'undefined') {
        localStorage.setItem('user-token', to.query.token);
    }

    return next()
}
