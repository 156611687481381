<template>
    <tr class="c-table__row">
        <td class="c-table__cell"><strong>{{ date }}</strong></td>
        <td class="c-table__cell">
            {{ invoice.amount.value + " " + invoice.amount.currency }}
            <span class="c-pill c-pill--sm" :class="'is-' + invoice.status ">{{ invoice.status }}</span>
        </td>
        <td class="c-table__cell u-text-right">
            <a @click="download" class="c-btn c-btn--icon c-btn--default">
                <i class="fal fa-download"></i>
            </a>
        </td> 
    </tr>  
</template>
<script>
import { format } from 'date-fns'
import { USER_DOWNLOAD_INVOICE } from '../store/stores/user/types/action-types'

export default {
    props: {
        invoice: {
            type: Object,
            required: true
        }
    },
    computed: {
        date(){
            return format(new Date(this.invoice.paidAt), 'dd-MM-yyyy')
        }
    },
    methods: {
        download(){
            this.$store.dispatch(USER_DOWNLOAD_INVOICE, this.invoice.id);
        }
    }
}
</script>