<template>
<div class="u-container u-max-w-tablet-small u-mt-16 u-mb-16">
        <div class="u-text-center">
            <picture class="u-inline-block u-w-56">
                <source srcset="@/assets/images/logo-printcil-diapos.svg" media="(prefers-color-scheme: dark)">
                <img src="@/assets/images/logo-printcil.svg" class="u-w-full" alt="Logo Printcil">
            </picture>
        </div>

        <Card class="u-mt-8">
            <h1 class="u-mt-0">
                {{ $t('auth.verify.title') }}
            </h1>

            <form @submit.prevent="verify" class="u-mt-4">
                
                <div class="c-alert c-alert--danger" :class="[auth.status == 'success' ? 'c-alert--success' : 'c-alert--danger']"  v-if="auth.message">
                    {{ auth.message }}
                </div>

                <div v-else-if="auth.status != 'success'">
                    <InputBase 
                        type="email" 
                        v-model="email" 
                        :placeholder="$t('auth.verify.email')"
                    
                        autocomplete="email" 
                        :validations="[
                            {
                                condition: !!auth.errors.email,
                                text: auth.errors.email ? auth.errors.email[0] : null
                            }
                        ]"/>

                    <InputBase 
                        type="text" 
                        v-model="confirmation_token" 
                        class="u-mt-4"
                        :placeholder="$t('auth.verify.token')"
                        autocomplete="confirmation_token" 
                        :validations="[
                            {
                                condition: !!auth.errors.confirmation_token,
                                text: auth.errors.confirmation_token ? auth.errors.confirmation_token[0] : null
                            }
                        ]"/>

                    <div class="u-flex u-justify-between u-mt-4">
                        <button type="submit" class="c-btn c-btn--primary">
                            <span><i class="fal fa-check"></i> {{ $t('auth.verify.verify') }}</span>
                        </button>
                    </div>
                </div>
            </form>

        </Card>
    </div>

</template>
<script>
import { mapState } from 'vuex' 
import { AUTH_VERIFY } from '@store/stores/auth/types/action-types';

import Card from '@components/card'
import InputBase from '@components/input-base'
import { AUTH_SET_VERIFY_SUCCESS } from '../../store/stores/auth/types/mutation-types';

export default {
    data() {
        return {
            email: null,
            confirmation_token: this.$route.query.token
        }
    },
    components: {
        Card,
        InputBase
    },
    computed: {
        ...mapState(['auth'])
    },
    methods: {
        verify(){
            this.$store.dispatch(AUTH_VERIFY, { email: this.email, confirmation_token: this.confirmation_token }).then((resp) => {
                // this.$router.push({name: 'login'})
                this.$store.commit(AUTH_SET_VERIFY_SUCCESS, resp.data)
            });
        }
    }
}
</script>