<template>
    <div>
        <label class="c-input-addon" :class="{'c-input-addon--action': modifiers.includes('action')}">
            <div class="c-input-addon__addon" v-if="$slots.addonBefore">
                <slot name="addonBefore"></slot>
            </div>
            <div class="c-input-addon__input">
                <slot></slot>
            </div>
            <div class="c-input-addon__addon" v-if="$slots.addonAfter">
                <slot name="addonAfter"></slot>
            </div>
        </label>
        <Validations v-if="validations" :validations="validations" />
    </div>
</template>
<script>
  import Validations from '@components/input-validation';

  export default {
    blockName: 'c-input-addon',
    components: {
      Validations
    },
    props: {
      validations: {
        type: Array,
        default: () => []
      },
      modifiers: {
        type: Array,
        default: () => []
      },
    }
  }
</script>
