export default {
    cta: require('./cta.json'),
    nav: require('./nav.json'),
    auth: require('./auth.json'),
    main: require('./main.json'),
    account: require('./account.json'),
    project: require('./project.json'),
    designer: require('./designer.json'),
    dashboard: require('./dashboard.json'),
    customers: require('./customers.json'),
    subscription: require('./subscription.json'),
}