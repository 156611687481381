// import { PROJECT_DRAW_CANVAS, PROJECT_HISTORY_REDO, PROJECT_HISTORY_SET, PROJECT_HISTORY_UNDO, PROJECT_PREPROCESS } from "../types/action-types";
import { PROJECT_DRAW_CANVAS, PROJECT_HISTORY_REDO, PROJECT_HISTORY_SET, PROJECT_HISTORY_UNDO, PROJECT_UNDO, PROJECT_REDO } from "../types/action-types";
import { PROJECT_SET_HISTORY, RESET_OFFSCREENCANVAS } from "../types/mutation-types";

export default {
    [PROJECT_HISTORY_UNDO]: ({state, dispatch, commit}) => {
        let latestActiveIndex = state.history.map(item => item.active).lastIndexOf(true)
        
        if(typeof state.history[latestActiveIndex] !== 'undefined'){
            
            state.history[latestActiveIndex].active = false
            commit(RESET_OFFSCREENCANVAS)
            dispatch(PROJECT_HISTORY_SET, [latestActiveIndex - 1, 'via_undo']);
        }

        if(latestActiveIndex >= 0){
            Array.range(latestActiveIndex, state.history.length).forEach(index => {
                state.history[index].active = false
            });
        }

    },
    [PROJECT_HISTORY_REDO]: ({state, dispatch}) => {

        let latestActiveIndex = state.history.map(item => item.active).indexOf(false)

        Array.range(0, latestActiveIndex + 1).forEach(index => {
            state.history[index].active = true
        });
        
        if(latestActiveIndex){
            dispatch(PROJECT_HISTORY_SET, [latestActiveIndex, 'via_redo']);
        }
    },
    [PROJECT_HISTORY_SET]: ({state, commit, dispatch}, [index, via]) => {

        let historyState = state.history[index];

        if(typeof historyState !== 'undefined'){
            commit(PROJECT_SET_HISTORY, historyState);

            let preceedingHistoryState = null;

            if(via == 'via_undo')
                preceedingHistoryState = state.history[index + 1] || null;

            if(via == 'via_redo')
                preceedingHistoryState = state.history[index - 1] || null;

            if(preceedingHistoryState && preceedingHistoryState.rerender){

                if(via == 'via_undo')
                    dispatch(PROJECT_UNDO)

                if(via == 'via_redo')
                    dispatch(PROJECT_REDO)

            } else dispatch(PROJECT_DRAW_CANVAS)
            
        }

    }
}