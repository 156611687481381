<template>
    <Panel :modifiers="['no-height']">
        <template #header>
            <h3>{{ $t('designer.size.title') }}</h3>
        </template>
        
        <div class="u-mt-6">
            <div class="u-flex">
                <div class="u-grow">
                    <div class="u-grid u-gap-2">
                        <InputGroup :label="'Width'" :grid="[12, 5]">
                            <InputAddon>
                                <InputBase @change="setWidth" type="number" max="3000" v-model="this.project.project.imageData.preprocessed.width" />
                                <template #addonAfter>
                                    <strong class="u-text-neutral-20">px</strong>
                                </template>
                            </InputAddon>
                        </InputGroup>
                        <InputGroup :label="'Height'" :grid="[12, 5]">
                            <InputAddon>
                                <InputBase @change="setHeight" type="number" max="3000" v-model="this.project.project.imageData.preprocessed.height" />
                                <template #addonAfter>
                                    <strong class="u-text-neutral-20">px</strong>
                                </template>
                            </InputAddon>
                        </InputGroup>
                    </div>
                </div>
                <div class="u-ml-4 u-grid u-grid-rows-3 u-items-center">
                    <div class="u-w-4 u-h-5 u-mr-2 u-border-t u-border-r u-border-neutral-20"></div>
                    <a class="c-btn c-btn--icon" @click="keepAspectRatio = !keepAspectRatio">
                        <i class="fal fa-lock" :class="keepAspectRatio ? 'fa-lock' : 'fa-lock-open'"></i>
                    </a>
                    <div class="u-w-4 u-h-5 u-mr-2 u-border-b u-border-r u-border-neutral-20"></div>
                </div>
            </div>
            
        </div>

        <template #footer>
            <button @click="resizeImage" class="c-btn c-btn--default">
                <i class="fal fa-expand-wide"></i> {{ $t('designer.size.resize') }}
            </button>
        </template>
    </Panel>
</template>
<script>
import { mapState } from 'vuex'

import Panel from '@components/panel'
import InputGroup from '@components/input-group'
import InputBase from '@components/input-base'
import InputAddon from '@components/input-addon'
import { PROJECT_RESIZE_IMAGE } from '../../store/stores/project/types/action-types'

export default {
    components: {
        Panel,
        InputBase,
        InputAddon,
        InputGroup
    },
    emits: ['close'],
    data(){
        return {
            w: null,
            h: null,
            aspectRatio: null,
            keepAspectRatio: true,
            orientation: null
        }
    },
    mounted(){
         this.orientation = this.project.project.imageData.preprocessed.orientation
         this.w = this.project.project.imageData.preprocessed.width
         this.h = this.project.project.imageData.preprocessed.height
         this.orientation = this.project.project.imageData.preprocessed.orientation

    

    },
    methods:{
        setWidth(){
            if(this.keepAspectRatio){
                this.aspectRatio =  this.h / this.w
                this.project.project.imageData.preprocessed.height = (this.project.project.imageData.preprocessed.width * this.aspectRatio).toFixed(0)
            }
        },
        setHeight(){
            if(this.keepAspectRatio){
                this.aspectRatio = this.w / this.h;
                this.project.project.imageData.preprocessed.width = (this.project.project.imageData.preprocessed.height * this.aspectRatio).toFixed(0)
            }
        },
        resizeImage(){
            this.$store.dispatch(PROJECT_RESIZE_IMAGE);
            this.$emit('close');
        }
    },
    computed: {
        ...mapState(['project'])
    }
}
</script>