export const stateCanvas = {
    canvas: null,
    canvasImg: document.createElement('canvas'),
    ctx: null,
    ctxImg: null,
    offscreen: null,
    offscreenContext: null,
    img: new Image(),
    background: new Image(),
    positions: {
        x: null,
        y: null
    }
}