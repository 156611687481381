<template>
    <div class="c-input-range">
        
        <input 
            type="range" 
            class="c-input-range__slider"
            v-bind="$attrs"
            @input="$emit('update:modelValue', parseFloat($event.target.value))"
        >
        <span class="c-input-range__val">
          {{ modelValue }}
        </span>
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      modelValue: {
        type: [Number,null],
        required: true
      },

    },
    emits: ['update:modelValue'],
}
</script>