<template>
    <div>
        <LayoutBase>
            <div class="u-container u-max-w-tablet-large u-mt-8 tablet:u-mt-16">
                
                <h2>{{ $t('subscription.title') }}</h2>
                
                <div class="u-grid u-grid-cols-2 u-gap-4 u-mt-8">
                    <Card >
                        <template #header>
                            <h4>{{ $t('subscription.you-are-on-plan', {plan: $t('subscription.plans.' + user.subscription_data.plan_code)}) }}</h4>
                            <span v-if="user.subscription_data.status == 'canceled'"><strong>({{ $t('subscription.status.canceled') }})</strong></span>
                            <span v-else-if="user.subscription_data.nextPlan != null"><strong>({{ $t('subscription.status.autorenew') }})</strong></span>
                        </template>
                        
                        <div v-if="user.subscription_data.plan_code != 'free' && user.subscription_data.plan_code" class="u-mt-2">
                            <span v-if="user.subscription_data.startDate_formatted"><strong>{{ $t('subscription.start-date') }}:</strong> {{ user.subscription_data.startDate_formatted }}</span>
                            <span v-else-if="user.subscription_data.activeTill_formatted"><strong>{{ $t('subscription.active-till') }}:</strong> {{ user.subscription_data.activeTill_formatted }}</span>
                        </div>

                        <div class="u-mt-4">
                            <a @click="plansPanel = true" class="c-btn c-btn--primary">
                                <span>{{ $t('subscription.switch-plan') }}</span>
                            </a>
                        </div>
                    </Card>
                    <Card>
                        <template #header>
                            <h4>{{ $t('subscription.billing-data') }}</h4>
                        </template>

                        <address v-if="user.profile.invoice_name">
                            <strong>{{ user.profile.invoice_name }}</strong> <br>
                            {{ user.profile.street }} {{ user.profile.house_number }} <br>
                            {{ user.profile.postcode }} {{ user.profile.city }} 
                            
                        </address>

                        <span v-else class="c-alert c-alert--warning">
                            {{ $t('subscription.no-billing-data') }}
                        </span>

                        <template #footer>
                            <a @click="billingPanel = true" class="c-btn c-btn--default c-btn--sm">
                                {{ $t('subscription.set-billing-information') }}
                            </a>
                        </template>
                    </Card>
                    
                </div>
                <section class="u-mt-6">
                
                    <Card>
                        <template #header>
                            <h4>{{ $t('subscription.coupons') }}</h4>
                        </template>

                        <form @submit.prevent="applyCoupon">

                            <div class="u-grid u-grid-cols-3 u-gap-1 u-my-4">
                                <div class="u-col-span-2">
                                    <InputGroup>
                                        <InputBase 
                                            type="text" 
                                            v-model="couponCode" 
                                            :placeholder="$t('subscription.please-enter-code')"
                                            />
                                    </InputGroup>
                                </div>
                                <div class="u-col-span-1">
                                    <button class="c-btn c-btn--primary u-ml-2">
                                        <span><i class="fal fa-check"></i> {{ $t('subscription.coupon-apply') }}</span>
                                    </button>
                                </div>
                            </div>
                            
                            <p class="u-text-primary" v-if="user.profile.coupon_data?.active_coupons.length > 0">{{ $t('subscription.active-coupons') }}</p>
                            <div v-for="coupon in user.profile.coupon_data?.active_coupons" :key="coupon.code" class="u-mt-2">
                                <p>{{coupon.title}}</p>
                            </div>
                            <span class="c-alert c-alert--warning" v-if="user.profile.coupon_data?.active_coupons.length == 0">
                                {{ $t('subscription.no-coupons') }}
                            </span>
                        </form>
                    </Card>
                </section>
                <section class="u-mt-6">
                
                    <Card>
                        <template #header>
                            <h4>{{ $t('subscription.payments') }}</h4>
                        </template>

                        <span class="c-alert c-alert--warning" v-if="user.profile.payments_data.length == 0">
                            {{ $t('subscription.no-payments') }}
                        </span>

                        <div v-for="(invoices, year) in user.profile.payments_data" :key="year" >
                            <h4>{{ year }}</h4>
                            <table class="c-table">
                                <Invoice v-for="invoice in invoices.slice(0, 6)" :key="invoice._id" :invoice="invoice"/>   
                            </table>
                        </div>
                    </Card>
                </section>
            </div>
        </LayoutBase>
        <teleport to="body">
            <component :is="wrapper" v-model="plansPanel" :modifiers="['transparent-bg']">
                <Plans @close="plansPanel = false" />
            </component>
            <Overlay v-model="plansPanel" @close="plansPanel = false"/>

            <component :is="wrapper" v-model="billingPanel" :title="$t('subscription.invoice-data.set-data')">
                <Billing @close="billingPanel = false" />
            </component>
            <Overlay v-model="billingPanel" @close="billingPanel = false"/>
        </teleport>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import Plans from '@parts/panels/plans'
import Billing from '@parts/panels/billing'

import Card from '@components/card'
import Modal from '@components/modal'
import Overlay from '@components/overlay';
import BarBottom from '@components/bar-bottom';
import Invoice from '@components/invoice';
import InputBase from '@components/input-base'
import InputGroup from '@components/input-group'

import LayoutBase from '@layouts/base'
import { USER_APPLY_COUPON } from '@store/stores/user/types/action-types'
import { USER_GET_USER } from "@store/stores/user/types/action-types"

export default {
    components: {
        Card,
        Modal,
        Plans,
        Billing,
        Invoice,
        Overlay,
        BarBottom,
        LayoutBase,
        InputBase,
        InputGroup
    },
    data(){
        return {
            plansPanel: false,
            billingPanel: false,
            couponCode: null,
        }
    },
    computed: {
        ...mapState(['ui', 'user']),
         wrapper(){
            return this.ui.isMobile ? BarBottom : Modal
        },
    },
    methods: {
        applyCoupon(){

            if(!this.couponCode) {
                this.errors = this.$t('subscription.coupon-invalid')
                this.$store.commit('ui/setFlashErrorMessage', this.errors)
                return;
            }

            this.$store.dispatch(USER_APPLY_COUPON, this.couponCode).then(() => {
                // this.$router.push({ name: 'customers.show', params: { customer_id: this.customer._id }})
                this.couponCode = null
                this.$store.commit('ui/setFlashSuccessMessage', this.$t('subscription.coupon-applied'))
                this.$store.dispatch(USER_GET_USER)
            }).catch((resp) => {
                this.errors = resp.response.data.errors
                this.$store.commit('ui/setFlashErrorMessage', this.errors)
            });
        }
    },
}
</script>