<template>
    <LayoutBase>
        <div class="u-container u-max-w-tablet-large u-mt-4 tablet:u-mt-16">
           <div class="u-flex u-justify-between">
               <h1 class="u-w-1/2">{{ $t('customers.title') }}</h1>

                <div v-if="user.subscription_data.limits.can_create_customers">
                    <router-link :to="{ name: 'customers.create'}" class="c-btn c-btn--primary">
                        <span><i class="fal fa-plus"></i> {{ $t('customers.add-customer') }}</span>
                    </router-link>
                </div>
                <div v-else>
                    <h5 class="u-w-1/3 u-mx-auto u-text-primary">{{ $t('cta.stencil.customer-limits-exceeded') }}</h5>
                </div>

           </div>
        </div>
       
        <div class="u-container u-max-w-tablet-large u-mt-8 tablet:u-mt-16">
             <div class="u-mt-4">
                <Customer :user="user" :customer="customer" v-for="customer in customers.items" :key="customer._id" />
                
                <template v-if="customers.loading">
                    <CustomerLoading v-for="i in Array(9)" :key="i"  />
                </template>
            </div>

            <div class="u-py-4" v-if="customers.pages > 1">
                <Pagination 
                    :currentPage="customers.page"
                    :max="customers.pages"
                    @setPage="setPage" />
            </div>
        </div>
    </LayoutBase>
</template>
<script>
import { mapState } from "vuex";
import { watch, ref } from "vue";
import { useRoute } from "vue-router";
import { Store } from "@store/index";

import LayoutBase from "@layouts/base";
import { CUSTOMERS_GET_CUSTOMERS } from "@store/stores/customers/types/action-types";

import Customer from "@components/customer";
import CustomerLoading from "@components/customer-loading";
import Pagination from "@components/pagination";

export default {
  setup() {
    const route = useRoute();
    const overview = ref(null);

    Store.dispatch(CUSTOMERS_GET_CUSTOMERS, {
      page: "p" in route.query ? route.query.p : null,
      size: "size" in route.query ? route.query.size : null,
      search: "search" in route.query ? route.query.size : null,
    });

    watch(
      () => route.query.p,
      async () => {
        Store.dispatch(CUSTOMERS_GET_CUSTOMERS, {
          page: "p" in route.query ? route.query.p : null,
          size: "size" in route.query ? route.query.size : null,
          search: "search" in route.query ? route.query.size : null,
        });

        overview.value.scrollIntoView({
          behavior: "smooth",
        });
      }
    );

    return {
      overview,
    };
  },
  components: {
    Customer,
    Pagination,
    LayoutBase,
    CustomerLoading,
  },
  data() {
    return {
      loading: false,
      searchString: "",
    };
  },
  computed: {
    ...mapState(["user", "customers"]),
  },
  methods: {
    search() {
      this.$store.dispatch(CUSTOMERS_GET_CUSTOMERS, {
        page: 1,
        size: 9,
        search: this.searchString,
      });
    },
    setPage(p) {
      this.$router.push({
        to: "customers",
        query: {
          p: p,
          search: this.searchString,
        },
      });
    },
  },
};
</script>