import { AUTH_REMOVE_TOKEN, AUTH_RESET_MESSAGE, AUTH_SET_LOGIN_ERROR, AUTH_SET_LOGIN_SUCCESS, AUTH_SET_REGISTER_ERROR, AUTH_SET_VERIFY_ERROR, AUTH_SET_VERIFY_SUCCESS, AUTH_SET_RESET_PASSWORD_SUCCESS, AUTH_SET_REQUEST_RESET_PASSWORD_SUCCESS } from "./types/mutation-types"

export default {
    [AUTH_REMOVE_TOKEN]: state => {
        state.token = ''
    },
    [AUTH_RESET_MESSAGE]: state => {
        state.message = null
    },
    [AUTH_SET_LOGIN_SUCCESS]: (state, token) => {
        state.status = 'success'
        state.message = null
        state.token = token
    },
    [AUTH_SET_LOGIN_ERROR]: (state, resp) => {
        state.status = "error"
        state.message = resp.data.message
    },
    [AUTH_SET_REGISTER_ERROR]: (state, errors) => {
        state.errors = errors
        state.status = 'error'
        state.message = null
    },
    [AUTH_SET_VERIFY_SUCCESS]: (state, resp) => {
        state.status = resp.status
        state.message = resp.message
    },
    [AUTH_SET_VERIFY_ERROR]: (state, resp) => {
        state.errors = {}
        state.status = resp.status
        state.message = resp.message
    },
    [AUTH_SET_RESET_PASSWORD_SUCCESS]: (state, resp) => {
        state.status = resp.status
        state.message = resp.message
    },
    [AUTH_SET_REQUEST_RESET_PASSWORD_SUCCESS]: (state, resp) => {
        console.log("AUTH_SET_REQUEST_RESET_PASSWORD_SUCCESS", resp)
        state.status = resp.status
        state.message = resp.message
    },
}