<template>
    <div class="o-layout o-layout--aside" :class="{'is-ios' : ui.isIos}">
        <Overlay />
        <header class="o-layout__heading">
            <div>
                <h5 v-if="title" v-text="title"/>
                <picture v-else class="u-w-full">
                    <source srcset="@/assets/images/logo-printcil-diapos.svg" media="(prefers-color-scheme: dark)">
                    <img src="@/assets/images/logo-printcil.svg" alt="Logo Printcil">
                </picture>
            </div>
            <div>
                <a href="#">
                    <Avatar class="c-avatar--small" :image="'https://i.pravatar.cc/300'" />
                </a>
            </div>
        </header>
        <aside class="o-layout__aside">
            <AsideMain />
        </aside>
        <main class="o-layout__main">
            <slot />
        </main>

        <footer class="o-layout__bottom-menu">
        <BarBottom>
            <NavMain class="c-nav-main--horizontal"/>
        </BarBottom>

        </footer>

    </div>
    <!-- <MenuSwitchWorkspaceBottom/>
    <GenericBarBottom />
    <GenericModal /> -->
    <FlashMessages/>
</template>
<script>
import { mapState } from 'vuex'

import AsideMain from '@parts/layout/aside'

import Avatar from '@components/avatar'
import BarBottom from '@components/bar-bottom';
import FlashMessages from '@components/flash-message';
import NavMain from '@parts/navs/nav-main';
import Overlay from '@components/overlay';

export default {
    components:{
        Avatar,
        NavMain,
        AsideMain,
        BarBottom,
        FlashMessages,
        Overlay,
    },
    computed: {
        ...mapState(['ui','user'])
    }
}
</script>