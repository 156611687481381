<template>
<div class="o-layout">
    <div class="u-grid tablet:u-h-screen tablet:u-grid-cols-2 desktop:u-grid-cols-3">

        <div class="u-px-4 desktop:u-max-w-tablet-small tablet:u-ml-auto u-relative u-z-10">
            <a href="https://printcil.com" class=" u-w-32 tablet:u-w-56 u-mt-16 u-block u-mx-auto tablet:u-mx-0">
                <picture class="u-w-full">
                    <source srcset="@/assets/images/logo-printcil-diapos.svg" media="(prefers-color-scheme: dark)">
                    <img src="@/assets/images/logo-printcil.svg" class="u-w-full" alt="Logo Printcil">
                </picture>
            </a>
            <Card class="u-mt-8 tablet:u-mt-40 tablet:u-mb-80">
                <form @submit.prevent="login">
                    <strong class="u-text-primary">{{ $t('auth.login-sub-title') }}</strong>
                    <h1 class="u-mt-0">
                        {{ $t('auth.login-title') }}
                    </h1>
                    
                    <div class="u-mt-8">
                        <div class="c-alert c-alert--danger" :class="'c-alert--' + auth.status"  v-if="auth.message">
                            {{ auth.message }}
                        </div>

                        <InputBase type="email" v-model="email" :placeholder="$t('auth.username')" autocomplete="email" />
                        <div class="u-mt-4">
                            <InputBase type="password" v-model="password" :placeholder="$t('auth.password')" autocomplete="current-password" />
                        </div>
                    </div>
                    <button type="submit" class="c-btn c-btn--primary u-mt-4">
                        <span>
                            <i class="fal fa-arrow-right"></i>
                            {{ $t('auth.login') }}
                        </span>
                    </button>
                </form>
            </Card>
        </div>

        <div class="u--mt-16 tablet:u-mt-0 u-bg-neutral-60 dark:u-bg-neutral tablet:u--ml-16 u-relative u-px-4 u-py-16 tablet:u-pl-32 desktop:u-pl-80 desktop:u-col-span-2 u-overflow-hidden">
            <img src="@/assets/images/login-bg.svg" class="u-absolute u-top-0 u-right-0 u-w-1/2" alt="Login example">
            <div class="u-pt-16 tablet:u-pt-72 u-relative">
                <h1 class="u-text-neutral u-font-special tablet:u-text-6xl u-font-normal dark:u-text-neutral-50" v-html="$t('auth.leading')"></h1>
                <div class="u-mt-8 u-flex u-gap-4">
                    <router-link :to="{name:'register'}" class="c-btn c-btn--primary c-btn--diapos">
                        <span>
                            <i class="fal fa-arrow-right"></i>
                            {{ $t('auth.get-started') }}
                        </span>
                    </router-link>
                    <a href="https://printcil.com/" class="c-btn c-btn--text c-btn--diapos">
                        {{ $t('auth.learn-more') }}
                    </a>
                    |
                    <router-link :to="{name:'reset-password'}" class="c-btn c-btn--text c-btn--diapos">
                        <span>
                            {{ $t('auth.reset-password') }}
                        </span>
                    </router-link>
                </div>
            </div>
        </div>

    </div>
</div>
</template>
<script>
import { mapState } from 'vuex' 
import { AUTH_LOGIN } from '@store/stores/auth/types/action-types';

import InputBase from '@components/input-base'
import Card from '@components/card'

export default {
    components: {
        Card,
        InputBase
    },
    data() {
        return {
            email: null,
            password: null
        }
    },
    computed: {
        ...mapState(['auth'])
    },
    methods: {
        login(){
            this.$store.dispatch(AUTH_LOGIN, { email: this.email, password: this.password }).then(() => {
                this.$router.push({name: 'dashboard'})
            });
        }
    }
}
</script>