<template>
    <div :class="blockClass">

        <slot name="beforeHeading" v-if="$slots.beforeHeading"></slot>

        <div :class="elementClass('header')" v-if="$slots.header">
            <slot name="header"></slot>
        </div>

        <div :class="elementClass('heading')" v-if="$slots.title">
            <slot name="title"></slot>
        </div>

        <slot name="afterHeading" v-if="$slots.afterHeading"></slot>

        <div :class="elementClass('body', (showBody) ? ['show-body'] : null)" v-if="hasSlot">
            <div :class="elementClass('body-wrapper')">
                <slot></slot>
            </div>
        </div>

        <slot name="afterContent" v-if="$slots.afterContent"></slot>

        <footer :class="elementClass('footer')" v-if="$slots.footer">
            <slot name="footer"></slot>
        </footer>
    </div>
</template>
<script>
  import BEM from '@helpers/bem';

  export default {
    blockName: 'c-panel',
    mixins: [BEM],
    props: {
      showBody: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      hasSlot() {
        return this.$slots.default
      }
    }
  }
</script>
