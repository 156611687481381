<template>
    <label class="c-input-checkbox">
             <input type="checkbox"
                    :checked="checked"
                    :name="name"
                   @input="(event) => $emit('update:checked',event.target.checked)" >

        <div class="c-input-checkbox__label">
            <slot></slot>
        </div>
    </label>
    <Validations v-if="validations.length" :validations="validations" />
</template>
<script>
  import Validations from '@components/input-validation';

  export default {
    model: {
      prop: 'value',
      event: 'change'
    },
    components: {
      Validations
    },
    emits: ['update:checked'],
    props: {
      checked: {
        type: Boolean,
      },
      name: {
        type: String,
        default: ''
      },
      validations: {
        type: Array,
        default: () => []
      }
    }
  }
</script>
