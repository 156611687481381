<template>
    <div class="u-p-4 u-border-b u-border-neutral-10">
        <div class="u-text-center">
            <div class="u-inline-flex">
                <Avatar :modifiers="['large']" class="u-block" :image="user.profile.avatar_url"/>
            </div>
        </div>
        <div class="u-text-center">
            <small>
                <strong>{{ user.profile.name }}</strong><br>
                {{ user.profile.email }}
            </small>
        </div>
    </div>
    <ul class="c-nav c-nav--small u-text-left u-mt-4 tablet:u-mt-0">
        <li class="c-nav__item">
            <router-link :to="{ name: 'account' }" class="c-nav__link"> 
                <i class="fal fa-user-plus"></i> {{ $t('main.settings') }}
            </router-link> 
        </li>
        <li class="c-nav__item">
            <a :href="'mailto:support@printcil.com?subject=Support Ticket [' + user.profile._id + ']'" class="c-nav__link"> 
                <i class="fal fa-ticket"></i> {{ $t('main.support') }}
            </a> 
        </li>
        <li class="c-nav__item">
            <router-link :to="{ name: 'subscription' }" class="c-nav__link"> 
                <i class="fal fa-credit-card"></i> {{ $t('main.subscription') }}
            </router-link> 
        </li>
        <li class="c-nav__item">
            <a @click="logout" class="c-nav__link u-text-danger">
                <i class="fal fa-sign-out"></i> {{ $t('main.logout') }}
            </a>
        </li>

    </ul>
</template>
<script>
import { mapState } from 'vuex';
import { AUTH_LOGOUT } from '@store/stores/auth/types/action-types';
import Avatar from '@components/avatar';

export default {
    props: {

    },
    components: {
        Avatar
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        logout(){
             this.$store.dispatch(AUTH_LOGOUT).then(() => {
                this.$router.push({ name: "login" });
            });
        }
    }
}
</script>
