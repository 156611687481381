<template>
    <div class="c-designer-tool" :class="{'is-active': active}">
        <a @click="$emit('toggle')" class="c-designer-tool__head">
            <h5>{{ $t('designer.tools.crop.title') }}</h5>
        </a>
        <div class="c-designer-tool__body u-px-6">

            <div class="u-pb-6">
                <a @click="cropImage" class="c-btn c-btn--sidebar">
                    <i class="fal fa-crop"></i> {{ $t('designer.tools.crop.crop') }}
                </a>
            </div>
            
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import { PROJECT_CROP_IMAGE } from '@store/stores/project/types/action-types'

export default {
    emits: ['toggle'],
    props: {
        active: {
            type: Boolean,
            default: true
        }
    },
    computed: mapState(['project']),
    methods: {
        cropImage(){
            this.$store.dispatch(PROJECT_CROP_IMAGE)
        }
    }
}
</script>