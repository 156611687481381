<template>
    <div :class="blockClass">
        <component :is="avatarType" :to="to" :class="elementClass('avatar')">
            <Avatar :modifiers="[['fluid'], ...modifierList]" :image="avatar"/>
        </component>
        <span :class="elementClass('label')">
            <slot></slot>
        </span>
    </div>

</template>
<script>
  import BEM from '@helpers/bem';
  import Avatar from '@components/avatar';

  export default {
    blockName: 'c-profile-box',
    mixins: [BEM],
    props: {
      avatar: {
        type: String,
        required: true
      },
      to: {
        type: [String,Object],
        required: false
      }
    },
    computed: {
      avatarType() {
        return this.to ? 'router-link' : 'span'
      }
    },
    components: {
      Avatar
    }
  }
</script>
