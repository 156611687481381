<template>
    <div class="c-designer-tool" :class="{'is-active': active}">
        <a @click="$emit('toggle')" class="c-designer-tool__head">
            <h5>{{ $t('designer.tools.stencil.title') }}</h5>
            <i class="fal fa-spinner-third fa-spin u-text-primary u-ml-2" v-if="project.loading"></i>
        </a>
        <div class="c-designer-tool__body">

            <!-- <ul class="c-designer-tool__tabs">
                <li class="c-designer-tool__tab-title">
                    {{ $t('designer.tools.stencil.selection') }}
                </li>
                <li class="c-designer-tool__tab-item" :class="{ 'is-active' : project.ui.mode == 'default' }">
                    <a @click="unsetSelection"  class="c-designer-tool__tab">
                        <i class="fal fa-vector-square"></i>
                    </a>
                </li>
                <li class="c-designer-tool__tab-item" :class="{ 'is-active' : project.ui.mode == 'select' }">
                    <a @click="setSelection" class="c-designer-tool__tab">
                        <i class="fal fa-object-group"></i>
                    </a>
                </li>
                <li class="c-designer-tool__tab-item" :class="{ 'is-active' : (project.ui.mode == 'freeselect' || project.ui.states.freeselect.coords.length > 10) }">
                    <a @click="setFreeSelection" class="c-designer-tool__tab">
                        <i class="fal fa-lasso"></i>
                    </a>
                </li>
            </ul> -->

            <div class="c-designer-tool__tab-content u-grid u-gap-2" :class="{'u-opacity-50' : project.loading }">
                <div class="c-designer-tool__option">
                    <div class="c-designer-tool__option-label">
                        Color shapes
                    </div>
                    <div class="c-designer-tool__option-value">
                        <InputRange
                            v-bind="project.preprocessUIOptions.posterizeOptions" 
                            :value="project.preprocessOptions.posterize.level"
                            @change="render"
                            v-model="project.preprocessOptions.posterize.level"/>
                    </div>
                </div>
                <div class="c-designer-tool__option">
                    <div class="c-designer-tool__option-label">
                        Details
                    </div>
                    <div class="c-designer-tool__option-value">
                        <InputRange
                            v-bind="project.preprocessUIOptions.selectiveBlurOptionsSigmar" 
                            :value="project.preprocessOptions.blur.selective.sigmar"
                            @change="render"
                            v-model="project.preprocessOptions.blur.selective.sigmar"/>
                    </div>
                </div>
                <div class="c-designer-tool__option">
                    <div class="c-designer-tool__option-label">
                        Smoothness
                    </div>
                    <div class="c-designer-tool__option-value">
                        <InputRange
                            v-bind="project.preprocessUIOptions.noiseOptions" 
                            :value="project.preprocessOptions.noise.level"
                            @change="render"
                            v-model="project.preprocessOptions.noise.level"/>
                    </div>
                </div>
                <div class="c-designer-tool__option">
                    <div class="c-designer-tool__option-label">
                        Line Thickness
                    </div>
                    <div class="c-designer-tool__option-value">
                        <InputRange
                            v-bind="project.preprocessUIOptions.morphologyRadiusOptions" 
                            :value="project.preprocessOptions.morphology.radius"
                            @change="render"
                            v-model="project.preprocessOptions.morphology.radius"/>
                    </div>
                </div>
                <div class="c-designer-tool__option">
                    <div class="c-designer-tool__option-label">
                        
                    </div>
                    <div class="c-designer-tool__option-value">
                        <ul class="c-designer__toolbar-container">
                            <li class="c-designer-tool__tab-item is-active">
                                <a @click="render" class="c-btn c-btn--sidebar apply-stencil apply-button">
                                    <!-- <i class="fal fa-circle-check"></i> -->
                                    <span>{{ $t('designer.tools.stencil.apply') }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import InputRange from '@components/input-range'
// import InputCheckbox from '@components/input-checkbox'
// import { PROJECT_SET_STENCIL_SELECTION } from '@store/stores/project/types/mutation-types'
// import { PROJECT_RESET_SELECTION_LINE, PROJECT_SET_STENCIL_FREE_SELECTION, PROJECT_UNSET_STENCIL_SELECTION } from '../../store/stores/project/types/mutation-types'

import { PROJECT_PREPROCESS } from '../../store/stores/project/types/action-types'

export default {
    emits: ['toggle'],
    components: {
        InputRange,
        // InputCheckbox,
    },
    props: {
        active: {
            type: Boolean,
            default: true
        }
    },
    computed: mapState(['project']),
    methods: {
        // unsetSelection(){
        //     this.$store.commit(PROJECT_UNSET_STENCIL_SELECTION)
        //     this.$store.commit(PROJECT_RESET_SELECTION_LINE)
        // },
        // setSelection(){
        //     this.$store.commit(PROJECT_SET_STENCIL_SELECTION)
        // },
        // setFreeSelection(){
        //     this.$store.commit(PROJECT_SET_STENCIL_FREE_SELECTION)
        // },
        render(){
            this.project.preprocessOptions.photocopy.enabled = false;
            this.$store.dispatch(PROJECT_PREPROCESS)
        },
    }
}
</script>