export default {
    printCorners: (state) => {
        let coords = {
            px: state.print.position.x,
            px2: state.print.position.x + state.print.size.w,
            py: state.print.position.y,
            py2: state.print.position.y + state.print.size.h,
            x: state.print.position.x * state.print.canvas.width,
            x2: (state.print.position.x * state.print.canvas.width) + (state.print.size.w * state.print.canvas.width),
            y: state.print.position.y * state.print.canvas.height,
            y2: (state.print.position.y * state.print.canvas.height) + (state.print.size.h * state.print.canvas.height)
        }

        return {
            ...coords,
            w: coords.x2 - coords.x,
            h: coords.y2 - coords.y,
            pw: coords.px2 - coords.px,
            ph: coords.py2 - coords.py,
            mmw: (coords.px2 - coords.px) * state.print.page.w,
            mmh: (coords.py2 - coords.py) * state.print.page.h,
            dpi: (state.canvas.img.width / (state.print.size.w * state.print.page.w)) * 25.4
        }
    }
}