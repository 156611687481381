import _ from 'lodash';

import printGetters from './getters/print'
import historyGetters from './getters/history'

export default {
    ...printGetters,
    ...historyGetters,
    drawThickness: (state) => {
        return state.ui.states.draw.thickness * state.ui.zoom
    },
    formattedPreprocessOptions: (state, getters) => {
        
        let options =  _.cloneDeep(state.preprocessOptions);

        if(options.canny)
            options.canny.enabled = true;

        if(state.ui.mode == 'crop'){
            options.crop.enabled = true
        }

        if(!options.clip.position)
            options.clip.position = {};

        if(['crop','select'].includes(state.ui.mode)){
            options.clip.position.pos = {
                swidth: getters.selectionCoords.rw,
                sheight: getters.selectionCoords.rh,
                sx: getters.selectionCoords.rx,
                sy: getters.selectionCoords.ry,
                dwidth: getters.selectionCoords.rw,
                dheight: getters.selectionCoords.rh,
                dx: getters.selectionCoords.rx,
                dy: getters.selectionCoords.ry
            }
        } else {
            options.clip.position = {
                ...options.clip.position,
                pos:{
                    swidth: state.project.imageData.preprocessed.width,
                    sheight: state.project.imageData.preprocessed.height,
                    sx: 0,
                    sy: 0,
                    dwidth: state.project.imageData.preprocessed.width,
                    dheight: state.project.imageData.preprocessed.height,
                    dx: 0,
                    dy: 0
                }
            }
        }

        options.clip.freeselectdata.enabled = false;
        options.clip.freeselectdata.points = getters.freeselectCoords;
        if(state.ui.mode == 'freeselect'){
            if(state.ui.states.freeselect.coords.length > 10){
                options.clip.freeselectdata.enabled = true;
                options.clip.freeselectdata.canvasWidth = state.canvas.img.width;
                options.clip.freeselectdata.canvasHeight = state.canvas.img.height;
                options.clip.freeselectdata.points = getters.freeselectCoords;
            }
        }

        return options
    },
    freeselectCoords: (state) => {
        let coords = []
        state.ui.states.freeselect.coords.forEach(item => {
            coords.push({
                x: item.x * state.canvas.img.width,
                y: item.y * state.canvas.img.height
            })
        })

        return coords
    },
    drawCoords: (state) => {
        let coords = []
        state.ui.states.draw.drawCoords.forEach(item => {
            coords.push({
                ...item,
                coords: item.coords.map((coord) => {
                    return {
                        x: coord.x * state.canvas.img.width,
                        y: coord.y * state.canvas.img.height
                    }
                })
            })
        })
        return coords
    },
    selectionCoords: (state) => {
        let coordsPercentages = {
            x: state.ui.states.select.pos.x,
            x2: state.ui.states.select.pos.x2,
            y: state.ui.states.select.pos.y,
            y2: state.ui.states.select.pos.y2,
        }

        let coordsRealPixels = {
            rx: state.ui.states.select.pos.x * state.canvas.img.width,
            rx2: state.ui.states.select.pos.x2 * state.canvas.img.width,
            ry: state.ui.states.select.pos.y * state.canvas.img.height,
            ry2: state.ui.states.select.pos.y2 * state.canvas.img.height,
            rw: (state.ui.states.select.pos.x2 - state.ui.states.select.pos.x) * state.canvas.img.width,
            rh: (state.ui.states.select.pos.y2 - state.ui.states.select.pos.y) * state.canvas.img.height
        }

        let coordsZoomedPixels = {
            xpx: state.ui.states.select.pos.x * (state.canvas.img.width * state.ui.zoom),
            x2px: state.ui.states.select.pos.x2 * (state.canvas.img.width * state.ui.zoom),
            ypx: state.ui.states.select.pos.y * (state.canvas.img.height * state.ui.zoom),
            y2px: state.ui.states.select.pos.y2 * (state.canvas.img.height * state.ui.zoom),
        }

        let coordsAbsolute = {
            ax: state.canvas.positions.x + (state.ui.states.select.pos.x * (state.canvas.img.width * state.ui.zoom)),
            ax2: state.canvas.positions.x + (state.ui.states.select.pos.x2 * (state.canvas.img.width * state.ui.zoom)),
            ay: state.canvas.positions.y + (state.ui.states.select.pos.y * (state.canvas.img.height * state.ui.zoom)),
            ay2: state.canvas.positions.y + (state.ui.states.select.pos.y2 * (state.canvas.img.height * state.ui.zoom)),
        }

        return {
            ...coordsPercentages,
            ...coordsZoomedPixels,
            ...coordsAbsolute,
            ...coordsRealPixels,
            w: state.ui.states.select.pos.x2 - state.ui.states.select.pos.x,
            h: state.ui.states.select.pos.y2 - state.ui.states.select.pos.y,
            wpx: coordsZoomedPixels.x2px - coordsZoomedPixels.xpx,
            hpx: coordsZoomedPixels.y2px - coordsZoomedPixels.ypx
        }
    },
    
}