<template>
    <div :class="blockClass" >
        <div :class="elementClass('wrapper')">
            <div :class="elementClass('header')" v-if="$slots.header">
                <slot name="header"></slot>
            </div>
            <div :class="elementClass('body')">
                <slot></slot>
            </div>
            <div :class="elementClass('footer')" v-if="$slots.footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>
<script>
  import BEM from '@helpers/bem';

  export default {
    blockName: 'c-dropdown',
    mixins: [BEM]
  }
</script>
