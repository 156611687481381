<template>
  <div class="c-pagination">
    <div class="c-pagination__nav">
        <button @click="prev" class="c-pagination__link" :disabled="!canPrev">
            <i class="fal fa-angle-left"></i>
        </button>
    </div>
    <div class="c-pagination__page">
        <form @submit.prevent="setPage()">
            <input ref="input" v-model="page" type="number" min="1" :max="max" step="1" class="c-pagination__page-input">
        </form>
    </div>
    <div class="c-pagination__nav">
        <button @click="next" class="c-pagination__link" :disabled="!canNext">
            <i class="fal fa-angle-right"></i>
        </button>
    </div>
    <div class="c-pagination__data">
        {{ $t('dashboard.of-amount',{n: max}) }}
    </div>
  </div>
</template>

<script>
export default {
    emits: ['prev','next','setPage'],
    props: ['currentPage','max'],
    data(){
        return {
            page: this.currentPage
        }
    },
    computed: {
        canPrev(){
            return parseInt(this.page) > 1
        },
        canNext(){
            return parseInt(this.page) < this.max
        }
    },
    watch: {
        currentPage(){
            this.page = this.currentPage
        }
    },
    methods: {
        prev(){
            if(this.canPrev){
                this.page--
                this.$emit('setPage', parseInt(this.page))
            }
        },
        next(){
            if(this.canNext){
                this.page++
                this.$emit('setPage', parseInt(this.page))
            }
        },
        setPage(){
            this.$emit('setPage',parseInt(this.page))
            this.$refs.input.blur()
        }
    }
}
</script>
