<template>
    <div class="c-designer-tool" :class="{'is-active': active}">
        <a @click="$emit('toggle')" class="c-designer-tool__head">
            <h5>{{ $t('designer.tools.erase.title') }}</h5>
        </a>
        <div class="c-designer-tool__body u-px-6">

            <div class="c-designer-tool__option">
                <div class="c-designer-tool__option-label">
                    {{ $t('designer.tools.erase.thickness') }}
                </div>
                <div class="c-designer-tool__option-value">
                    <InputRange
                        min="1"
                        max="200"
                        steps="1"
                        :value="project.ui.states.draw.thickness"
                        v-model="project.ui.states.draw.thickness"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import InputRange from '@components/input-range'

export default {
    emits: ['toggle'],
    components: {
        InputRange
    },
    props: {
        active: {
            type: Boolean,
            default: true
        }
    },
    computed: mapState(['project'])
}
</script>