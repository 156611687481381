import { Guest, Auth, AppAuth } from './middleware'

import Dashboard from '../pages/dashboard'
import Account from '../pages/account'
import Subscription from '../pages/subscription'
import SubscriptionPayment from '../pages/subscription-payment'
import Designer from '../pages/designer'
import Login from '../pages/auth/login'
import Register from '../pages/auth/register'
import Verification from '../pages/auth/verification'
import ResetPassword from '../pages/auth/reset-password'
import CustomersIndex from '../pages/customers/index'
import CustomersShow from '../pages/customers/show'
import CustomersEdit from '../pages/customers/edit'
import CustomersCreate from '../pages/customers/create'

export const Routes = {
    routes: [
        {
            path: '/',
            redirect: { name: 'dashboard' }
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                middleware: [Guest]
            }
        },
        {
            path: '/verification',
            name: 'verification',
            component: Verification,
            meta: {
                middleware: [Guest]
            }
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: ResetPassword,
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                middleware: [Guest]
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                middleware: [Auth, AppAuth]
            }
        },
        {
            path: '/account',
            name: 'account',
            component: Account,
            meta: {
                middleware: [Auth, AppAuth]
            }
        },
        {
            path: '/subscription',
            name: 'subscription',
            component: Subscription,
            meta: {
                middleware: [Auth, AppAuth]
            }
        },
        {
            path: '/subscription/payment',
            name: 'subscription.payment',
            component: SubscriptionPayment,
            meta: {
                middleware: [Auth, AppAuth]
            }
        },
        {
            path: '/designer/:project_id',
            name: 'designer',
            component: Designer,
            props: true,
            meta: {
                middleware: [Auth, AppAuth]
            }
        },
        {
            path: '/customers',
            name: 'customers.index',
            component: CustomersIndex,
            props: true,
            meta: {
                middleware: [Auth, AppAuth]
            }
        },
        {
            path: '/customers/:customer_id',
            name: 'customers.show',
            component: CustomersShow,
            props: true,
            meta: {
                middleware: [Auth, AppAuth]
            }
        },
        {
            path: '/customers/:customer_id/edit',
            name: 'customers.edit',
            component: CustomersEdit,
            props: true,
            meta: {
                middleware: [Auth, AppAuth]
            }
        },
        {
            path: '/customers/create',
            name: 'customers.create',
            component: CustomersCreate,
            props: true,
            meta: {
                middleware: [Auth, AppAuth]
            }
        }
    ]
}