<template>
    <transition name="modal" duration="300">
        <div class="c-modal c-modal--animation c-modal--open c-modal__backdrop" :class="setModifiers" v-if="active"
             ref="modal" :aria-hidden="active">
            <div class="c-modal__dialog" ref="dialog">
                <div class="c-modal__close c-modal__close--top-right" v-if="!title && !hideClose">
                    <a @click="close" class="c-btn c-btn--icon c-btn--default" :aria-label="$t('main.close')">
                        <i class="fal fa-times"></i>
                    </a>
                </div>
                <div class="c-modal__content">
                    <div class="c-modal__hero" v-if="hasHeroSlot">
                        <slot name="hero"></slot>
                    </div>
                    <div class="c-modal__header" v-if="title">
                        <div>
                            <h2 class="c-modal__title">{{ title }}</h2>
                            <p class="c-modal__subtitle" v-if="subtitle">{{ subtitle }}</p>
                        </div>
                        <div class="c-modal__close" v-if="!hasHeroSlot && !hideClose">
                            <i class="fal fa-times c-modal__close-btn" @click="close"></i>
                        </div>
                    </div>
                    <div class="c-modal__body">
                        <slot></slot>
                    </div>

                    <div class="c-modal__footer" v-if="hasFooterSlot">
                        <slot name="footer" close="close()"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
  // import {disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

  export default {
    props: ['name', 'title', 'subtitle', 'modelValue', 'classes', 'modifiers', 'hideClose'],
    emits: ["update:modelValue"],
    data() {
      return {
        active: this.modelValue,
        token: ''
      }
    },
    computed: {
      hasDefaultSlot() {
        return !!this.$slots.default
      },
      hasHeroSlot() {
        return !!this.$slots['hero']
      },
      hasFooterSlot() {
        return !!this.$slots['footer']
      },
      setModifiers() {
        let classname = this.classes ? this.classes : '';
        if (this.modifiers) {
          this.modifiers.forEach((item) => {
            classname += ' c-modal--' + item;
          });
        }

        return classname;
      }
    },
    mounted(){
        window.addEventListener("keydown", this.setWindowShortcuts);
    },
    unmounted(){
        window.removeEventListener("keydown", this.setWindowShortcuts);
    },
    watch: {
      modelValue(value) {
        this.active = value;
      },
      active(value) {
        this.$nextTick(() => {
          if (value) {
            document.body.classList.add('modal-open');
            // disableBodyScroll(this.$refs.dialog);
          }
        });

        if (!value) {
          document.body.classList.remove('modal-open');
          // enableBodyScroll(this.$refs.dialog);
        }

        this.$emit('update:modelValue', value);
      }
    },
    events: {
      openModal: function () {
        this.active = true;
      }
    },
    methods: {
      close() {
        // enableBodyScroll(this.$el);
        this.active = false;
      },
      open() {
        this.active = true;
      },
      setWindowShortcuts(evt){
        if (evt.code === 'Escape') {
            this.active = false
        }
      }
    },
  }
</script>
