import { createStore } from 'vuex'
import UIStore from './stores/ui'
import AuthStore from './stores/auth'
import UserStore from './stores/user'
import ProjectStore from './stores/project'
import ProjectsStore from './stores/projects'
import CustomersStore from './stores/customers'

const store = {
    modules: {
        ui: UIStore,
        auth: AuthStore,
        user: UserStore,
        project: ProjectStore,
        projects: ProjectsStore,
        customers: CustomersStore
    }
};

export const Store = createStore(store);