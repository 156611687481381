<template>
    <div class="c-designer-tool" :class="{'is-active': active}">
        <a @click="$emit('toggle')" class="c-designer-tool__head">
            <h5>{{ $t('designer.tools.snapshots.title') }}</h5>
        </a>
        <div class="c-designer-tool__body u-px-6">

            <div class="c-snapshot u-mt-2" v-for="snapshot in project.snapshots" :key="snapshot.id">
                <div class="c-snapshot__img-wrapper">
                    <img class="c-snapshot__image" :src="snapshot.src" alt="">
                </div>
                <div class="c-snapshot__data">
                    <h6>{{ $t('designer.tools.snapshots.snapshot') }} {{ snapshot.id }}</h6>

                    <div>
                        <a @click="downloadSnapshot(snapshot.id)" class="c-btn c-btn--icon c-btn--small c-btn--sidebar">
                            <i class="fal fa-download"></i>
                        </a>
                        <a @click="removeSnapshot(snapshot.id)" class="c-btn c-btn--icon c-btn--small c-btn--sidebar">
                            <i class="fal fa-trash-alt"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="u-text-center u-mt-4 u-mb-4">
                 <a @click="makeSnapshot" class="c-btn c-btn--sidebar c-btn--sm">
                    <i class="fal fa-plus"></i> {{ $t('designer.tools.snapshots.make') }}
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { PROJECT_ADD_SNAPSHOT } from '@store/stores/project/types/action-types'
import { PROJECT_DOWNLOAD_SNAPSHOT, PROJECT_REMOVE_SNAPSHOT } from '../../store/stores/project/types/action-types'

export default {
    emits: ['toggle'],
    props: {
        active: {
            type: Boolean,
            default: true
        }
    },
    computed: mapState(['project']),
    methods: {
        makeSnapshot(){
            this.$store.dispatch(PROJECT_ADD_SNAPSHOT)
        },
        removeSnapshot(id){
            this.$store.dispatch(PROJECT_REMOVE_SNAPSHOT, id)
        },
        downloadSnapshot(id){
            this.$store.dispatch(PROJECT_DOWNLOAD_SNAPSHOT, id)

        }
    }
}
</script>