<template>
   <div class="u-grid u-gap-2">
        <a @click="setDragging()" class="c-btn c-btn--icon c-btn--default" :class="{'is-active' : project.ui.state == 'drag'}">
            <i class="fal fa-hand"></i>
        </a>
        <a @click="setFreeSelection()" class="c-btn c-btn--icon c-btn--default" :class="{'is-active' : project.ui.state == 'freeselect'}">
            <i class="fal fa-lasso"></i>
        </a>
        <a @click="setCropSelection()" class="c-btn c-btn--icon c-btn--default" :class="{'is-active' : project.ui.mode == 'crop'}">
            <i class="fal fa-crop"></i>
        </a>
        <a @click="zoomIn()" class="c-btn c-btn--icon c-btn--default" :class="{'is-active' : project.ui.state == 'zoom'}">
            <i class="fal fa-search-plus"></i>
        </a>
        <a @click="zoomOut()" class="c-btn c-btn--icon c-btn--default" :class="{'is-active' : project.ui.state == 'zoom'}">
            <i class="fal fa-search-minus"></i>
        </a>
        <a @click="setDrawing()" class="c-btn c-btn--icon c-btn--default" :class="{'is-active' : project.ui.state == 'draw'}">
            <i class="fal fa-pencil"></i>
        </a>
        <a @click="setErasing()" class="c-btn c-btn--icon c-btn--default" :class="{'is-active' : project.ui.state == 'erase'}">
            <i class="fal fa-eraser"></i>
        </a>
        <a @click="rotate()" class="c-btn c-btn--icon c-btn--default">
            <i class="fal fa-rotate-right"></i>
        </a>
        <a @click="rotate('counterclockwise')" class="c-btn c-btn--icon c-btn--default">
            <i class="fal fa-rotate-left"></i>
        </a>
   </div>
</template>
<script>
import { mapState } from 'vuex'
import { PROJECT_DRAW_CANVAS } from '@store/stores/project/types/action-types'
import { PROJECT_SET_STATE_DRAGGING, PROJECT_SET_STATE_DRAWING, PROJECT_SET_STATE_ERASE, PROJECT_SET_STENCIL_FREE_SELECTION, PROJECT_ZOOM } from '@store/stores/project/types/mutation-types'
import { PROJECT_SET_CROP_SELECTION } from '../../store/stores/project/types/mutation-types'
import { PROJECT_ROTATE } from '../../store/stores/project/types/action-types'

export default {
    computed: {
        ...mapState(['project'])
    },
    methods: {
        zoomIn(){
            let zoom = this.project.ui.zoom + .1
            this.$store.commit(PROJECT_ZOOM, zoom)
        },
        zoomOut(){
            let zoom = this.project.ui.zoom - .1
            this.$store.commit(PROJECT_ZOOM, zoom)
        },
        setDragging(){
            this.$store.commit(PROJECT_SET_STATE_DRAGGING)
        },
        setDrawing(){
            this.project.ui.toolPanelStates.drawing = true
            this.$store.commit(PROJECT_SET_STATE_DRAWING)
        },
        setErasing(){
            this.project.ui.toolPanelStates.erase = true
            this.$store.commit(PROJECT_SET_STATE_ERASE)
        },
        setFreeSelection(){
            this.$store.commit(PROJECT_SET_STENCIL_FREE_SELECTION)
            this.$store.dispatch(PROJECT_DRAW_CANVAS);
        },
        setCropSelection(){
            this.$store.commit(PROJECT_SET_CROP_SELECTION)
            this.$store.dispatch(PROJECT_DRAW_CANVAS);
        },
        rotate(direction = 'clockwise'){
            this.$store.dispatch(PROJECT_ROTATE, direction)
        }
    }
}
</script>