<template>
    <div class="u-border-b u-border-neutral-20 u-p-2 u-flex u-justify-between u-items-center">
        <router-link :to="{ name: 'customers.show', params: { customer_id: customer._id }}">
            <h5>{{ customer.name }}</h5>
        </router-link>
        <div>
            <a @click="removeCustomer" class="c-btn c-btn--icon c-btn--warning">
                <i class="fal fa-trash-alt"></i>
            </a>
            <router-link :to="{ name: 'customers.show', params: { customer_id: customer._id }}" class="c-btn c-btn--icon c-btn--default">
                <i class="fal fa-eye"></i>
            </router-link>
        </div>
    </div>
</template>
<script>
import { CUSTOMERS_DELETE_CUSTOMER } from '../store/stores/customers/types/action-types'
import { USER_GET_USER } from "../store/stores/user/types/action-types";
export default {
    props: {
        customer: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    methods: {
        removeCustomer(){

            if(this.user.subscription_data.limits.can_create_customers && this.user.subscription_data.limits.can_delete_customers){
                this.$swal({
                    title: this.$t('customers.delete.confirm-title'),
                    text: this.$t('customers.delete.confirm-text'),
                    icon: 'error',
                    confirmButtonText: this.$t('customers.delete.confirm-btn')
                }).then(result => {
                    if(result.isConfirmed){
                        this.$store.commit('ui/setFlashSuccessMessage', this.$t('customers.delete.success'))
                        this.$store.dispatch(CUSTOMERS_DELETE_CUSTOMER, this.customer._id)
                        this.$store.dispatch(USER_GET_USER);
                    }
                })
            } else {
                if(!this.user.subscription_data.limits.can_delete_customers)
                    this.$swal({
                        title: this.$t('customers.delete.ability-not-available.confirm-title'),
                        text: this.$t('customers.delete.ability-not-available.confirm-text'),
                        icon: 'error',
                        cancelButtonText: this.$t('customers.delete.cancel-btn')
                    })
                else
                    this.$swal({
                        title: this.$t('customers.delete.limit-reached.confirm-title'),
                        text: this.$t('customers.delete.limit-reached.confirm-text'),
                        icon: 'error',
                        cancelButtonText: this.$t('customers.delete.cancel-btn')
                    })
            }
        }
    }
}
</script>