<template>
    <div class="c-project" :class="{'u-opacity-40 u-pointer-events-none' : deleting}">
        <router-link :to="{ name: 'designer',  params: { project_id: project._id } }" class="c-project__image-box">
            <img v-if="thumb" class="c-project__image" :src="thumb" alt="">
        </router-link>
        <div class="c-project__meta">
            <router-link :to="{ name: 'designer',  params: { project_id: project._id } }">
                <h4 class="c-project__title">{{ project.title }}</h4>
                <small class="u-text-faded">{{ date }}</small>
            </router-link>
            <Popper arrow offsetDistance="0" zIndex="100">
                <a class="c-btn c-btn--icon">
                    <i class="fal fa-ellipsis-vertical"></i>
                </a>
                <template #content>
                    <ul class="c-nav c-nav--small u-text-left u-mt-4 tablet:u-mt-0">
                        <li class="c-nav__item">
                            <a @click="removeProject" class="c-nav__link"> 
                                <i class="fal fa-trash-alt"></i> {{ $t('project.delete.delete') }}
                            </a> 
                        </li>
                    </ul>
                </template>
            </Popper>
            
        </div>
    </div>
</template>
<script>
import { formatDistance } from 'date-fns'
import { PROJECTS_GET_THUMBNAIL } from '../store/stores/projects/types/action-types'
import Popper from "vue3-popper";
import { PROJECT_REMOVE } from '../store/stores/project/types/action-types';
import {USER_GET_USER} from "../store/stores/user/types/action-types";

export default {
    props: {
        project: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    components: {
        Popper
    },
    data(){
        return {
            thumb: null,
            deleting: false
        }
    },
    mounted(){
        this.$store.dispatch(PROJECTS_GET_THUMBNAIL, this.project.thumbnail).then((resp) => {
            this.thumb = 'data:image/png;base64, ' + resp.toString('base64');
        });

    },
    computed: {
        date(){
            return formatDistance(new Date(this.project.created_at.replace(/-/g, "/")), new Date(), { addSuffix: true })
        }
    },
    methods: {
        removeProject(){
            if(this.user.subscription_data.limits.can_create_projects && this.user.subscription_data.limits.can_delete_projects){
                this.$swal({
                    title: this.$t('project.delete.confirm-title'),
                    text: this.$t('project.delete.confirm-text'),
                    icon: 'error',
                    confirmButtonText: this.$t('project.delete.confirm-btn'),
                    cancelButtonText: this.$t('project.delete.cancel-btn')
                }).then(result => {
                    if(result.isConfirmed){
                        this.deleting = true;
                        this.$store.dispatch(PROJECT_REMOVE, this.project._id).then(() => {
                            this.$store.commit('ui/setFlashSuccessMessage', this.$t('project.delete.success'))
                            this.$store.dispatch(USER_GET_USER);
                        })
                    }
                });
            } else {
                if(!this.user.subscription_data.limits.can_delete_projects)
                    this.$swal({
                        title: this.$t('project.delete.ability-not-available.confirm-title'),
                        text: this.$t('project.delete.ability-not-available.confirm-text'),
                        icon: 'error',
                        cancelButtonText: this.$t('project.delete.ability-not-available.cancel-btn')
                    });
                else
                    this.$swal({
                        title: this.$t('project.delete.limit-reached.confirm-title'),
                        text: this.$t('project.delete.limit-reached.confirm-text'),
                        icon: 'error',
                        cancelButtonText: this.$t('project.delete.limit-reached.cancel-btn')
                    });
            }
        }
    }
}
</script>