import { USER_SET_DATA, USER_SET_ERROR, USER_SET_SUBSCRIPTION_STATUS } from "./types/mutation-types"

export default {
    [USER_SET_ERROR]: state => {
        state.status = 'error'
    },
    [USER_SET_DATA]: (state, resp) => {
        state.status = 'success'
        state.profile = resp
        state.permissions = resp.checkPermissions
        state.roles = resp.checkRoles
        state.subscription_data = resp.subscription_data
        state.coupon_data = resp.coupon_data
        state.available_subscriptions = resp.asset_data.available_subscriptions
    },
    [USER_SET_SUBSCRIPTION_STATUS]: (state, status) => {
        state.subscription_data.status = status
    },
}