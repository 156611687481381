<template>
    <div class="u-w-full u-relative">
        <input
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :name="name"
            :type="inputType"
            :disabled="disabled"
            :placeholder="placeholder"
            :class="blockClass + (isError ? ' is-error' : '')"
            @blur="$emit('blur')"
            v-bind="$attrs"
            ref="input"
        >
        <a v-if="type == 'password'" @click="togglePass" class="c-input__passtoggle "><i class="fal fa-eye"></i></a>
        <Validations v-if="validations" :validations="validations" />
    </div>
</template>
<script>
  import BEM from '@helpers/bem';
  import Validations from '@components/input-validation';

  export default {
    mixins: [BEM],
    blockName: 'c-input',
    inheritAttrs: false,
    components: {
      Validations
    },
    props: {
      modelValue: {
        type: [String,null],
        required: true
      },
      type: {
        type: String,
        default: 'text'
      },
      placeholder: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      validations: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
    },
    data(){
      return {
        inputType: this.type
      }
    },
    computed: {
      isError(){
        return this.validations.find((i) => { return i.condition; });
      }
    },
    methods: {
      togglePass(){
        this.inputType = this.inputType == 'password' ? 'text' : 'password';
      }
    }
  }
</script>
