window.axios = require('axios')

import { createApp } from 'vue'
import {Store} from './js/store/index';
import {Router} from './js/routes/router';
import {i18n} from './js/i18n';
import VueClickAway from "vue3-click-away";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import App from './js/App.vue'
import './assets/tailwind.css'
import 'animate.css'
import './scss/app.scss';

Array.range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);

const app = createApp(App)

app.use(Store)
app.use(Router)
app.use(i18n);
app.use(VueClickAway);

app.use(VueSweetalert2,{
    customClass: {
        confirmButton: 'c-btn c-btn--success',
        cancelButton: 'c-btn c-btn--default'
    },
    buttonsStyling: false
});

app.mount('#app') 