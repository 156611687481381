import { createRouter, createWebHistory } from 'vue-router'
import middlewarePipeline from './middlewarePipeline'
import { Routes } from './routes'
import {Store as store} from '../store/index';

let router = createRouter({
    history: createWebHistory(),
    ...Routes,
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
      return next()
    }
    const middleware = to.meta.middleware
  
    const context = {
      to,
      from,
      next,
      store,
      router
    }

    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    })
  })

  export const Router = router