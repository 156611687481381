import { PROJECTS_GET_THUMBNAIL, PROJECTS_GET_CUSTOMER_PROJECTS, PROJECTS_GET_PROJECTS } from "./types/action-types"
import { PROJECTS_RESET_PROJECTS, PROJECTS_SET_LOADING, PROJECTS_SET_PROJECTS } from "./types/mutation-types"

export default {
    [PROJECTS_GET_PROJECTS]: ({ commit, state }, params) => {
        let page = params.page ? params.page : state.page
        let size = params.size ? params.size : state.size
        let search = params.search ? params.search : state.search

        commit(PROJECTS_SET_LOADING, true)
        commit(PROJECTS_RESET_PROJECTS)

        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/workspace/projects?p=' + page + '&size=' + size + '&search=' + search, method: 'GET' })
                .then(resp => {     
                    commit(PROJECTS_SET_PROJECTS, resp.data.data)
                    commit(PROJECTS_SET_LOADING, false)

                    resolve(resp)
                })
                .catch(err => {

                    reject(err)
                })
        })
    },
    [PROJECTS_GET_CUSTOMER_PROJECTS]: ({ commit, state }, params) => {
        let page = params.page ? params.page : state.page
        let size = params.size ? params.size : state.size
        let search = params.search ? params.search : state.search
        let user_id = params.user_id ? params.user_id : state.user_id

        commit(PROJECTS_SET_LOADING, true)
        commit(PROJECTS_RESET_PROJECTS)

        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/projects/' + user_id + '?p=' + page + '&size=' + size + '&search=' + search, method: 'GET' })
                .then(resp => {     
                    commit(PROJECTS_SET_PROJECTS, resp.data.data)
                    commit(PROJECTS_SET_LOADING, false)

                    resolve(resp)
                })
                .catch(err => {

                    reject(err)
                })
        })
    },
    [PROJECTS_GET_THUMBNAIL]: ({state}, url) => {
        return new Promise((resolve, reject) => {
            axios({ 
                url: url, 
                method: 'GET',
                responseType: "arraybuffer" 
            })
            .then(resp => {  
                    console.log(state)
                    resolve(Buffer.from(resp.data, 'base64'))
                })
                .catch(err => {

                    reject(err)
                })
        })
    }
}