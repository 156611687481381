<template>
    <div class="c-designer-tool" :class="{'is-active': active}">
        <a @click="$emit('toggle')" class="c-designer-tool__head">
            <h5>{{ $t('designer.tools.navigator.title') }}</h5>
        </a>
        <div class="c-designer-tool__body u-px-6">

            <div class="c-designer-tool__option">
                <canvas ref="preview" v-show="project.loaded" class="c-designer-tool__preview"></canvas>
            </div>
            <div class="c-designer-tool__option u-text-sm u-mt-2" v-if="typeof project.project.imageData.preprocessed != 'undefined'">
                    <span class="u-text-neutral-20">
                        <!-- <strong>{{ project.project.imageData.preprocessed.width }}</strong>
                        x
                        <strong>{{ project.project.imageData.preprocessed.height }}</strong>
                        px -->
                    </span>
                    <!-- <a @click="sizePanel = true" class="c-btn c-btn--sidebar c-btn--sm u-ml-2">
                        <i class="fal fa-expand-wide"></i> {{ $t('designer.size.resize') }}
                    </a> -->
                </div>
            <div class="c-designer-tool__option u-text-sm u-mt-2">
                

                <div class="u-grid u-grid-flow-col u-gap-2 u-items-center u-mr-2">
                    {{ $t('designer.tools.navigator.size') }}
                    <InputSelect :modifiers="['toolbar']">
                        <select v-model="zoom">
                            <option :value="this.project.ui.zoom">{{ (this.project.ui.zoom * 100).toFixed(0) }}%</option>
                             <optgroup :label="$t('designer.tools.navigator.sizes')">
                            <option v-if="this.project.ui.zoom != 1" value="1">100%</option>
                            <option v-if="this.project.ui.zoom != 2" value="2">200%</option>
                            <option v-if="this.project.ui.zoom != 3" value="3">300%</option>
                            <option v-if="this.project.ui.zoom != 4" value="4">400%</option>
                             </optgroup>
                        </select>
                    </InputSelect>
                </div>
                <div class="u-grid u-grid-flow-col u-gap-2 u-items-center">
                    {{ $t('designer.tools.navigator.visibility') }}
                    <InputSelect :modifiers="['toolbar']">
                        <select @change="render" v-model="project.preprocessOptions.background.opacity">
                            <option value="0">0%</option>
                            <option value="10">10%</option>
                            <option value="20">20%</option>
                            <option value="30">30%</option>
                            <option value="40">40%</option>
                            <option value="50">50%</option>
                            <option value="60">60%</option>
                            <option value="70">70%</option>
                            <option value="80">80%</option>
                            <option value="90">90%</option>
                            <option value="100">100%</option>
                        </select>
                    </InputSelect>
                     <InputSelect :modifiers="['toolbar']">
                        <select @change="render" v-model="project.preprocessOptions.background.greyscale">
                            <option :value="true">b/w</option>
                            <option :value="false">color</option>
                        </select>
                    </InputSelect>
                </div>
            </div>
        </div>
    </div>
    <teleport to="body">
            <component :is="wrapper" v-model="sizePanel" :modifiers="['sm']">
                <PanelSize @close="sizePanel = false" />
            </component>
            <Overlay v-model="sizePanel" @close="sizePanel = false" />
    </teleport>
</template>
<script>
import { mapState } from 'vuex'
import { PROJECT_PREPARE_PREVIEW, PROJECT_SET_BACKGROUND } from '@store/stores/project/types/action-types';
import { PROJECT_ZOOM } from '@store/stores/project/types/mutation-types';

import Modal from '@components/modal'
import Overlay from '@components/overlay';
import BarBottom from '@components/bar-bottom';
import InputSelect from '@components/input-select'

import PanelSize from '@parts/panels/size'

export default {
    emits: ['toggle'],
    components: {
        Overlay,
        PanelSize,
        InputSelect
    },
    data(){
        return {
            sizePanel: false
        }
    },
    props: {
        active: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapState(['project','ui']),
        zoom: { 
            get(){
                return this.project.ui.zoom 
            },
            set(zoom){
                this.$store.commit(PROJECT_ZOOM, parseInt(zoom))
            }
        },
        wrapper(){
            return this.ui.isMobile ? BarBottom : Modal
        },
    },
    mounted(){

        this.$store.dispatch(PROJECT_PREPARE_PREVIEW, this.$refs.preview);
    },
    methods: {
        render(){
            this.$store.dispatch(PROJECT_SET_BACKGROUND)
        }
    }
}
</script>