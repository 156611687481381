import { createI18n } from 'vue-i18n'

const i18nStrings = {
    'en_GB': require('./locales/en_GB').default,
}

export const i18n = createI18n({
    locale: 'en_GB', //navigator.language || navigator.userLanguage, // set locale
    fallbackLocale: 'en_GB',
    messages: i18nStrings
})