<template>
<div class="u-container u-max-w-tablet-small u-mt-16 u-mb-16">
        <div class="u-text-center">
            <picture class="u-inline-block u-w-56">
                <source srcset="@/assets/images/logo-printcil-diapos.svg" media="(prefers-color-scheme: dark)">
                <img src="@/assets/images/logo-printcil.svg" class="u-w-full" alt="Logo Printcil">
            </picture>
        </div>

        <Card class="u-mt-8">
            <h1 class="u-mt-0 u-mb-4">
                {{ $t('auth.reset.title') }}
            </h1>
            <p v-if="!auth.errors"> {{ $t('auth.reset.sub-title') }}</p>
                
                <div class="c-alert c-alert--danger" :class="[auth.status == 'success' ? 'c-alert--success' : 'c-alert--danger']"  v-if="auth.message">
                    {{ auth.message }}
                </div>

                <div v-else-if="auth.status != 'success'">
                    <InputBase 
                        type="email" 
                        v-model="email" 
                        :placeholder="$t('auth.reset.email')"
                    
                        autocomplete="email" 
                        :validations="[
                            {
                                condition: !!auth.errors.email,
                                text: auth.errors.email ? auth.errors.email[0] : null
                            }
                        ]"/>

                    <InputBase 
                        v-if="reset_token"
                        type="password" 
                        v-model="password" 
                        class="u-mt-4"
                        :placeholder="$t('auth.reset.password')"
                        :validations="[
                            {
                                condition: !!auth.errors.password,
                                text: auth.errors.password ? auth.errors.password[0] : null
                            }
                        ]"/>

                    <InputBase 
                        v-if="reset_token"
                        type="password" 
                        v-model="password_confirmation" 
                        class="u-mt-4"
                        :placeholder="$t('auth.reset.password-confirmation')"
                        :validations="[
                            {
                                condition: !!auth.errors.password_confirmation,
                                text: auth.errors.password_confirmation ? auth.errors.password_confirmation[0] : null
                            }
                        ]"/>

                    <InputBase 
                        v-if="reset_token"
                        type="text" 
                        v-model="reset_token" 
                        class="u-mt-4"
                        :placeholder="$t('auth.reset.token')"
                        autocomplete="reset_token" 
                        :validations="[
                            {
                                condition: !!auth.errors.reset_token,
                                text: auth.errors.reset_token ? auth.errors.reset_token[0] : null
                            }
                        ]"/>

                    <div v-if="reset_token" class="u-flex u-justify-between u-mt-4">
                        <button @click="resetPassword" class="c-btn c-btn--primary">
                            <span><i class="fal fa-check"></i> {{ $t('auth.reset.reset') }}</span>
                        </button>
                    </div>

                    <div v-else class="u-flex u-justify-between u-mt-4">                 
                        <button @click="requestReset" class="c-btn c-btn--primary">
                            <span><i class="fal fa-check"></i> {{ $t('auth.reset.requestReset') }}</span>
                        </button>
                    </div>

                    <router-link :to="{name: 'login'}" class="c-btn c-btn--text">{{ $t('auth.reset.or-login') }}</router-link>
                </div>
        </Card>
    </div>

</template>
<script>
import { mapState } from 'vuex' 
import { AUTH_RESET_PASSWORD, AUTH_REQUEST_RESET_PASSWORD } from '@store/stores/auth/types/action-types';

import Card from '@components/card'
import InputBase from '@components/input-base'

export default {
    data() {
        return {
            phaseRequest: true,
            email: this.$route.query.email,
            reset_token: this.$route.query.token,
            password: null,
            password_confirmation: null
        }
    },
    components: {
        Card,
        InputBase
    },
    computed: {
        ...mapState(['auth'])
    },
    methods: {
        resetPassword(){
            this.$store.dispatch(AUTH_RESET_PASSWORD, { email: this.email, password: this.password, password_confirmation: this.password_confirmation, token: this.reset_token }).then(() => {
                setTimeout(() => {
                    this.$router.push({name: 'login'})
                }, 3000);
            });
        },
        requestReset(){
            this.$store.dispatch(AUTH_REQUEST_RESET_PASSWORD, this.email);
        }
    }
}
</script>