import _ from 'lodash';

import { stateProject } from "./states/project";
import { stateCanvas } from "./states/canvas";
import { statePreview } from "./states/preview";
import { statePreprocessOptions } from './states/preprocessOptions';
import { statePrint } from './states/print';
import { stateUI } from "./states/ui";

export default {
    project: _.cloneDeep(stateProject),
    preprocessOptions: _.cloneDeep(statePreprocessOptions),
    abortController: null,
    snapshots: [],
    preprocessUIOptions: {},
    print: _.cloneDeep(statePrint),
    canvas: _.cloneDeep(stateCanvas),
    preview: _.cloneDeep(statePreview),
    ui: _.cloneDeep(stateUI),
    loading: false,
    loaded: false,
    history:[]
}