import _ from 'lodash';
import { stateUI } from "./states/ui"
import { stateCanvas } from "./states/canvas"
import { stateProject } from './states/project';
import { statePreprocessOptions } from './states/preprocessOptions';

import MutationPrint from "./mutations/print"
import MutationHistory from "./mutations/history"

import { RESET_OFFSCREENCANVAS, PROJECT_MOVE_SELECTION, PROJECT_RESIZE_SELECTION_BL, PROJECT_RESIZE_SELECTION_BR, PROJECT_RESIZE_SELECTION_TL, PROJECT_RESIZE_SELECTION_TR, PROJECT_SET_ANCHOR, PROJECT_SET_CANVAS, PROJECT_SET_CLOSE_DRAWED_SELECTION, PROJECT_SET_DRAW_LINE, PROJECT_SET_DRAW_POSITION, PROJECT_SET_DRAW_SELECTION, PROJECT_SET_ERASE_LINE, PROJECT_SET_ERASE_POSITION, PROJECT_SET_PREPROCESS_OPTIONS, PROJECT_SET_PROCESSED_IMAGE, PROJECT_SET_PROJECT, PROJECT_RESET_SELECTION_LINE, PROJECT_SET_STATE_DRAGGING, PROJECT_SET_STATE_DRAWING, PROJECT_SET_STATE_ERASE, PROJECT_SET_STENCIL_FREE_SELECTION, PROJECT_SET_STENCIL_SELECTION, PROJECT_UNSET_STENCIL_SELECTION, PROJECT_ZOOM, PROJECT_SET_PREVIEW, PROJECT_SET_PREVIEW_SIZE, PROJECT_SET_SAVED, PROJECT_SET_UNSAVED, PROJECT_RESET_STATE, PROJECT_SET_SNAPSHOTS, PROJECT_SET_CROP_SELECTION, PROJECT_SET_BACKGROUND, COMMIT_DRAWING } from "./types/mutation-types"

export default {
    ...MutationPrint,
    ...MutationHistory,
    [PROJECT_SET_PROJECT]: (state, project) => {
        state.project._id = project._id
        state.project.title = project.title
        state.project.imageData = project.imageData
        state.project.customer_ids = project.customer_ids
        state.project.customers = project.customers
        state.project.customer_name = project.customer_name
        state.preprocessUIOptions = project.uiOptions
        state.preprocessOptions = project.preprocessOptions
        state.snapshots = project.snapshots
    },
    [PROJECT_SET_SNAPSHOTS]: (state, snapshots) => {
        state.snapshots = snapshots
    }, 
    [PROJECT_RESET_STATE]: (state) => {
        if(state.canvas.ctx && state.canvas.canvas){
            state.canvas.ctx.clearRect(0, 0, state.canvas.canvas.width, state.canvas.canvas.height);
        }

        state.preprocessOptions = _.cloneDeep(statePreprocessOptions)
        state.project = _.cloneDeep(stateProject)
        state.canvas = _.cloneDeep(stateCanvas)
        state.ui = _.cloneDeep(stateUI)
    },
    [PROJECT_SET_PREPROCESS_OPTIONS]: (state, preprocessOptions) => {
        state.preprocessOptions = preprocessOptions
    },
    [PROJECT_SET_PROCESSED_IMAGE]: (state, image) => {
        let blob = new Blob([image], {type: 'image/png'});
        var url = URL.createObjectURL(blob);
        state.canvas.img.src = url; 
    },
    [RESET_OFFSCREENCANVAS]: (state) => {
        state.canvas.offscreen = new OffscreenCanvas(state.canvas.img.width, state.canvas.img.height);
        state.canvas.offscreenContext = state.canvas.offscreen.getContext("2d");

        state.ui.states.draw.drawCoordsLatest = null
        state.canvas.offscreenContext.clearRect(0, 0, state.canvas.canvas.width, state.canvas.canvas.height); 
    },
    [PROJECT_SET_BACKGROUND]: (state, image) => {
        let blob = new Blob([image], {type: 'image/png'});
        var url = URL.createObjectURL(blob);
        state.canvas.background.src = url; 
    },
    [PROJECT_SET_CANVAS]: (state, canvas) => {

        state.canvas.canvas = canvas;
        state.canvas.ctx = canvas.getContext('2d');
        state.canvas.ctxImg = state.canvas.canvasImg.getContext('2d');

        canvas.width = canvas.parentElement.offsetWidth;
        canvas.height = canvas.parentElement.offsetHeight;

    },
    [PROJECT_ZOOM]: (state, zoom) => {
        state.ui.zoomed = true;

        let centerPointXBase = (state.canvas.canvas.width / 2) - state.canvas.positions.x;
        let centerPointXPercent = centerPointXBase / (state.canvas.img.width * state.ui.zoom);
        let centerPointYBase = (state.canvas.canvas.height / 2) - state.canvas.positions.y;
        let centerPointYPercent = centerPointYBase / (state.canvas.img.height * state.ui.zoom);

        state.ui.zoom = zoom

        let recalcCenterXPoint = centerPointXPercent * (state.canvas.img.width * state.ui.zoom);
        let recalcCenterYPoint = centerPointYPercent * (state.canvas.img.height * state.ui.zoom);
    
        state.ui.states.drag.dragPosition.x = state.canvas.positions.x = (state.canvas.canvas.width / 2) - recalcCenterXPoint;  
        state.ui.states.drag.dragPosition.y = state.canvas.positions.y = (state.canvas.canvas.height / 2) - recalcCenterYPoint;
    },
    [PROJECT_SET_STATE_DRAWING]: (state) => {
        state.ui.state = 'draw'
        state.ui.states.freeselect.coords = []
        clearInterval(state.ui.states.freeselect.marching)
    },
    [PROJECT_SET_STATE_DRAGGING]: (state) => {
        state.ui.state = 'drag'
        state.ui.mode = 'default'
    },
    [PROJECT_SET_STATE_ERASE]: (state) => {
        state.ui.state = 'erase'
    },
    [PROJECT_SET_DRAW_LINE]: (state) => {
        state.ui.states.draw.drawCoordsLatest = {
            thickness: state.ui.states.draw.thickness,
            mode: 'draw',
            coords: []
        }
    },
    [COMMIT_DRAWING]: (state) => {
        state.ui.states.draw.drawCoords.unshift(state.ui.states.draw.drawCoordsLatest)
    },
    [PROJECT_RESET_SELECTION_LINE]: (state) => {
        state.ui.states.freeselect.coords = []
        clearInterval(state.ui.states.freeselect.marching)
    },
    [PROJECT_SET_DRAW_POSITION]: (state, coords) => {
        state.ui.states.draw.drawCoordsLatest.coords.push({
            x: ((coords.clientX - state.canvas.positions.x) / (state.canvas.img.width * state.ui.zoom)).toFixed(4),
            y: ((coords.clientY - state.canvas.positions.y) / (state.canvas.img.height * state.ui.zoom)).toFixed(4),
        })
    },
    [PROJECT_SET_DRAW_SELECTION]: (state, coords) => {
        state.ui.states.freeselect.coords.push({
            x: (coords.clientX - state.canvas.positions.x) / (state.canvas.img.width * state.ui.zoom),
            y: (coords.clientY - state.canvas.positions.y) / (state.canvas.img.height * state.ui.zoom),
        })
    },
    [PROJECT_SET_CLOSE_DRAWED_SELECTION]: (state) => {
        state.ui.states.freeselect.coords.push(state.ui.states.freeselect.coords[0]);
    },
    [PROJECT_SET_ERASE_LINE]: (state) => {
        state.ui.states.draw.drawCoordsLatest = {
            thickness: state.ui.states.draw.thickness,
            mode: 'erase',
            coords: []
        }
    },
    [PROJECT_SET_ERASE_POSITION]: (state, coords) => {
        state.ui.states.draw.drawCoordsLatest.coords.push({
            x: ((coords.clientX - state.canvas.positions.x) / (state.canvas.img.width * state.ui.zoom)).toFixed(4),
            y: ((coords.clientY - state.canvas.positions.y) / (state.canvas.img.height * state.ui.zoom)).toFixed(4),
        })
    },
    [PROJECT_SET_STENCIL_SELECTION]: (state) => {
        state.ui.state = 'select'
        state.ui.mode = 'select'
        state.ui.states.freeselect.coords = []
        clearInterval(state.ui.states.freeselect.marching)
    },
    [PROJECT_SET_CROP_SELECTION]: (state) => {
        state.ui.state = 'select'
        state.ui.mode = 'crop'
        state.ui.states.freeselect.coords = []
        clearInterval(state.ui.states.freeselect.marching)
    },
    [PROJECT_SET_STENCIL_FREE_SELECTION]: (state) => {
        state.ui.state = 'freeselect'
        state.ui.mode = 'freeselect'
    },
    [PROJECT_UNSET_STENCIL_SELECTION]: (state) => {
        state.ui.state = 'drag'
        state.ui.mode = 'default'
        state.ui.states.freeselect.coords = []
        clearInterval(state.ui.states.freeselect.marching)
    },
    [PROJECT_RESIZE_SELECTION_TL]: (state, e) => {
        state.ui.states.select.lockCanvasDrag = true
        state.ui.states.select.pos.x =  _.clamp((e.clientX - state.canvas.positions.x) / (state.canvas.img.width * state.ui.zoom),0,1)
        state.ui.states.select.pos.y =  _.clamp((e.clientY - state.canvas.positions.y) / (state.canvas.img.height * state.ui.zoom),0,1)
    },
    [PROJECT_RESIZE_SELECTION_TR]: (state, e) => {
        state.ui.states.select.lockCanvasDrag = true
        state.ui.states.select.pos.x2 = _.clamp((e.clientX - state.canvas.positions.x) / (state.canvas.img.width * state.ui.zoom),0,1)
        state.ui.states.select.pos.y = _.clamp((e.clientY - state.canvas.positions.y) / (state.canvas.img.height * state.ui.zoom),0,1)
    },
    [PROJECT_RESIZE_SELECTION_BR]: (state, e) => {
        state.ui.states.select.lockCanvasDrag = true
        state.ui.states.select.pos.x2 = _.clamp((e.clientX - state.canvas.positions.x) / (state.canvas.img.width * state.ui.zoom),0,1)
        state.ui.states.select.pos.y2 = _.clamp((e.clientY - state.canvas.positions.y) / (state.canvas.img.height * state.ui.zoom),0,1)
    },
    [PROJECT_RESIZE_SELECTION_BL]: (state, e) => {
        state.ui.states.select.lockCanvasDrag = true
        state.ui.states.select.pos.x = _.clamp((e.clientX - state.canvas.positions.x) / (state.canvas.img.width * state.ui.zoom),0,1)
        state.ui.states.select.pos.y2 = _.clamp((e.clientY - state.canvas.positions.y) / (state.canvas.img.height * state.ui.zoom),0,1)
    },
    [PROJECT_MOVE_SELECTION]: (state, e) => {
        let moveX = (state.ui.states.drag.dragStartPosition.x - e.clientX) / (state.canvas.img.width * state.ui.zoom);
        let moveY = (state.ui.states.drag.dragStartPosition.y - e.clientY) / (state.canvas.img.height * state.ui.zoom);
        
        state.ui.states.select.pos.x = _.clamp(state.ui.states.select.startDragPos.x - moveX, 0,1);
        state.ui.states.select.pos.x2 = _.clamp(state.ui.states.select.startDragPos.x2 - moveX, 0,1);
        state.ui.states.select.pos.y = _.clamp(state.ui.states.select.startDragPos.y - moveY, 0,1);
        state.ui.states.select.pos.y2 = _.clamp(state.ui.states.select.startDragPos.y2 - moveY, 0,1);
    },
    [PROJECT_SET_ANCHOR]: (state, anchor) => {
        let cursors = {
            'tl' : 'nwse-resize',
            'tr' : 'nesw-resize',
            'bl' : 'nesw-resize',
            'br' : 'nwse-resize',
            'drag': 'move'
        }

        state.canvas.canvas.style.cursor = cursors[anchor] ?? 'inherit'
        state.ui.states.select.anchor = anchor
    },
    [PROJECT_SET_PREVIEW]: (state, canvas) => {
        state.preview.canvas = canvas;
        state.preview.ctx = canvas.getContext('2d');
    },
    [PROJECT_SET_PREVIEW_SIZE]: (state) => {
        state.preview.canvas.width = state.preview.canvas.parentElement.offsetWidth;
        state.preview.canvas.height = (state.canvas.img.height / state.canvas.img.width) * state.preview.canvas.width;
    },
    [PROJECT_SET_UNSAVED]: (state) => {
        state.ui.saved = false
    },
    [PROJECT_SET_SAVED]: (state) => {
        state.ui.saved = true
    }
}