import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_REGISTER, AUTH_VERIFY, AUTH_REQUEST_RESET_PASSWORD, AUTH_RESET_PASSWORD } from "./types/action-types"
import { AUTH_REMOVE_TOKEN, AUTH_SET_LOGIN_ERROR, AUTH_SET_LOGIN_SUCCESS, AUTH_SET_REGISTER_ERROR, AUTH_SET_REGISTER_SUCCESS, AUTH_SET_VERIFY_ERROR, AUTH_SET_RESET_PASSWORD_SUCCESS, AUTH_SET_REQUEST_RESET_PASSWORD_SUCCESS } from "./types/mutation-types"

export default {
    [AUTH_REGISTER]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/auth/register', data: user, method: 'POST' })
                .then(resp => {
                    if (resp.data.status == "success") {
                        commit(AUTH_SET_REGISTER_SUCCESS, resp.data)
                        resolve(resp)
                    } else {
                        commit(AUTH_SET_REGISTER_ERROR, resp.data.errors)
                        reject(resp)
                    }
                })
                .catch(err => {
                    commit(AUTH_SET_REGISTER_ERROR, err)
                    reject(err)
                })
        })

    },
    [AUTH_VERIFY]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/auth/verify', data: data, method: 'POST' })
                .then(resp => {
                    if (resp.data.status == "success") {
                        resolve(resp)
                    } else {
                        commit(AUTH_SET_VERIFY_ERROR, resp.data)
                        reject(resp)
                    }
                })
                .catch(err => {
                    commit(AUTH_SET_VERIFY_ERROR, err)
                    reject(err)
                })
        })

    },
    [AUTH_LOGIN]: ({ commit }, user) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/auth/login', data: user, method: 'POST' })
                .then(resp => {

                    if (resp.data.status == "success") {
                        const token = resp.headers.authorization
                        localStorage.setItem('user-token', token) // store the token in localstorage
                        axios.defaults.headers.common['Authorization'] =
                            'Bearer ' + token

                        commit(AUTH_SET_LOGIN_SUCCESS, token)

                        resolve()
                    } else {
                        commit(AUTH_SET_LOGIN_ERROR, resp)
                    }
                })
                .catch(err => {
                    commit(AUTH_SET_LOGIN_ERROR, err.response)
                    localStorage.removeItem('user-token')

                    reject(err)
                })
        })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise((resolve) => {
            localStorage.removeItem('user-token')
            commit(AUTH_REMOVE_TOKEN)

            resolve()
        })
    },
    [AUTH_REQUEST_RESET_PASSWORD]: ({ commit }, email) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/auth/forgot-password', data: { email: email }, method: 'POST' })
                .then(resp => {
                        console.log("AUTH_REQUEST_RESET_PASSWORD resp", resp)
                        commit(AUTH_SET_RESET_PASSWORD_SUCCESS, resp.data)
                        resolve()
                })
                .catch(err => {
                    commit(AUTH_SET_RESET_PASSWORD_SUCCESS, err.response.data)
                    reject(err)
                })
        })
    },
    [AUTH_RESET_PASSWORD]: ({ commit }, data) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/auth/reset-password', data: data, method: 'POST' })
                .then(resp => {
                        console.log("AUTH_REQUEST_RESET_PASSWORD resp", resp)
                        commit(AUTH_SET_REQUEST_RESET_PASSWORD_SUCCESS, resp.data)
                        resolve()
                    
                })
                .catch(err => {
                    commit(AUTH_SET_REQUEST_RESET_PASSWORD_SUCCESS, err.response.data)
                    reject(err)
                })
        })
    },
}