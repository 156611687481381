import { AUTH_REMOVE_TOKEN } from "../auth/types/mutation-types"
import { AUTH_LOGOUT } from "../auth/types/action-types"
import { USER_CHANGE_SUBSCRIPTION, USER_CHECK_PAYMENT, USER_DOWNLOAD_INVOICE, USER_GET_USER, USER_STORE_BILLING, USER_STORE_USER, USER_UPLOAD_AVATAR, USER_APPLY_COUPON, ACCOUNT_REMOVE } from "./types/action-types"
import { USER_SET_DATA, USER_SET_ERROR, USER_SET_SUBSCRIPTION_STATUS } from "./types/mutation-types"

export default {
    [USER_GET_USER]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/auth/user', method: 'GET' })
                .then(resp => {

                    commit(USER_SET_DATA, resp.data.data)

                    resolve(resp)
                })
                .catch(err => {
                    commit(USER_SET_ERROR)
                    commit(AUTH_REMOVE_TOKEN)

                    reject(err)
                })
        })
    },
    [USER_STORE_USER]: ({ dispatch }, form) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/user/store', data: form, method: 'POST' })
                .then(resp => {

                    dispatch(USER_GET_USER)

                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [USER_STORE_BILLING]: ({ dispatch }, form) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/user/store_billing_info', data: form, method: 'POST' })
                .then(resp => {

                    dispatch(USER_GET_USER)

                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [USER_CHECK_PAYMENT]: ({ commit, state }) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/payment/check/' + state.profile._id, method: 'GET' })
                .then(resp => {

                    commit(USER_SET_SUBSCRIPTION_STATUS, resp.data.status)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [USER_CHANGE_SUBSCRIPTION]: ({ state, dispatch }, plan) => {
        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/user/subscription', data: {
                    user_id: state.profile._id,
                    plan: plan
                }, method: 'POST'
            })
                .then(resp => {

                    dispatch(USER_GET_USER)
                    resolve(resp)
                })
                .catch(err => {

                    reject(err.response)
                })
        })
    },
    [USER_UPLOAD_AVATAR]: ({ dispatch }, data) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/user/avatar/upload', data: data, method: 'POST' })
                .then(resp => {

                    dispatch(USER_GET_USER)
                    resolve(resp)
                })
                .catch(err => {

                    reject(err)
                })
        })
    },
    [ACCOUNT_REMOVE]: ({ dispatch }) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/user/delete', method: 'POST' })
                .then(resp => {
                    dispatch(AUTH_LOGOUT)
                    resolve(resp)
                })
                .catch(err => {

                    reject(err)
                })
        })
    },
    // [USER_CHANGE_SUBSCRIPTION]: ({ state, dispatch }, plan) => {
    //     return new Promise((resolve, reject) => {
    //         axios({ url: process.env.VUE_APP_API_URL + '/api/v1/user/subscription', data: {
    //             user_id:  state.profile._id,
    //             plan: plan
    //         }, method: 'POST' })
    //             .then(resp => {  

    //                 dispatch(USER_GET_USER)
    //                 resolve(resp)
    //             })
    //             .catch(err => {

    //                 reject(err)
    //             })
    //     })
    // },
    [USER_DOWNLOAD_INVOICE]: ({ state }, invoice) => {
        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/payment/invoice/' + invoice,
                method: 'GET',
                responseType: "blob"
            }).then(resp => {
                if (resp.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement("a");

                    link.href = url;
                    link.setAttribute('download', state.profile._id + '-' + invoice + '.pdf');
                    document.body.appendChild(link);

                    link.click();

                    resolve(resp)
                } else {
                    reject(resp)
                }
            }).catch(err => {

                reject(err)
            })
        })
    },
    [USER_APPLY_COUPON]: ({ dispatch }, coupon_code) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/user/coupon', data: { coupon_code: coupon_code }, method: 'POST' })
                .then(resp => {

                    dispatch(USER_GET_USER)

                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}