export const PROJECT_SET_PROJECT = 'PROJECT_SET_PROJECT'
export const PROJECT_RESET_STATE = 'PROJECT_RESET_STATE'
export const PROJECT_SET_PREPROCESS_OPTIONS = 'PROJECT_SET_PREPROCESS_OPTIONS'
export const PROJECT_SET_PROCESSED_IMAGE = 'PROJECT_SET_PROCESSED_IMAGE'
export const PROJECT_SET_CANVAS = 'PROJECT_SET_CANVAS'
export const PROJECT_SET_PRINT_CANVAS = 'PROJECT_SET_PRINT_CANVAS'
export const PROJECT_SET_PAGE_SIZE = 'PROJECT_SET_PAGE_SIZE'
export const PROJECT_SET_STATE_DRAGGING = 'PROJECT_SET_STATE_DRAGGING'
export const PROJECT_SET_STATE_DRAWING = 'PROJECT_SET_STATE_DRAWING'
export const PROJECT_SET_STATE_ERASE = 'PROJECT_SET_STATE_ERASE'
export const PROJECT_SET_DRAW_LINE = 'PROJECT_SET_DRAW_LINE'
export const PROJECT_SET_DRAW_POSITION = 'PROJECT_SET_DRAW_POSITION'
export const PROJECT_SET_DRAW_SELECTION = 'PROJECT_SET_DRAW_SELECTION'
export const PROJECT_RESET_SELECTION_LINE = 'PROJECT_RESET_SELECTION_LINE'
export const PROJECT_SET_CLOSE_DRAWED_SELECTION = 'PROJECT_SET_CLOSE_DRAWED_SELECTION'
export const PROJECT_ZOOM = 'PROJECT_ZOOM'
export const PROJECT_SET_ZOOM = 'PROJECT_SET_ZOOM'
export const PROJECT_SET_ERASE_LINE = 'PROJECT_SET_ERASE_LINE'
export const PROJECT_SET_ERASE_POSITION = 'PROJECT_SET_ERASE_POSITION'
export const PROJECT_SET_STENCIL_SELECTION = 'PROJECT_SET_STENCIL_SELECTION'
export const PROJECT_SET_STENCIL_FREE_SELECTION = 'PROJECT_SET_STENCIL_FREE_SELECTION'
export const PROJECT_UNSET_STENCIL_SELECTION = 'PROJECT_UNSET_STENCIL_SELECTION'
export const PROJECT_RESIZE_SELECTION_TL = 'PROJECT_RESIZE_SELECTION_TL'
export const PROJECT_RESIZE_SELECTION_TR = 'PROJECT_RESIZE_SELECTION_TR'
export const PROJECT_RESIZE_SELECTION_BR = 'PROJECT_RESIZE_SELECTION_BR'
export const PROJECT_RESIZE_SELECTION_BL = 'PROJECT_RESIZE_SELECTION_BL'
export const PROJECT_RESIZE_PRINT_SELECTION_TL = 'PROJECT_RESIZE_PRINT_SELECTION_TL'
export const PROJECT_RESIZE_PRINT_SELECTION_TR = 'PROJECT_RESIZE_PRINT_SELECTION_TR'
export const PROJECT_RESIZE_PRINT_SELECTION_BR = 'PROJECT_RESIZE_PRINT_SELECTION_BR'
export const PROJECT_RESIZE_PRINT_SELECTION_BL = 'PROJECT_RESIZE_PRINT_SELECTION_BL'
export const PROJECT_MOVE_SELECTION = 'PROJECT_MOVE_SELECTION'
export const PROJECT_MOVE_PRINT_SELECTION = 'PROJECT_MOVE_PRINT_SELECTION'
export const PROJECT_SET_ANCHOR = 'PROJECT_SET_ANCHOR'
export const PROJECT_SET_PREVIEW = 'PROJECT_SET_PREVIEW'
export const PROJECT_SET_PREVIEW_SIZE = 'PROJECT_SET_PREVIEW_SIZE'
export const PROJECT_SET_SAVED = 'PROJECT_SET_SAVED'
export const PROJECT_SET_UNSAVED = 'PROJECT_SET_UNSAVED'
export const PROJECT_SET_PRINT_ANCHOR = 'PROJECT_SET_PRINT_ANCHOR'
export const PROJECT_SET_PRINT_PAGE = 'PROJECT_SET_PRINT_PAGE'
export const PROJECT_SET_SNAPSHOTS = 'PROJECT_SET_SNAPSHOTS'
export const PROJECT_SET_CROP_SELECTION = 'PROJECT_SET_CROP_SELECTION'
export const PROJECT_SET_BACKGROUND = 'PROJECT_SET_BACKGROUND'
export const PROJECT_SET_HISTORY_POINT = 'PROJECT_SET_HISTORY_POINT'
export const PROJECT_SET_HISTORY = 'PROJECT_SET_HISTORY'
export const RESET_OFFSCREENCANVAS = 'RESET_OFFSCREENCANVAS'
export const COMMIT_DRAWING = 'COMMIT_DRAWING'