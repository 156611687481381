<template>
    <div class="u-px-4">
        <InputBase class="u-mt-4 u-w-2/3" :placeholder="$t('designer.add-customer.search-customer')" v-model="search" />

        <div class="u-mt-4">
            <h5>{{ $t('designer.add-customer.add-a-customer') }}</h5>
            <div class="u-border-b u-border-neutral-20 u-p-2 u-flex u-justify-between u-items-center" v-for="customer in customers.items" :key="customer._id">
                <h5>{{ customer.name }}</h5>
                <div>
                    <a v-if="activeCustomers.includes(customer._id)" @click="removeCustomer(customer)" class="c-btn c-btn--icon c-btn--success">
                        <i class="fal fa-check"></i>
                    </a>
                    <a v-else @click="addCustomer(customer)" class="c-btn c-btn--icon c-btn--default">
                        <i class="fal fa-plus"></i>
                    </a>
                </div>
            </div>
            <div class="u-py-4" v-if="customers.pages > 1">
                <Pagination 
                    :currentPage="customers.page"
                    :max="customers.pages"
                    @setPage="setPage" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import InputBase from '@components/input-base'
import Pagination from '@components/pagination'
import { CUSTOMERS_GET_CUSTOMERS } from '@store/stores/customers/types/action-types'

export default {
    props: {
        activeCustomers: {
            type: Array,
            default(){
                return []
            }
        }
    },
    components: {
        InputBase,
        Pagination
    },
    data(){
        return {
            search: null
        }
    },
    mounted(){
        this.$store.dispatch(CUSTOMERS_GET_CUSTOMERS, {
            page: 1,
            size: 8,
            search: ''
        })
    },
    watch: {
        search(val){
            this.$store.dispatch(CUSTOMERS_GET_CUSTOMERS, {
                page: 1,
                size: 8,
                search: val
            })
        }
    },
    computed: {
        ...mapState(['customers', 'user'])
    },
    methods: {
        addCustomer(customer){
            this.$emit('attach', customer)
        },
        removeCustomer(customer){
            this.$emit('detach', customer)
        },
        setPage(p){
            this.$store.dispatch(CUSTOMERS_GET_CUSTOMERS, {
                page: p,
                size: 8,
                search: this.search
            })
        }
    }
}
</script>