export const stateUI = {
    zoom: 1,
    zoomed: false,
    state: 'drag',
    dragging: false,
    mode: 'default',
    saved: true,
    toolPanelStates: {
        crop: true,
        collapsed: true,
        drawing: false,
        erase: false,
        stencil: false,
        snapshots: false,
        size: false,
        navigator: true
    },
    states: {
        drag: {
            dragged: false,
            dragStartPosition: {
                x: null,
                y: null
            },
            dragPosition: {
                x: null,
                y: null
            }
        },
        draw: {
            thickness: 1,
            drawCoords: [],
            drawCoordsLatest: null
        },
        select: {
            anchor: null,
            anchorFix: false,
            lockCanvasDrag: false,
            pos: {
                x: .4,
                x2: .6,
                y: .4,
                y2: .6
            },
            startDragPos: {
                x: .4,
                x2: .6,
                y: .4,
                y2: .6
            }
        },
        freeselect: {
            offset: 0,
            marching: null,
            coords: []
        }
    }
    
}