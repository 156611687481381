<template>
    <LayoutBase>
        <div class="u-max-w-tablet u-mx-auto u-flex-grow u-flex u-items-center">
            
            <div class="u-text-center" v-if="user.subscription_data.status == 'paid'">
                
                <h1>{{ $t('subscription.payment.paid.title') }}</h1>
                <p class="c-lead">
                    {{ $t('subscription.payment.paid.text') }}
                </p>
                <div class="u-mt-4">
                     <router-link :to="{name: 'subscription'}" class="c-btn c-btn--primary">
                         <span>{{ $t('subscription.payment.paid.button') }}</span>
                     </router-link>
                </div>
            </div>
            <div class="u-text-center" v-if="user.subscription_data.status == 'open'">
                
                <h1>{{ $t('subscription.payment.open.title') }}</h1>
                <p class="c-lead">
                    {{ $t('subscription.payment.open.text') }}
                </p>
                <div class="u-mt-4">
                     <router-link :to="{name: 'subscription'}" class="c-btn c-btn--default">
                         <span>{{ $t('subscription.payment.open.button') }}</span>
                     </router-link>
                </div>
            </div>
            <div class="u-text-center" v-if="user.subscription_data.status == 'canceled'">
                
                <h1>{{ $t('subscription.payment.canceled.title') }}</h1>
                <p class="c-lead">
                    {{ $t('subscription.payment.canceled.text') }}
                </p>
                <div class="u-mt-4">
                     <router-link :to="{name: 'subscription'}" class="c-btn c-btn--default">
                         <span>{{ $t('subscription.payment.canceled.button') }}</span>
                     </router-link>
                </div>
            </div>

        </div>
    </LayoutBase>

</template>
<script>
import { mapState } from 'vuex'

import LayoutBase from '@layouts/base'
import { USER_CHECK_PAYMENT } from '@store/stores/user/types/action-types'

export default {
    components: {
        LayoutBase
    },
    data(){
        return {
            timer: null
        }
    },
    computed: {
        ...mapState(['ui', 'user'])
    },
    watch: {
        'user.subscription_data.status'(state){
            if(['paid','canceled'].includes(state)){
                clearInterval(this.timer);
            }
        }
    },
    mounted(){
        this.$store.dispatch(USER_CHECK_PAYMENT)

        if (!this.user.subscription_data.state || this.user.subscription_data.state == "open") {
            this.timer = setInterval(() => {
                this.$store.dispatch(USER_CHECK_PAYMENT)
            }, 5000);
        }
    }  
}
</script>