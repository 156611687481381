import { PROJECTS_RESET_PROJECTS, PROJECTS_SET_LOADING, PROJECTS_SET_PROJECTS } from "./types/mutation-types";

export default {
    [PROJECTS_RESET_PROJECTS]: (state) => {
        state.items = []
    },
    [PROJECTS_SET_PROJECTS]: (state, projects) => {
        state.items = projects.items
        state.pages = projects.pages
        state.page = parseInt(projects.current_page),
        state.total = projects.items_total
    },
    [PROJECTS_SET_LOADING]: (state, loading) => {
        state.loading = loading
    }
}