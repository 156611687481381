<template>
    <Panel>
       
            <div class="c-plan-checkbox">
                <div class="c-plan-checkbox__plan" v-for="(plan) in user.available_subscriptions" :key="plan.id">
                    <div class="c-plan-checkbox__plan-body">
                        <div class="c-plan-checkbox__description-box">
                            <h3>{{ $t('subscription.plan-titles.' + plan.code) }}</h3>
                            <span class="c-plan-checkbox__description">{{ $t('subscription.plan-descriptions.' + plan.code) }}</span>
                            <ul class="c-checklist u-mt-2">
                                <li class="c-checklist__item">{{ $t('subscription.limits.customers',{'n': plan.limits.max_customers}) }}</li>
                                <li class="c-checklist__item">{{ $t('subscription.limits.projects',{'n': plan.limits.max_projects}) }}</li>
                                <!-- <li class="c-checklist__item">{{ $t('subscription.limits.max_storage',{'n': plan.limits.max_storage/1000}) }}</li> -->
                            </ul>
                        </div>

                        <div class="c-plan-checkbox__bottom">

                            <div class="c-plan-checkbox__price" v-if="parseInt(plan.price) > 0">
                                <span :class="{'u-line-through u-text-danger': hasDiscount}">€ {{ parseFloat(plan.price).toFixed(2) }} p/m</span>
                                <span v-if="hasDiscount"><br>€ {{ discountPrice(plan) }} p/m</span>
                            </div>
                            <div class="c-plan-checkbox__price" v-else>
                                {{ $t('subscription.free') }}
                            </div>

                            <div class="o-block u-text-center u-mt-4">
                                <a class="c-btn c-btn--icon c-btn--primary" v-if="user.subscription_data.plan_code === plan.code && user.subscription_data.nextPlan === plan.code" >
                                    <i class="fal fa-check"></i>
                                </a>

                                <span v-else-if="plan.code == 'free' && (user.subscription_data.plan_code != 'free' && user.subscription_data.plan_code)">
                                    <a @click="changePlan(plan.code)" v-if="user.subscription_data.status != 'canceled'"  class="c-btn c-btn--default">
                                        {{ $t('subscription.cancel') }}
                                    </a>
                                    <span v-else><strong>({{ $t('subscription.status.canceled') }})</strong></span>
                                    <!-- <span v-if="user.subscription_data.nextPlan != null"><strong>({{ $t('subscription.status.autorenew') }})</strong></span> -->
                                </span>

                                <a @click="changePlan(plan.code)" v-else-if="plan.code != user.subscription_data.plan_code" class="c-btn c-btn--default">
                                    {{ $t('subscription.select') }}
                                </a>

                                <a @click="changePlan(plan.code)" v-else-if="user.subscription_data.plan_code === plan.code && user.subscription_data.status == 'canceled'" class="c-btn c-btn--default">
                                    {{ $t('subscription.undo_cancel') }}
                                </a>

                            </div>
                        </div>
                    </div> 
                </div>
            </div>
    </Panel>
</template>
<script>
import { mapState } from 'vuex'

import Panel from '@components/panel'
import { USER_CHANGE_SUBSCRIPTION } from '../../store/stores/user/types/action-types'

export default {
    components: {
        Panel
    },
    data(){
        return {
        
        }
    },
    computed: {
        ...mapState(['user']),
        hasDiscount(){
            return (this.user.coupon_data?.discount_amount && parseFloat(this.user.coupon_data?.discount_amount).toFixed(2) != 0.00)
        }
    },
    methods: {
        discountPrice(plan){
            if(this.hasDiscount) {
                let amount = parseFloat(plan.price) - parseFloat(this.user.coupon_data?.discount_amount);
                if (amount < 0) amount = 0;
                return amount.toFixed(2);
            }
            return parseFloat(plan.price).toFixed(2)
        },
        changePlan(plan){
            this.$store.dispatch(USER_CHANGE_SUBSCRIPTION, plan).then((resp) => {
                if (resp.status == 200) {
                    if (resp.data.process == "payment_success")
                        this.$store.commit('ui/setFlashSuccessMessage', this.$t('subscription.change.success'))
                    if (resp.data.process == "redirect_to_payment")
                        window.location.replace(resp.data.redirect_url);
                    if (resp.data.process == "subscription_success")
                        this.$store.commit('ui/setFlashSuccessMessage', this.$t('subscription.change.upgraded-success'))
                    if (resp.data.process == "subscription_canceled")
                        this.$store.commit('ui/setFlashSuccessMessage', this.$t('subscription.change.canceled'))
                }

                this.$emit('close')
            }).catch((err) => {
                this.$emit('close')

                console.log("err ", err)
                
                if (err.status == 422 && err.data.errors) {

                    this.$store.commit('ui/setFlashErrorMessage', this.$t('subscription.change.missing-data'))
                } else 
                this.$store.commit('ui/setFlashErrorMessage', this.$t('subscription.change.something-went-wrong'))
            })
        }
    },
}
</script>