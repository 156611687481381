<template>
    <Panel>
        <form @submit.prevent="updateBilling">
            <InputGroup :label="$t('subscription.invoice-data.invoice-name')">
                <InputBase 
                    type="text" 
                    v-model="form.invoice_name" 
                    :placeholder="$t('subscription.invoice-data.invoice-name')"
                    autocomplete="name" 
                    :validations="[
                        {
                            condition: errors && !!errors.invoice_name,
                            text: errors && errors.invoice_name ? errors.invoice_name[0] : null
                        }
                    ]"/>
            </InputGroup>

            <div class="u-grid u-grid-cols-3 u-gap-2">
                <InputGroup class="u-col-span-2 u-mt-2" :label="$t('subscription.invoice-data.street')">
                    <InputBase 
                        type="text" 
                        v-model="form.street" 
                        :placeholder="$t('subscription.invoice-data.street')"
                        autocomplete="street" 
                        :validations="[
                            {
                                condition: errors && !!errors.street,
                                text: errors && errors.street ? errors.street[0] : null
                            }
                        ]"/>
                </InputGroup>
            
                <InputGroup :label="$t('subscription.invoice-data.house_number')">
                    <InputBase 
                        type="text" 
                        v-model="form.house_number" 
                        :placeholder="$t('subscription.invoice-data.house_number')"
                        autocomplete="street" 
                        :validations="[
                            {
                                condition: errors && !!errors.house_number,
                                text: errors && errors.house_number ? errors.house_number[0] : null
                            }
                        ]"/>
                </InputGroup>
            </div>

            <InputGroup :label="$t('subscription.invoice-data.postcode')">
                <InputBase 
                    type="text" 
                    v-model="form.postcode" 
                    :placeholder="$t('subscription.invoice-data.postcode')"
                    autocomplete="postcode" 
                    :validations="[
                        {
                            condition: errors && !!errors.postcode,
                            text: errors && errors.postcode ? errors.postcode[0] : null
                        }
                    ]"/>
            </InputGroup>

            <InputGroup :label="$t('subscription.invoice-data.city')">
                <InputBase 
                    type="text" 
                    v-model="form.city" 
                    :placeholder="$t('subscription.invoice-data.city')"
                    autocomplete="city" 
                    :validations="[
                        {
                            condition: errors && !!errors.city,
                            text: errors && errors.city ? errors.city[0] : null
                        }
                    ]"/>
            </InputGroup>

            <InputGroup :label="$t('subscription.invoice-data.country')">
                <InputBase 
                    type="text" 
                    v-model="form.country" 
                    :placeholder="$t('subscription.invoice-data.country')"
                    autocomplete="country" 
                    :validations="[
                        {
                            condition: errors && !!errors.country,
                            text: errors && errors.country ? errors.country[0] : null
                        }
                    ]"/>
            </InputGroup>

            <button class="c-btn c-btn--primary u-mt-4">
                <span>
                    <i class="fal fa-check"></i> {{ $t('subscription.invoice-data.update') }}
                </span>
            </button>
        </form>
    </Panel>
</template>
<script>
import { mapState } from 'vuex'

import Panel from '@components/panel'
import InputBase from '@components/input-base'
import InputGroup from '@components/input-group'
import { USER_STORE_BILLING } from '../../store/stores/user/types/action-types'

export default {
    components: {
        Panel,
        InputBase,
        InputGroup
    },
    data(){
        return {
            errors: [],
            form: {
                invoice_name: null,
                street: null,
                house_number: null,
                postcode: null,
                city: null,
                country: null,
            }
        }
    },
    mounted(){
        this.form.invoice_name = this.user.profile.invoice_name
        this.form.street = this.user.profile.street
        this.form.house_number = this.user.profile.house_number
        this.form.postcode = this.user.profile.postcode
        this.form.city = this.user.profile.city
        this.form.country = this.user.profile.country
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        updateBilling(){
            this.$store.dispatch(USER_STORE_BILLING, this.form).then(() => {
                this.$emit('close')

                this.$store.commit('ui/setFlashSuccessMessage', this.$t('subscription.invoice-data.success'))
            }).catch((resp) => {
                this.errors = resp.response.data.errors
                this.$store.commit('ui/setFlashErrorMessage', this.errors)
            })
        }
    }
}
</script>