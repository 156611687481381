<template>
    <component :is="type" :class="[blockClass,gridClass.wrapper]">
        <div :class="elementClass('label') + gridClass.label" v-if="$slots.label">
            <slot name="label"></slot> <a v-if="help" class="c-input__help-note">?</a>
        </div>
        <div :class="elementClass('label') + gridClass.label" v-else-if="label">
            {{ label }}
            <a ref="helpbtn" v-if="help" class="c-input__help-note">?</a>
        </div>
        <div :class="elementClass('input') + gridClass.input">
            <slot></slot>
        </div>
    </component>
</template>
<script>
    import BEM from '@helpers/bem';
    import { ref, onMounted } from 'vue';
    import tippy from 'tippy.js';

  export default {
        setup(props){
            const help = ref(null);
            const helpbtn = ref(null);

            onMounted(()=> {
                if(helpbtn.value){
                    tippy(helpbtn.value, {
                        content: props.help
                    });
                }
            })

            return {
                help,
                helpbtn
            }
        },
        blockName: 'c-input-group',
        mixins: [BEM],
        props: {
            label: {
                type: [String,Boolean],
                default: false
            },
            grid: {
                type: Array,
                required: false
            },
            type: {
                type: String,
                default: 'label'
            }
        },
        computed: {
            gridClass() {
                return {
                    wrapper: this.grid ? 'c-input-group--horizontal u-grid-cols-' + this.grid[0] : '',
                    label: this.grid ? ' u-col-span-' + this.grid[1] : '',
                    input: this.grid ? ' u-col-span-' + (12 - this.grid[1]) : '',
                }
            }
        }
  }
</script>
