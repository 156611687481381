<template>
    <div class="u-flex-grow u-flex u-flex-col u-h-screen">
        <a href="/" class="u-w-40 u-mx-auto u-mt-16 u-block">
            <picture class="u-w-full">
                <source srcset="@/assets/images/logo-printcil-diapos.svg" media="(prefers-color-scheme: dark)">
                <img src="@/assets/images/logo-printcil.svg" alt="Logo Printcil">
            </picture>
        </a>

        <div class="u-h-full u-flex u-flex-col u-mt-8 u-justify-between u-border-r u-border-neutral-20">
            <div class="u-px-8 u-mt-8 u-mx-auto">
                <NavMain />
            </div>

            <div class="u-px-8 u-mt-8 u-mb-8 u-min-w-mobile">
                <div class="u-relative" v-click-away="closeUserMenu">
                    <ProfileBox class="u-cursor-pointer" @click="usermenu = !usermenu" :avatar="user.profile.avatar_url">
                        <h5>{{ user.profile.name }}</h5>
                        <span class="u-text-faded">{{ $t('auth.edit-profile') }}</span>
                    </ProfileBox>

                    <div class="u-absolute u-bottom-full u-left-0 u-mb-4 u-w-full" v-if="usermenu">
                        <Dropdown>
                            <NavUserMenu />
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

import ProfileBox from '@components/profile-box';
import Dropdown from '@components/dropdown';

import NavMain from '@parts/navs/nav-main';
import NavUserMenu from '@parts/navs/nav-user-menu';

export default {
    data(){
        return {
            usermenu: false
        }
    },
    components: {
        NavMain,
        Dropdown,
        ProfileBox,
        NavUserMenu
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        closeUserMenu(){
            this.usermenu = false
        }
    }
}
</script>