<template>
    <transition name="bar-toggle">
        <div :class="blockClass" v-if="collapsed">
            <div :class="elementClass('wrapper')">
                <div :class="elementClass('header')" v-if="$slots.header">
                    <slot name="header"></slot>
                </div>
                <div :class="elementClass('content')" v-if="$slots.default">
                    <slot />
                </div>
                <div :class="elementClass('footer')" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
  import { mapState } from 'vuex'
  import BEM from '@helpers/bem';

  export default {
    blockName: 'c-bar-bottom',
    mixins: [BEM],
    props: {
      modelValue: {
        type: Boolean,
        default: true
      },
      addModifiers: {
        type: Array,
        default(){
          return []
        }
      }
    },
    data(){
        return{
            collapsed: this.modelValue
        }
    },
    watch: {
      collapsed(val){
        if(val){
          this.modifierList.filter(item => item !== 'active');
        } else {
          this.modifierList.push('active')
          this.modifierList.push(this.addModifiers)
        }
      },
      modelValue(value) {
        this.collapsed = value;
      },
    },
    computed: {
      ...mapState(['ui'])
    },
    mounted() {
        if(this.ui.isIos){
          this.modifierList.push('is-ios')
        }
    }
  }
</script>
