import _ from 'lodash';
import { cursorPosition } from "@helpers/cursor"
import { PROJECT_ADD_IMAGE, PROJECT_CREATE, PROJECT_GET_DATA, PROJECT_PREPROCESS, PROJECT_GET_PROCESSED_IMAGE, PROJECT_UPLOAD_AND_CREATE, PROJECT_DRAW_CANVAS, PROJECT_SET_IMAGE, PROJECT_PREPARE_CANVAS, PROJECT_CLEAR_CANVAS, PROJECT_DRAG_CANVAS, PROJECT_SET_DRAG, PROJECT_DRAW_LINE, PROJECT_ERASE_LINE, PROJECT_DRAG_SELECTION, PROJECT_DRAW_SELECTION_BOX, PROJECT_DRAW_LINES, PROJECT_MOUSE_EFFECTS, PROJECT_DRAW_SELECTION, PROJECT_DRAW_FREE_SELECTION_BOX, PROJECT_PREPARE_PREVIEW, PROJECT_DRAW_PREVIEW, PROJECT_EXPORT_WITH_BACKGROUND, PROJECT_SAVE, PROJECT_SAVE_TITLE, PROJECT_RESET_STATES, PROJECT_ADD_SNAPSHOT, PROJECT_DOWNLOAD_SNAPSHOT, PROJECT_REMOVE_SNAPSHOT, PROJECT_CROP_IMAGE, PROJECT_ADD_CUSTOMER, PROJECT_GET_BACKGROUND, PROJECT_SET_SHORTCUTS, PROJECT_HISTORY_UNDO, PROJECT_HISTORY_REDO, PROJECT_UNDO, PROJECT_REDO, PROJECT_RESET, PROJECT_RESET_CANVAS_SIZES, PROJECT_RESIZE_IMAGE, PROJECT_REMOVE, PROJECT_SET_ABORT_CONTROLLER, PROJECT_ROTATE } from "./types/action-types"
import { PROJECT_MOVE_SELECTION, PROJECT_RESIZE_SELECTION_BL, PROJECT_RESIZE_SELECTION_BR, PROJECT_RESIZE_SELECTION_TL, PROJECT_RESIZE_SELECTION_TR, PROJECT_SET_ANCHOR, PROJECT_SET_CANVAS, PROJECT_SET_CLOSE_DRAWED_SELECTION, PROJECT_SET_DRAW_LINE, PROJECT_SET_DRAW_POSITION, PROJECT_SET_DRAW_SELECTION, PROJECT_SET_ERASE_LINE, PROJECT_SET_ERASE_POSITION, PROJECT_SET_PROCESSED_IMAGE, PROJECT_SET_PROJECT, PROJECT_RESET_SELECTION_LINE, PROJECT_SET_PREVIEW, PROJECT_SET_PREVIEW_SIZE, PROJECT_SET_UNSAVED, PROJECT_SET_SAVED, PROJECT_RESET_STATE, PROJECT_SET_SNAPSHOTS, PROJECT_SET_STATE_DRAGGING, PROJECT_SET_BACKGROUND, PROJECT_SET_HISTORY_POINT, RESET_OFFSCREENCANVAS, COMMIT_DRAWING } from "./types/mutation-types"

import PrintActions from './actions/print'
import HistoryActions from './actions/history'
import { HISTORY_DRAW, HISTORY_ERASE, HISTORY_STENCIL } from "./types/history-types"

export default {
    ...PrintActions,
    ...HistoryActions,
    [PROJECT_SET_SHORTCUTS]: ({ dispatch }, e) => {

        if (e.code === "KeyZ" && e.metaKey && e.shiftKey) {
            dispatch(PROJECT_HISTORY_REDO)
        } else if (e.code === "KeyZ" && e.metaKey) {
            dispatch(PROJECT_HISTORY_UNDO)
        }
    },
    [PROJECT_RESET_STATES]: ({ commit }) => {
        return new Promise((resolve) => {
            commit(PROJECT_RESET_STATE)
            resolve()
        })
    },
    [PROJECT_CREATE]: ({ commit }, name) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/workspace/project', data: { title: name }, method: 'POST' })
                .then(resp => {
                    commit(PROJECT_SET_PROJECT, resp.data.data)

                    resolve(resp.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_REMOVE]: ({ state }, id) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/workspace/project/delete', data: { _id: id }, method: 'POST' })
                .then(resp => {
                    console.log(state)
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_SAVE]: ({ state, getters, commit }) => {
        state.loading = true;

        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/draw', data: {
                    coords: getters.drawCoords,
                    project: state.project,
                    history: state.history,
                }, method: 'POST'
            })
                .then(resp => {
                    commit(PROJECT_SET_SAVED)
                    state.loading = false;
                    resolve(resp.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_SAVE_TITLE]: ({ state, commit }) => {
        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/workspace/project/', data: {
                    "_id": state.project._id,
                    "title": state.project.title
                }, method: 'POST'
            })
                .then(resp => {
                    commit(PROJECT_SET_PROJECT, resp.data.data)

                    resolve(resp.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_ADD_CUSTOMER]: ({ state, commit }, customer) => {
        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/workspace/project/attach_customer', data: {
                    "project_id": state.project._id,
                    "customer_id": customer._id
                }, method: 'POST'
            })
                .then(resp => {
                    commit(PROJECT_SET_PROJECT, resp.data.data)

                    resolve(resp.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_GET_DATA]: ({ commit }, project_id) => {
        return new Promise((resolve, reject) => {
            axios({ url: process.env.VUE_APP_API_URL + '/api/v1/workspace/project/' + project_id, method: 'GET' })
                .then(resp => {
                    commit(PROJECT_SET_PROJECT, resp.data.data)
                    // commit(PROJECT_SET_HISTORY_POINT, 'Open')
                    resolve(resp.data.data)

                    if (this.$route.query.isApp) {
                        if (typeof window.ReactNativeWebView !== 'undefined')
                            window.ReactNativeWebView.postMessage('changed');
                        
                        if (typeof window.webkit.messageHandlers !== 'undefined')
                            window.webkit.messageHandlers.handler.postMessage('changed');
                    }

                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_ADD_SNAPSHOT]: ({ state, dispatch }) => {
        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/snapshot', data: {
                    project: {
                        ...state.project,
                        preprocessOptions: state.preprocessOptions
                    }
                }, method: 'POST'
            })
                .then(resp => {

                    dispatch(PROJECT_GET_DATA, state.project._id)
                    resolve(resp.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_REMOVE_SNAPSHOT]: ({ state, commit }, id) => {
        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/delete_snapshot/' + id, data: {
                    project: {
                        ...state.project,
                        preprocessOptions: state.preprocessOptions
                    }
                }, method: 'POST'
            })
                .then(resp => {

                    commit(PROJECT_SET_SNAPSHOTS, resp.data.data.snapshots)
                    resolve(resp.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_DOWNLOAD_SNAPSHOT]: ({ state }, id) => {
        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/download_snapshot/' + state.project._id + '/' + id,
                method: "GET",
                responseType: "blob"
            }).then(resp => {
                if (resp.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement("a");

                    link.href = url;
                    link.setAttribute('download', state.project.title + '_printcil.png');
                    document.body.appendChild(link);

                    link.click();

                    resolve(resp)
                } else {
                    reject(resp)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    [PROJECT_CROP_IMAGE]: ({ state, commit, getters, dispatch }) => {
        state.loading = true;
        dispatch(PROJECT_SET_ABORT_CONTROLLER);

        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/crop', data: {
                    history: state.history,
                    project: state.project,
                    preprocessOptions: getters.formattedPreprocessOptions
                }, method: 'POST', signal: state.abortController.signal
            })
                .then(resp => {
                    state.preprocessOptions = resp.data.preprocessOptions
                    state.loading = false;
                    commit(PROJECT_SET_HISTORY_POINT, HISTORY_STENCIL)
                    dispatch(PROJECT_GET_PROCESSED_IMAGE).then(() => {

                        commit(PROJECT_SET_STATE_DRAGGING)
                        resolve(resp.data.data)
                    })
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_ADD_IMAGE]: ({ state, commit }, imageFile) => {
        const file = new Blob([imageFile]);
        let data = new FormData();

        data.append("file", file, imageFile.name);
        data.append("project", JSON.stringify({ '_id': state.project._id }));

        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/image/upload', data: data, method: 'POST', headers: {
                    "Content-Type": imageFile.type
                }
            })
                .then(resp => {

                    commit(PROJECT_SET_HISTORY_POINT, 'Open')
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_UPLOAD_AND_CREATE]: ({ dispatch }, file) => {

        dispatch(PROJECT_RESET_STATES);

        return new Promise((resolve, reject) => {
            return dispatch(PROJECT_CREATE, file.name.replace(/\.[^.$]+$/, '')).then(resp => {
                dispatch(PROJECT_ADD_IMAGE, file).then(() => {
                    resolve(resp)
                }).catch(err => { reject(err) })
            }).catch(err => { reject(err) })
        })
    },
    [PROJECT_PREPROCESS]: ({ state, dispatch, getters, commit }) => {
        state.loading = true;

        dispatch(PROJECT_SET_ABORT_CONTROLLER);

        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/preprocess', data: {
                    history: state.history,
                    project: state.project,
                    preprocessOptions: getters.formattedPreprocessOptions
                }, method: 'POST', signal: state.abortController.signal
            })
                .then(resp => {
                    state.preprocessOptions = resp.data.preprocessOptions

                    commit(PROJECT_SET_HISTORY_POINT, HISTORY_STENCIL)

                    dispatch(PROJECT_GET_DATA, state.project._id);

                    dispatch(PROJECT_GET_PROCESSED_IMAGE).then(() => {
                        resolve(resp.data.data)
                    })
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_GET_PROCESSED_IMAGE]: ({ state, commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/preprocessed/' + state.project._id,
                method: 'GET',
                responseType: "arraybuffer"
            }).then(resp => {
                if (resp.status == 200) {
                    state.loading = false;

                    dispatch(PROJECT_GET_BACKGROUND).then(() => {
                        commit(PROJECT_SET_PROCESSED_IMAGE, Buffer.from(resp.data, 'base64'))
                        resolve(resp)
                    })
                } else {
                    reject(resp)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    [PROJECT_EXPORT_WITH_BACKGROUND]: ({ state }) => {
        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/export_with_background/' + state.project._id,
                method: "GET",
                responseType: "blob"
            }).then(resp => {
                if (resp.status == 200) {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement("a");

                    link.href = url;
                    link.setAttribute('download', state.project.title + '_printcil.png');
                    document.body.appendChild(link);

                    link.click();

                    resolve(resp)
                } else {
                    reject(resp)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    [PROJECT_SET_BACKGROUND]: ({ state, getters, dispatch }) => {
        state.loading = true;

        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/background',
                data: {
                    project: state.project,
                    preprocessOptions: getters.formattedPreprocessOptions
                },
                method: 'POST',
            }).then(resp => {

                dispatch(PROJECT_GET_BACKGROUND)
                resolve(resp)

            }).catch(err => {
                reject(err)
            })
        })
    },
    [PROJECT_GET_BACKGROUND]: ({ state, commit }) => {
        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/background/' + state.project._id,
                method: 'GET',
                responseType: "arraybuffer"
            }).then(resp => {
                if (resp.status == 200) {
                    state.loading = false;
                    commit(PROJECT_SET_BACKGROUND, Buffer.from(resp.data, 'base64'))
                    resolve(resp)
                } else {
                    reject(resp)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    [PROJECT_RESIZE_IMAGE]: ({ state, dispatch }) => {
        state.loading = true;

        dispatch(PROJECT_SET_ABORT_CONTROLLER);

        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/resize/',
                data: {
                    project: {
                        ...state.project,
                        preprocessOptions: state.preprocessOptions
                    }
                },
                method: 'POST',
                signal: state.abortController.signal
            }).then(resp => {
                if (resp.status == 200) {
                    state.loading = false;
                    dispatch(PROJECT_GET_PROCESSED_IMAGE)
                    resolve(resp)
                } else {
                    reject(resp)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    [PROJECT_SET_ABORT_CONTROLLER]: ({ state }) => {
        if (state.abortController) {
            state.abortController.abort();
        }

        state.abortController = new AbortController();
    },
    [PROJECT_CLEAR_CANVAS]: ({ dispatch, state }, canvas) => {

        state.canvas.ctx.clearRect(0, 0, canvas.width, canvas.height);
        dispatch(PROJECT_SET_IMAGE, canvas);
    },
    [PROJECT_PREPARE_CANVAS]: ({ commit, dispatch, state }, canvas) => {
        commit(PROJECT_SET_CANVAS, canvas)

        state.canvas.img.onload = () => {
            dispatch(PROJECT_SET_IMAGE, canvas);
        };

        state.canvas.background.onload = () => {
            state.canvas.ctx.clearRect(0, 0, canvas.width, canvas.height);

            dispatch(PROJECT_DRAW_CANVAS)
            state.loaded = true;
        }

        const resizeCanvas = () => {
            commit(PROJECT_SET_CANVAS, canvas)
            dispatch(PROJECT_SET_IMAGE, canvas);
        }

        const dragEvent = (e) => {
            let cursor = cursorPosition(e)
            dispatch(PROJECT_SET_DRAG, cursor)
            dispatch(PROJECT_DRAW_CANVAS)
        }

        const dragStart = (e) => {
            let cursor = cursorPosition(e)

            state.ui.dragging = true

            if (state.ui.state == 'draw') {
                commit(PROJECT_SET_DRAW_LINE)
                commit(PROJECT_SET_UNSAVED)
                dispatch(PROJECT_SET_DRAG, cursor)
            }

            if (state.ui.state == 'erase') {
                commit(PROJECT_SET_ERASE_LINE)
                commit(PROJECT_SET_UNSAVED)
                dispatch(PROJECT_SET_DRAG, cursor)
            }

            if (state.ui.state == 'select') {
                state.ui.states.select.startDragPos = { ...state.ui.states.select.pos }
                state.ui.states.select.anchorFix = e.type == 'mousedown' ? true : false;
            }

            if (state.ui.state == 'freeselect') {
                commit(PROJECT_RESET_SELECTION_LINE)
                dispatch(PROJECT_SET_DRAG, cursor)
            }

            canvas.addEventListener("mousemove", dragEvent)
            canvas.addEventListener("touchmove", dragEvent, { passive: true })

            state.ui.states.drag.dragStartPosition.x = cursor.clientX
            state.ui.states.drag.dragStartPosition.y = cursor.clientY
        }

        const dragStop = () => {

            if (state.ui.state === 'draw' && state.ui.dragging) {
                commit(COMMIT_DRAWING)
                commit(PROJECT_SET_HISTORY_POINT, HISTORY_DRAW)
            }

            if (state.ui.state === 'erase' && state.ui.dragging) {
                commit(COMMIT_DRAWING)
                commit(PROJECT_SET_HISTORY_POINT, HISTORY_ERASE)
            }

            state.ui.dragging = false,

                canvas.removeEventListener("mousemove", dragEvent)
            canvas.removeEventListener("touchmove", dragEvent)

            if (['drag', 'select'].includes(state.ui.state)) {
                state.ui.states.drag.dragPosition.x = state.canvas.positions.x
                state.ui.states.drag.dragPosition.y = state.canvas.positions.y
                state.ui.states.select.anchorFix = false;
                state.ui.states.select.lockCanvasDrag = false
            }


            if (state.ui.state === 'freeselect') {
                commit(PROJECT_SET_CLOSE_DRAWED_SELECTION)
                clearInterval(state.ui.states.freeselect.marching)

                if (state.ui.states.freeselect.coords.length > 5) {
                    state.ui.states.freeselect.marching = setInterval(() => {
                        state.ui.states.freeselect.offset++;
                        if (state.ui.states.freeselect.offset > 76) {
                            state.ui.states.freeselect.offset = 0;
                        }

                        dispatch(PROJECT_DRAW_CANVAS)
                    }, 80)
                }
            }
        }

        canvas.addEventListener("mousedown", dragStart);
        canvas.addEventListener("touchstart", dragStart, { passive: true });
        canvas.addEventListener("mouseup", dragStop);
        canvas.addEventListener("mouseout", dragStop);
        canvas.addEventListener("mouseleave", dragStop);

        canvas.addEventListener("touchend", dragStop, { passive: true });

        window.addEventListener('resize', resizeCanvas, false);

        let mouseEvent = (e) => dispatch(PROJECT_MOUSE_EFFECTS, e)
        canvas.addEventListener("mousemove", mouseEvent)
        canvas.addEventListener("touchmove", mouseEvent)
    },
    [PROJECT_SET_IMAGE]: ({ state, commit, dispatch }, canvas) => {
        commit(RESET_OFFSCREENCANVAS)

        state.canvas.ctx.clearRect(0, 0, canvas.width, canvas.height);
        state.canvas.ctxImg.clearRect(0, 0, state.canvas.canvasImg.width, state.canvas.canvasImg.height);

        state.canvas.canvasImg.width = state.canvas.img.width
        state.canvas.canvasImg.height = state.canvas.img.height

        if (!state.ui.states.drag.dragged && !state.ui.zoomed) {
            state.canvas.positions.x = canvas.width / 2 - state.canvas.img.width / 2
            state.canvas.positions.y = canvas.height / 2 - state.canvas.img.height / 2

            state.ui.states.drag.dragPosition.x = state.canvas.positions.x
            state.ui.states.drag.dragPosition.y = state.canvas.positions.y
        }

        commit(PROJECT_SET_PREVIEW_SIZE)
        dispatch(PROJECT_DRAW_CANVAS)
    },
    [PROJECT_RESET_CANVAS_SIZES]: ({ state, dispatch }) => {
        state.canvas.canvas.width = state.canvas.canvas.parentElement.offsetWidth;
        state.canvas.canvas.height = state.canvas.canvas.parentElement.offsetHeight;

        dispatch(PROJECT_DRAW_CANVAS)
    },
    [PROJECT_MOUSE_EFFECTS]: ({ state, getters, commit }, e) => {

        let cursor = cursorPosition(e)

        if (state.ui.state == 'select' && !state.ui.states.select.anchorFix) {
            let accuracy = 20

            const isBetween = (num1, num2, value) => value >= num1 && value <= num2

            if (isBetween(getters.selectionCoords.ax - accuracy, getters.selectionCoords.ax + accuracy, cursor.clientX) && isBetween(getters.selectionCoords.ay - accuracy, getters.selectionCoords.ay + accuracy, cursor.clientY)) {
                commit(PROJECT_SET_ANCHOR, 'tl')
            } else if (isBetween(getters.selectionCoords.ax2 - accuracy, getters.selectionCoords.ax2 + accuracy, cursor.clientX) && isBetween(getters.selectionCoords.ay - accuracy, getters.selectionCoords.ay + accuracy, cursor.clientY)) {
                commit(PROJECT_SET_ANCHOR, 'tr')
            } else if (isBetween(getters.selectionCoords.ax - accuracy, getters.selectionCoords.ax + accuracy, cursor.clientX) && isBetween(getters.selectionCoords.ay2 - accuracy, getters.selectionCoords.ay2 + accuracy, cursor.clientY)) {
                commit(PROJECT_SET_ANCHOR, 'bl')
            } else if (isBetween(getters.selectionCoords.ax2 - accuracy, getters.selectionCoords.ax2 + accuracy, cursor.clientX) && isBetween(getters.selectionCoords.ay2 - accuracy, getters.selectionCoords.ay2 + accuracy, cursor.clientY)) {
                commit(PROJECT_SET_ANCHOR, 'br')
            } else if (isBetween(getters.selectionCoords.ax + 20, getters.selectionCoords.ax2 - 20, cursor.clientX) && isBetween(getters.selectionCoords.ay + 20, getters.selectionCoords.ay2 - 20, cursor.clientY)) {
                commit(PROJECT_SET_ANCHOR, 'drag')
            } else {
                commit(PROJECT_SET_ANCHOR, null)
            }
        }
    },
    [PROJECT_SET_DRAG]: ({ state, dispatch }, e) => {
        let dragActions = {
            'drag': PROJECT_DRAG_CANVAS,
            'draw': PROJECT_DRAW_LINE,
            'erase': PROJECT_ERASE_LINE,
            'select': PROJECT_DRAG_SELECTION,
            'freeselect': PROJECT_DRAW_SELECTION
        }

        dispatch(dragActions[state.ui.state], e)
    },
    [PROJECT_DRAW_LINE]: ({ commit, state }, e) => {
        if (
            e.clientY > state.canvas.positions.y &&
            e.clientY < (state.canvas.positions.y + (state.canvas.img.height * state.ui.zoom)) &&
            e.clientX > state.canvas.positions.x &&
            e.clientX < (state.canvas.positions.x + (state.canvas.img.width * state.ui.zoom))
        ) {
            commit(PROJECT_SET_DRAW_POSITION, e)
        } else {
            commit(PROJECT_SET_DRAW_LINE)
        }
    },
    [PROJECT_ERASE_LINE]: ({ commit, state }, e) => {
        if (
            e.clientY > state.canvas.positions.y &&
            e.clientY < (state.canvas.positions.y + (state.canvas.img.height * state.ui.zoom)) &&
            e.clientX > state.canvas.positions.x &&
            e.clientX < (state.canvas.positions.x + (state.canvas.img.width * state.ui.zoom))
        ) {
            commit(PROJECT_SET_ERASE_POSITION, e)
        } else {
            commit(PROJECT_SET_ERASE_LINE)
        }
    },
    [PROJECT_DRAG_CANVAS]: ({ state, dispatch }, e) => {

        if (state.ui.states.select.lockCanvasDrag) {
            return false;
        }

        state.ui.states.drag.dragged = true;

        let moveX = state.ui.states.drag.dragStartPosition.x - e.clientX;
        let moveY = state.ui.states.drag.dragStartPosition.y - e.clientY;

        state.canvas.positions.x = state.ui.states.drag.dragPosition.x - moveX;
        state.canvas.positions.y = state.ui.states.drag.dragPosition.y - moveY;

        dispatch(PROJECT_DRAW_CANVAS)
    },
    [PROJECT_DRAG_SELECTION]: ({ state, dispatch, commit }, e) => {

        if (state.ui.states.select.anchor == 'tl') {
            commit(PROJECT_RESIZE_SELECTION_TL, e)
        } else if (state.ui.states.select.anchor == 'tr') {
            commit(PROJECT_RESIZE_SELECTION_TR, e)
        } else if (state.ui.states.select.anchor == 'bl') {
            commit(PROJECT_RESIZE_SELECTION_BL, e)
        } else if (state.ui.states.select.anchor == 'br') {
            commit(PROJECT_RESIZE_SELECTION_BR, e)
        } else if (state.ui.states.select.anchor == 'drag') {
            commit(PROJECT_MOVE_SELECTION, e)
        } else {
            dispatch(PROJECT_DRAG_CANVAS, e)
            return false
        }

        dispatch(PROJECT_DRAW_CANVAS)
    },
    [PROJECT_DRAW_SELECTION]: ({ state, commit }, e) => {
        if (
            e.clientY > state.canvas.positions.y &&
            e.clientY < (state.canvas.positions.y + (state.canvas.img.height * state.ui.zoom)) &&
            e.clientX > state.canvas.positions.x &&
            e.clientX < (state.canvas.positions.x + (state.canvas.img.width * state.ui.zoom))
        ) {
            commit(PROJECT_SET_DRAW_SELECTION, e)
        }
    },
    [PROJECT_DRAW_CANVAS]: _.throttle(({ state, dispatch }) => {
        if (!state.canvas.ctx) {
            return false
        }

        state.canvas.ctx.clearRect(0, 0, state.canvas.canvas.width, state.canvas.canvas.height);
        state.canvas.ctxImg.clearRect(0, 0, state.canvas.canvasImg.width, state.canvas.canvasImg.height);

        state.canvas.ctx.globalCompositeOperation = "source-over";

        dispatch(PROJECT_DRAW_LINES)

        state.canvas.ctx.drawImage(
            state.canvas.background,
            state.canvas.positions.x,
            state.canvas.positions.y,
            state.canvas.img.width * state.ui.zoom,
            state.canvas.img.height * state.ui.zoom
        )

        state.canvas.ctx.drawImage(
            state.canvas.canvasImg,
            state.canvas.positions.x,
            state.canvas.positions.y,
            state.canvas.img.width * state.ui.zoom,
            state.canvas.img.height * state.ui.zoom
        )

        state.canvas.ctx.beginPath();
        state.canvas.ctx.strokeStyle = "#EEEEEE";
        state.canvas.ctx.lineWidth = 1;
        state.canvas.ctx.rect(
            state.canvas.positions.x,
            state.canvas.positions.y,
            state.canvas.img.width * state.ui.zoom,
            state.canvas.img.height * state.ui.zoom
        );

        state.canvas.ctx.stroke()

        if (state.ui.state === 'select') {
            dispatch(PROJECT_DRAW_SELECTION_BOX)
        }

        dispatch(PROJECT_DRAW_FREE_SELECTION_BOX)


        dispatch(PROJECT_DRAW_PREVIEW)

    }, 30),
    [PROJECT_DRAW_LINES]: ({ state }) => {

        if (!state.canvas.offscreenContext) {
            return false
        }

        if (!state.ui.states.draw.drawCoordsLatest) {
            state.canvas.offscreenContext.drawImage(
                state.canvas.img,
                0,
                0,
                state.canvas.img.width,
                state.canvas.img.height
            )
        }

        let lines = null;

        if (state.ui.states.draw.drawCoordsLatest) {
            lines = [state.ui.states.draw.drawCoordsLatest];
        } else {
            lines = state.ui.states.draw.drawCoords.slice().reverse();
        }

        lines.forEach((line) => {
            state.canvas.offscreenContext.lineCap = "round";
            state.canvas.offscreenContext.lineJoin = "round";

            let globalCompositeOperations = {
                'draw': "source-over",
                'erase': "destination-out"
            }
            state.canvas.offscreenContext.globalCompositeOperation = globalCompositeOperations[line.mode];

            state.canvas.offscreenContext.beginPath();
            state.canvas.offscreenContext.lineWidth = line.thickness;

            state.canvas.offscreenContext.strokeStyle = "#000000";


            line.coords.forEach(point => {
                state.canvas.offscreenContext.lineTo(
                    point.x * (state.canvas.canvasImg.width),
                    point.y * (state.canvas.canvasImg.height)
                );
            })

            state.canvas.offscreenContext.stroke();
        })

        state.canvas.offscreenContext.globalCompositeOperation = "source-over"
        state.canvas.ctxImg.globalCompositeOperation = "source-over"

        state.canvas.ctxImg.drawImage(state.canvas.offscreen,
            0,
            0,
            state.canvas.img.width,
            state.canvas.img.height
        );
    },
    [PROJECT_DRAW_SELECTION_BOX]: ({ state, getters }) => {

        // set faded background
        state.canvas.ctx.globalCompositeOperation = "source-over";
        state.canvas.ctx.fillStyle = 'rgba(0,0,0,0.5)';
        state.canvas.ctx.fillRect(
            state.canvas.positions.x,
            state.canvas.positions.y,
            state.canvas.img.width * state.ui.zoom,
            state.canvas.img.height * state.ui.zoom
        );

        // set white selection background
        state.canvas.ctx.globalCompositeOperation = "destination-out";
        state.canvas.ctx.fillStyle = 'white';
        state.canvas.ctx.fillRect(
            getters.selectionCoords.ax,
            getters.selectionCoords.ay,
            getters.selectionCoords.wpx,
            getters.selectionCoords.hpx
        );

        // draw image clipped in selection
        state.canvas.ctx.globalCompositeOperation = "source-over";

        // set background
        state.canvas.ctx.drawImage(
            state.canvas.background,
            getters.selectionCoords.x * state.canvas.img.width,
            getters.selectionCoords.y * state.canvas.img.height,
            getters.selectionCoords.w * state.canvas.img.width,
            getters.selectionCoords.h * state.canvas.img.height,
            getters.selectionCoords.ax,
            getters.selectionCoords.ay,
            getters.selectionCoords.wpx,
            getters.selectionCoords.hpx
        )

        state.canvas.ctx.drawImage(
            state.canvas.canvasImg,
            getters.selectionCoords.x * state.canvas.img.width,
            getters.selectionCoords.y * state.canvas.img.height,
            getters.selectionCoords.w * state.canvas.img.width,
            getters.selectionCoords.h * state.canvas.img.height,
            getters.selectionCoords.ax,
            getters.selectionCoords.ay,
            getters.selectionCoords.wpx,
            getters.selectionCoords.hpx
        )

        // set resizers
        state.canvas.ctx.globalCompositeOperation = "source-over";
        state.canvas.ctx.fillStyle = '#ffaa2a';

        state.canvas.ctx.beginPath();
        state.canvas.ctx.arc(
            getters.selectionCoords.ax,
            getters.selectionCoords.ay,
            5, 0, 2 * Math.PI
        );
        state.canvas.ctx.fill()

        state.canvas.ctx.beginPath();
        state.canvas.ctx.arc(
            state.canvas.positions.x + getters.selectionCoords.x2px,
            state.canvas.positions.y + getters.selectionCoords.ypx,
            5, 0, 2 * Math.PI
        );
        state.canvas.ctx.fill()

        state.canvas.ctx.beginPath();
        state.canvas.ctx.arc(
            state.canvas.positions.x + getters.selectionCoords.x2px,
            state.canvas.positions.y + getters.selectionCoords.y2px,
            5, 0, 2 * Math.PI
        );
        state.canvas.ctx.fill()

        state.canvas.ctx.beginPath();
        state.canvas.ctx.arc(
            state.canvas.positions.x + getters.selectionCoords.xpx,
            state.canvas.positions.y + getters.selectionCoords.y2px,
            5, 0, 2 * Math.PI
        );

        state.canvas.ctx.fill()
    },
    [PROJECT_DRAW_FREE_SELECTION_BOX]: ({ state }) => {

        if (state.ui.states.freeselect.coords.length > 10) {
            state.canvas.ctx.save();

            state.canvas.ctx.globalCompositeOperation = 'source-over';


            state.canvas.ctx.lineWidth = 1;
            state.canvas.ctx.setLineDash([4, 2]);
            state.canvas.ctx.lineCap = "round";
            state.canvas.ctx.lineDashOffset = state.ui.states.freeselect.offset;
            state.canvas.ctx.strokeStyle = "#000000";

            state.canvas.ctx.beginPath();

            state.ui.states.freeselect.coords.forEach(point => {
                state.canvas.ctx.lineTo(
                    (point.x * (state.canvas.img.width * state.ui.zoom)) + state.canvas.positions.x,
                    (point.y * (state.canvas.img.height * state.ui.zoom)) + state.canvas.positions.y
                );
            })

            state.canvas.ctx.stroke();
            state.canvas.ctx.restore();
        }
    },
    [PROJECT_PREPARE_PREVIEW]: ({ commit }, canvas) => {
        commit(PROJECT_SET_PREVIEW, canvas)
    },
    [PROJECT_DRAW_PREVIEW]: ({ state }) => {
        if (!state.preview.ctx) {
            return false
        }

        state.preview.ctx.clearRect(0, 0, state.preview.canvas.width, state.preview.canvas.height);
        state.preview.ctx.globalCompositeOperation = "source-over";

        state.preview.ctx.drawImage(
            state.canvas.background,
            0,
            0,
            state.preview.canvas.width,
            state.preview.canvas.height
        )

        state.preview.ctx.drawImage(
            state.canvas.canvasImg,
            0,
            0,
            state.preview.canvas.width,
            state.preview.canvas.height
        )

        // set focus rectangle 
        state.preview.ctx.beginPath();
        state.preview.ctx.strokeStyle = '#FFAA2A'

        let scale = state.preview.canvas.width / state.canvas.img.width
        let x = state.canvas.positions.x / state.canvas.img.width;
        let y = state.canvas.positions.y / state.canvas.img.height;
        let w = state.canvas.canvas.width * scale
        let h = state.canvas.canvas.height * scale

        state.preview.ctx.rect(
            - (x * state.preview.canvas.width) / state.ui.zoom,
            - (y * state.preview.canvas.height) / state.ui.zoom,
            w / state.ui.zoom,
            h / state.ui.zoom
        );

        state.preview.ctx.stroke()
    },
    [PROJECT_UNDO]: ({ state, dispatch }) => {
        state.loading = true;

        dispatch(PROJECT_SET_ABORT_CONTROLLER);

        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/undo', data: {
                    history: state.history,
                    project: state.project
                }, method: 'POST', signal: state.abortController.signal
            })
                .then(resp => {

                    dispatch(PROJECT_GET_DATA, state.project._id);

                    dispatch(PROJECT_GET_PROCESSED_IMAGE).then(() => {
                        resolve(resp.data.data)
                    })
                })
                .catch(err => {
                    reject(err)
                })
        })

    },
    [PROJECT_REDO]: ({ state, dispatch }) => {

        state.loading = true;

        dispatch(PROJECT_SET_ABORT_CONTROLLER);

        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/redo', data: {
                    history: state.history,
                    project: state.project
                }, method: 'POST', signal: state.abortController.signal
            })
                .then(resp => {

                    dispatch(PROJECT_GET_DATA, state.project._id);

                    dispatch(PROJECT_GET_PROCESSED_IMAGE).then(() => {
                        resolve(resp.data.data)
                    })
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [PROJECT_RESET]: ({ state, dispatch }) => {
        state.loading = true;

        dispatch(PROJECT_SET_ABORT_CONTROLLER);

        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/reset', data: {
                    project: state.project
                }, method: 'POST', signal: state.abortController.signal
            })
                .then(resp => {

                    dispatch(PROJECT_GET_DATA, state.project._id);

                    dispatch(PROJECT_GET_PROCESSED_IMAGE).then(() => {
                        resolve(resp.data.data)
                    })
                })
                .catch(err => {
                    reject(err)
                })
        })

    },
    [PROJECT_ROTATE]: ({ state, dispatch }, direction) => {

        state.loading = true;

        dispatch(PROJECT_SET_ABORT_CONTROLLER);

        return new Promise((resolve, reject) => {
            axios({
                url: process.env.VUE_APP_API_URL + '/api/v1/ip/rotate', data: {
                    history: state.history,
                    project: state.project,
                    direction: direction
                }, method: 'POST', signal: state.abortController.signal
            })
                .then(resp => {

                    dispatch(PROJECT_GET_DATA, state.project._id);

                    dispatch(PROJECT_GET_PROCESSED_IMAGE).then(() => {
                        resolve(resp.data.data)
                    })
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}