<template>
    <div class="c-card" :class="{'has-bg': !!background}" :style="{'backgroundImage': 'url(' + background + ')'}">

        <div class="c-card__content">
            <slot name="beforeHeading" v-if="$slots.beforeHeading"></slot>

            <div class="c-card__header" v-if="$slots.header">
                <slot name="header"></slot>
            </div>

            <div class="c-card__heading" v-if="$slots.title">
                <slot name="title"></slot>
            </div>

            <slot name="afterHeading" v-if="$slots.afterHeading"></slot>

            <div class="c-card__body" v-if="hasSlot">
                <slot></slot>
            </div>

            <slot name="afterContent" v-if="$slots.afterContent"></slot>
        </div>

        <footer class="c-card__footer" v-if="$slots.footer">
            <slot name="footer"></slot>
        </footer>
    </div>
</template>
<script>
  export default {
    props: {
        background: String
    },
    computed: {
      hasSlot() {
        return this.$slots.default
      }
    }
  }
</script>
