export default function guest({ from, next, store, router }) {

    if (store.getters.isAuthenticated) {
        if (from.name != 'dashboard') {
            return router.push({
                name: 'dashboard'
            })
        }
    }

    return next()
}
