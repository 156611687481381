<template>
  <div class="c-avatar-upload" id="c-avatar-upload">
    <input
      type="file"
      name="avatar"
      class="js-avatar-upload c-avatar-upload__input"
      id="avatar-upload"
      ref="avatar"
      accept="image/*"
      @change="uploadAvatar()"
    />

    <label for="avatar-upload" class="c-avatar-upload__trigger">
      <div class="c-avatar-upload__avatar" :class="{ 'is-loading': loading }">
        <span class="c-avatar c-avatar--fluid">
          <img
            v-if="avatar != 'null'"
            :src="avatar"
            alt=""
            class="c-avatar__img"
          />
          <img
            v-else
            src="@/assets/images/avatar-base.svg"
            alt=""
            class="c-avatar__img"
          />
        </span>
      </div>
      <div class="c-avatar-upload__label">
        {{ $t("account.avatar.add") }}
        <small class="c-avatar-upload__filepath">
          {{
            loading ? $t("account.avatar.loading") : $t("account.avatar.sub")
          }}
        </small>
      </div>
    </label>
  </div>
  <div class="u-text-center">
    <Validations v-if="validations" :validations="validations" />
  </div>
</template>
<script>
import Validations from "@components/input-validation";

export default {
  blockName: "c-avatar-upload",
  components: {
    Validations,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    initAvatar: {
      type: String,
      required: true,
    },
    validations: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      avatar: this.initAvatar,
    };
  },
  methods: {
    postFile(files) {
      this.$emit("fileSelected", files);
    },
    uploadAvatar() {
      const files = this.$refs.avatar.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.avatar = e.target.result;
        };
        reader.readAsDataURL(files[0]);

        this.postFile(files);
      }
    },
  },
  mounted() {
  },
};
</script>
