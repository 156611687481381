export default {
    props: {
        modifiers: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            modifierList: this.modifiers
        }
    },
    computed: {
        blockClass(){
            return (this.$options.blockName + ' ' + this.buildModifier(this.$options.blockName, this.modifierList)).trim();
        }
    },
    methods: {
        elementClass(element,modifiers){
            modifiers = modifiers ? modifiers : [];
            let elementName = this.$options.blockName + '__' + element;
            return ((elementName) + ' ' + this.buildModifier(elementName, modifiers)).trim()
        },
        buildModifier(className,modifiers){
            return modifiers.map(m => className + '--' + m ).join(' ');
        }
    }
}
