<template>
    <div class="u-grid tablet:u-grid-cols-3 u-items-center">
        <canvas class="u-w-full tablet:u-col-span-2 u-cursor-crosshair"  ref="canvasEl"></canvas>
        <div class="u-p-6">
            <h5><i class="fal fa-crop"></i> {{ $t('designer.crop-modal.title') }}</h5>
            <p>{{ $t('designer.crop-modal.text') }}</p>
        
            <button :disabled="loading" @click="startWithSelection()" class="c-btn c-btn--primary u-mt-2"><span>
                <i v-if="loading" class="fal fa-spin fa-spinner"></i>
                {{ $t('designer.crop-modal.use') }}
            </span></button>
            <a @click="resetSelection()" v-if="startY > 0 && startX > 0" class="u-underline u-inline-block u-p-2 u-cursor-pointer"><span>{{ $t('designer.crop-modal.reset-selection') }}</span></a>
        </div>
    </div>
            
</template>
<script>
import { onMounted, ref, nextTick } from 'vue';


    export default {
        emits: ['process'],
        setup(props, {emit}){

            const canvasEl = ref(null)
            const reader = ref(new FileReader());
            const ctx = ref(null)
            const loading = ref(null)
            const img = ref(new Image()); 
            
            let startX = ref(null);
            let startY = ref(null);
            let currentX = ref(null);
            let currentY = ref(null);
            let isSelecting = ref(false);
            let scaleWidth = ref(null);
            let scaleHeight = ref(null);

            const readImages = ()=>{
                
                reader.value.addEventListener("load", function(){

                    img.value.src = reader.value.result;  
                    img.value.onload = () => {

                        canvasEl.value.width = img.value.width;
                        canvasEl.value.height = img.value.height;
                        scaleWidth.value = img.value.width / canvasEl.value.offsetWidth;
                        scaleHeight.value = img.value.height / canvasEl.value.offsetHeight;

                        ctx.value.drawImage(
                            img.value, 
                            0,
                            0,
                            img.value.width, 
                            img.value.height
                        );   

                        // set default full selection
                        startX.value = 0;
                        startY.value = 0;
                        currentX.value = canvasEl.value.offsetWidth;
                        currentY.value = canvasEl.value.offsetHeight;
                    }
                })

                ctx.value = canvasEl.value.getContext('2d');

                nextTick(() => {
                    reader.value.readAsDataURL(props.image);
                })
            }

            const handleMouseDown = (event) => {
                startX.value = event.offsetX;
                startY.value = event.offsetY;
                currentX.value = startX.value;
                currentY.value = startY.value;
                isSelecting.value = true;
            };

            const handleMouseMove = (event) => {
                if (!isSelecting.value) return;
                
                if(event.offsetX > canvasEl.value.offsetWidth){
                    currentX.value = canvasEl.value.offsetWidth;
                } else {
                    currentX.value = event.offsetX;
                }

                if(event.offsetY > canvasEl.value.offsetHeight){
                    currentY.value = canvasEl.value.offsetHeight;
                } else {
                    currentY.value = event.offsetY;
                }

                drawSelection();
            };

            const handleMouseUp = () => {
                isSelecting.value = false;
            };

            const handleTouchStart = (event) => {
                event.preventDefault();
                const touch = event.touches[0];
                startX.value = touch.clientX - canvasEl.value.getBoundingClientRect().left;
                startY.value = touch.clientY - canvasEl.value.getBoundingClientRect().top;
                currentX.value = startX;
                currentY.value = startY;
                isSelecting.value = true;
            };

            const handleTouchMove = (event) => {
                event.preventDefault();
                if (!isSelecting.value) return;
                const touch = event.touches[0];

                let touchX = touch.clientX - canvasEl.value.getBoundingClientRect().left
                let touchY = touch.clientY - canvasEl.value.getBoundingClientRect().top;

                if(touchX > canvasEl.value.offsetWidth){
                    currentX.value = canvasEl.value.offsetWidth;
                } else {
                    currentX.value = touchX;
                }

                if(touchY > canvasEl.value.offsetHeight){
                    currentY.value = canvasEl.value.offsetHeight;
                } else {
                    currentY.value = touchY
                }

                drawSelection();
            };

            const handleTouchEnd = (event) => {
                event.preventDefault();
                isSelecting.value = false;
            };

            const drawSelection = () => {
                ctx.value.clearRect(0, 0, canvasEl.value.width, canvasEl.value.height);

                const width = (currentX.value - startX.value) * scaleWidth.value;
                const height = (currentY.value - startY.value) * scaleHeight.value;
                const scaledStartX = startX.value * scaleWidth.value;
                const scaledStartY = startY.value * scaleHeight.value;

                // // set background
                ctx.value.drawImage(
                    img.value, 
                    0,
                    0,
                    img.value.width, 
                    img.value.height
                )

                // set faded background
                ctx.value.globalCompositeOperation = "source-over";
                ctx.value.fillStyle = 'rgba(0,0,0,0.5)';
                ctx.value.fillRect(
                    0,
                    0,
                    img.value.width, 
                    img.value.height
                );
                
                ctx.value.strokeStyle = "#EEEEEE";
                ctx.value.strokeRect(scaledStartX, scaledStartY, width, height);

                // set white selection background
                ctx.value.globalCompositeOperation = "destination-out";
                ctx.value.fillStyle = 'white';
                ctx.value.fillRect(scaledStartX, scaledStartY, width, height);

                // draw image clipped in selection
                ctx.value.globalCompositeOperation = "source-over";

                ctx.value.drawImage(
                    img.value, 
                    scaledStartX, 
                    scaledStartY, 
                    width, 
                    height,
                    scaledStartX, 
                    scaledStartY, 
                    width, 
                    height,
                )
                
            };

            onMounted(()=> {
                readImages();

                canvasEl.value.addEventListener("mousedown", handleMouseDown);
                canvasEl.value.addEventListener("mousemove", handleMouseMove);
                canvasEl.value.addEventListener("mouseup", handleMouseUp);
                canvasEl.value.addEventListener("touchstart", handleTouchStart);
                canvasEl.value.addEventListener("touchmove", handleTouchMove);
                canvasEl.value.addEventListener("touchend", handleTouchEnd);
            })

            const resetSelection = () => {
                startX.value = 0;
                startY.value = 0;
                currentX.value = canvasEl.value.offsetWidth;
                currentY.value = canvasEl.value.offsetHeight;

                drawSelection();
            }

            const startWithSelection = () => {
                loading.value = true
                
                const startXValue = Math.min(currentX.value, startX.value);
                const endXValue = Math.max(currentX.value, startX.value);
                
                const startYValue = Math.min(currentY.value, startY.value);
                const endYValue = Math.max(currentY.value, startY.value);

                const width = Math.abs((endXValue - startXValue)) * scaleWidth.value;
                const height = Math.abs((endYValue - startYValue)) * scaleHeight.value;
                const scaledStartX = startXValue * scaleWidth.value;
                const scaledStartY = startYValue * scaleHeight.value;
                
                // Extract the cropped image data from the canvas
                const croppedData = ctx.value.getImageData(scaledStartX, scaledStartY, width, height);

                // Create a new canvas to hold the cropped image
                const croppedCanvas = document.createElement('canvas');
                croppedCanvas.width = width;
                croppedCanvas.height = height;
                const croppedCtx = croppedCanvas.getContext('2d');
                croppedCtx.putImageData(croppedData, 0, 0);

                // Convert the cropped canvas to a Blob object (file-like object)
                croppedCanvas.toBlob((blob) => {
                    // Create a FormData object and append the cropped image file
                    const file = new File([blob], props.image.name, {
                        type: 'image/png'
                    });
                    
                    emit('process', file)
                }, 'image/png');
            }

            return {
                readImages,
                canvasEl,
                reader,
                ctx,
                img,
                loading,

                startX,
                startY,
                currentX,
                currentY,
                scaleWidth,
                scaleHeight,
                isSelecting,

                resetSelection,
                startWithSelection
            }
        },
        props: {
            image: File
        },
        mounted(){

        }
    }
</script>