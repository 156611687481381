import { HISTORY_STENCIL } from "../types/history-types"
import { PROJECT_SET_HISTORY, PROJECT_SET_HISTORY_POINT } from "../types/mutation-types"

export default {
    [PROJECT_SET_HISTORY_POINT]: (state, action) => {

        if(["Open"].includes(action)){

            state.history = [
                {
                    action: action,
                    active: true,
                    preprocessOptions: {...state.preprocessOptions},
                    drawCoords: [],
                    rerender: false,
                    canUndo: false,
                }
            ];
            return;
        }

        let history = {
            action: action,
            active: true,
            preprocessOptions: {...state.preprocessOptions},
            drawCoords: [...state.ui.states.draw.drawCoords],
            rerender: [HISTORY_STENCIL].includes(action),
            canUndo: true,
        }

        let latestActiveIndex = state.history.map(item => item.active).lastIndexOf(true)

        if(latestActiveIndex >= 0){
            state.history.length = latestActiveIndex + 1
        }

        state.history.push(history)
    },
    [PROJECT_SET_HISTORY]: (state, historyState) => {
        
        state.preprocessOptions = {...historyState.preprocessOptions}
        state.ui.states.draw.drawCoords = [...historyState.drawCoords]
    }
}